import React from "react";
import VerticalLogo from "../../assets/images/Minerd-Vertical-Logo.png";
import Background from "../../assets/images/Ministerio.jpeg";
import { Link } from "react-router-dom";
import LibroAbiertoLogoIcon from "../../assets/icons/LibroAbiertoLogoIcon";

const NotFoundUnauthorized: React.FC = () => {
	return (
		<main id="not-found-unauthorize-page" style={{ backgroundImage: `url(${Background})` }}>
			<div className="overlay"></div>
			<div className="login-container">
				<div className="logo-container">
					<img src={VerticalLogo} alt="Minerd Logo" />
					<LibroAbiertoLogoIcon className="header-logo-icon" />
				</div>
				<div className="message-container">
					<div className="content">
						<h2>Acceso denegado</h2>
						<div>
							Actualmente te encuentras sin acceso. Por favor inicia sesión para utilizar los servicios de
							Libro Abierto.
						</div>
					</div>
					<div className="action">
						<Link to={"/"} className="back-button">
							Iniciar Sesión
						</Link>
					</div>
				</div>
			</div>
		</main>
	);
};

export default NotFoundUnauthorized;

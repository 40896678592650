import React, { useState } from "react";
import BookCard from "../../../components/templates/BookCard/BookCard";
import { Book } from "../../../models";
import { useGrade } from "../../../hooks/useGrade.hook";
import { useFetchAndLoad } from "../../../hooks/useFetchAndLoad.hook";
import { useAsync } from "../../../hooks/useAsync.hook";
import { getRelatedBooksByGrade } from "../../../services";
import LoadingIcons from "react-loading-icons";

interface RelatedListProps {
	bookId: string;
}

const RelatedList: React.FC<RelatedListProps> = ({ bookId }) => {
	const { grade } = useGrade();
	const { loading, callEndpoint } = useFetchAndLoad();
	const [relatedBooks, setRelatedBooks] = useState<Book[]>([]);

	const getRelatedBooksApiCall = async () => grade && (await callEndpoint(getRelatedBooksByGrade(grade.id!, bookId)));

	const adaptRelatedBooksData = (backData: any) => {
		if (backData.data) setRelatedBooks(backData.data);
	};

	useAsync(getRelatedBooksApiCall, adaptRelatedBooksData, [grade]);

	if (loading)
		return (
			<div className="loading-container">
				<div className="loading-component">
					<LoadingIcons.Oval
						className="loadin-icon"
						stroke="#00ACCE"
						speed={2}
						width="6rem"
						height="6rem"
						strokeWidth="3"
					/>
				</div>
			</div>
		);

	if (relatedBooks && relatedBooks.length > 0)
		return (
			<div className="books-list">
				{relatedBooks.map((book, i) => {
					const booksData = book as Book;
					return <BookCard grade={grade!} book={booksData} key={i} />;
				})}
			</div>
		);

	return null;
};

export default RelatedList;

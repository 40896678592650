import React, { useState } from "react";
import LeftFillArrowIcon from "../../../assets/icons/LeftFillArrowIcon";
import RightFillArrowIcon from "../../../assets/icons/RightFillArrowIcon";
import { BannerImage } from "../../../models";

interface SlideItem {
	order: number;
	img: string;
}

interface SlideshowProps {
	id: string;
	imgList: BannerImage[];
}

const orderImageList = (imgList: BannerImage[]) => {
	return imgList.map((img, i) => ({
		order: i,
		img: img.imageUrl,
	}));
};

const Slideshow: React.FC<SlideshowProps> = ({ id, imgList }) => {
	const [imageList, setImageList] = useState<SlideItem[]>(orderImageList(imgList) || []);
	const [currentImage, setCurrentImage] = useState(0);

	const prevImage = () => {
		if (currentImage === 0) return imageList.length - 1;
		return currentImage - 1;
	};

	const nextImage = () => {
		if (currentImage == imageList.length - 1) return 0;
		return currentImage + 1;
	};

	const movePrev = () => {
		const prevImg = document.querySelector(".prev-image") as HTMLElement;
		const currentImg = document.querySelector(".current-image") as HTMLElement;

		prevImg.classList.add("move-right");
		currentImg.classList.add("move-right");

		setTimeout(() => {
			setCurrentImage(prevImage());
			prevImg.classList.remove("move-right");
			currentImg.classList.remove("move-right");
		}, 300);
	};

	const moveNext = () => {
		const nextImg = document.querySelector(".next-image") as HTMLElement;
		const currentImg = document.querySelector(".current-image") as HTMLElement;

		nextImg.classList.add("move-left");
		currentImg.classList.add("move-left");

		setTimeout(() => {
			nextImg.classList.remove("move-left");
			currentImg.classList.remove("move-left");
			setCurrentImage(nextImage());
		}, 300);
	};

	return (
		<section id={id} className="slideshow">
			<div className="image-container">
				<article className="prev-image">
					<img src={imageList.at(prevImage())?.img} alt="banner" />
				</article>
				<article className="current-image">
					<img src={imageList.at(currentImage)?.img} alt="banner" />
				</article>
				<article className="next-image">
					<img src={imageList.at(nextImage())?.img} alt="banner" />
				</article>
			</div>
			<div className="slideshow-actions">
				<div className="prev" onClick={movePrev}>
					<LeftFillArrowIcon className="medium-less-icon" />
				</div>
				<div className="next" onClick={moveNext}>
					<RightFillArrowIcon className="medium-less-icon" />
				</div>
			</div>
		</section>
	);
};

export default Slideshow;

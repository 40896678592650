import React from "react";
import { Chapter } from "../../../models";
import DownloadIcon from "../../../assets/icons/DownloadIcon";
import ViewIcon from "../../../assets/icons/ViewIcon";
import QrCodeIcon from "../../../assets/icons/QrCodeIcon";
import RedirectIcon from "../../../assets/icons/RedirectIcon";
import ChapterMedia from "./ChapterMedia";

interface ChaptersTableProps {
	chapters: Chapter[];
	download: () => void;
	view: () => void;
}

const ChaptersTable: React.FC<ChaptersTableProps> = ({ chapters, download, view }) => {
	return (
		<div className="chapters-table">
			{chapters
				.sort((a, b) => a.order! - b.order!)
				.map((chapter) => {
					return (
						<div className="chapter-container">
							<div className="chapter-item" key={chapter.order}>
								<div className="chapter-name">
									<span>{chapter.title}</span>
								</div>
								<div className="chapters-actions">
									{chapter.pdfUrl && (
										<>
											<DownloadIcon
												className="download-icon small-plus-icon"
												fill="#636363"
												onClick={download}
											/>
											<ViewIcon
												className="small-plus-icon"
												fill="#636363"
												onClick={view}
											/>
										</>
									)}
									{chapter.qrCodes && <QrCodeIcon className="small-plus-icon" />}
									{chapter.links && <RedirectIcon className="small-plus-icon" />}
								</div>
							</div>
							<ChapterMedia chapter={chapter} />
						</div>
					);
				})}
		</div>
	);
};

export default ChaptersTable;

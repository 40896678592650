import React from "react";
import EscudoRd from "../../assets/images/img-escudo-republica-dominicana.png";
import MinerdInstitucion from "../../assets/images/logo_grayscala.png";

const Footer: React.FC = () => {
	return (
		<footer id="app-footer">
			<div className="minerd-info">
				<div className="minerd-images">
					<a href="/">
						<img
							src={EscudoRd}
							alt="Escudo Nacional de la República Dominicana"
							title="Escudo Nacional de la República Dominicana"
						/>
					</a>
					<a href="/">
						<img
							src={MinerdInstitucion}
							alt="Logo institucional del ministerio de educación de la República Dominicana"
							title="Logo institucional del ministerio de educación de la República Dominicana"
						/>
					</a>
				</div>
				<ul className="minerd-data">
					<li>Ministerio de Educación de la República Dominicana</li>
					<li>
						<address>
							Avenida Máximo Gómez esquina Santiago, No.02 Gazcue, Distrito Nacional, República
							Dominicana.
						</address>
					</li>
					<li>
						Tel.: 809.688.9700 | email: <a href="mailto:info@minerd.gob.do"> info@minerd.gob.do</a>
					</li>
					<li>Horario: De 7:30 a.m. a 4:30 p.m. de Lunes a Viernes.</li>
					<li>
						<a href="/terminos-de-uso">Terminos de uso</a> |
						<a href="/politicas-de-privacidad">Política de privacidad</a> |
						<a href="/preguntas-frecuentes">Preguntas frecuentes</a>
					</li>
					<li>© 2023 MINISTERIO DE EDUCACIÓN | Todos los Derechos Reservados</li>
				</ul>
			</div>
		</footer>
	);
};

export default Footer;

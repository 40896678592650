import VerticalLogo from "../../assets/images/Minerd-Vertical-Logo.png";
import Background from "../../assets/images/Ministerio.jpeg";
import LibroAbiertoLogoIcon from "../../assets/icons/LibroAbiertoLogoIcon";
import EmailFormModal from "./components/EmailFormModal";
import { Link } from "react-router-dom";
import CodeFormModal from "./components/CodeFormModal";
import PasswordFormModal from "./components/PasswordFormModal";
import { useState } from "react";
import { RestoreUserDto } from "../../models";
import PasswordRestoredModal from "./components/PasswordRestoredModal";

const RestorePasswordPage = () => {
	const [actualStep, setActualStep] = useState<number>(0);
	const [entity, setEntity] = useState<RestoreUserDto>({
		email: "",
		password: "",
		restoreCode: "",
	});

	const goNextStep = () => setActualStep(actualStep + 1);
	const goPrevStep = () => setActualStep(actualStep - 1);

	const getActualModule = () => {
		switch (actualStep) {
			case 0:
				return <EmailFormModal entity={entity} setEntity={setEntity} goNextStep={goNextStep} />;

			case 1:
				return <CodeFormModal entity={entity} setEntity={setEntity} goNextStep={goNextStep} />;

			case 2:
				return <PasswordFormModal entity={entity} setEntity={setEntity} goNextStep={goNextStep} />;

			case 3:
				return <PasswordRestoredModal />;
		}
	};

	return (
		<main id="login-page" className="scroll" style={{ backgroundImage: `url(${Background})` }}>
			<div className="overlay"></div>
			<div className="login-container">
				<div className="logo-container">
					<img src={VerticalLogo} alt="Minerd Logo" />
					<LibroAbiertoLogoIcon className="header-logo-icon" />
				</div>
				{getActualModule()}
			</div>
			<div className="change-link-container">
				{actualStep < 3 &&
					(actualStep === 0 ? (
						<Link className="change-link" to={"/"}>
							Regresar
						</Link>
					) : (
						<div className="change-link" onClick={goPrevStep}>
							Regresar
						</div>
					))}
			</div>
		</main>
	);
};

export default RestorePasswordPage;

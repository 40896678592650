import React, { useState } from "react";
import { useFetchAndLoad } from "../../../hooks/useFetchAndLoad.hook";
import LoadingIcons from "react-loading-icons";
import { resendConfirmationMail } from "../../../services";
import { useNotification } from "../../../hooks/useNotification.hook";

interface ResendFormModalProps {
	goLogin: () => void;
}

const ResendFormModal: React.FC<ResendFormModalProps> = ({ goLogin }) => {
	const { notifyError } = useNotification();
	const [mailSended, setMailSended] = useState<boolean>(false);
	const { loading, callEndpoint } = useFetchAndLoad();

	const resendConfirmationMailApiCall = async (mail: string) => await callEndpoint(resendConfirmationMail(mail));

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		const email = e.currentTarget.mail.value;

		try {
			const result = await resendConfirmationMailApiCall(email);
			if (result.statusCode == 200) {
				setMailSended(true);
			}
		} catch (err: any) {
			if (err.data) {
				notifyError(err.data.data);
			} else {
				console.log(err);
			}
		}
	};

	return (
		<div className="init-form-modal">
			{!mailSended ? (
				<>
					<div className="information-text">
						Si no has recibido un correo de confirmación ingresa tu correo electrónico para enviarte un
						nuevo correo de confirmación.
					</div>
					<div className="form-container">
						<form id="logInForm" onSubmit={handleSubmit}>
							<div className="input-container">
								<input id="mail" type="email" placeholder="Correo electrónico" required />
							</div>
							<button className="submit-btn" type="submit">
								{!loading ? (
									<span>Enviar</span>
								) : (
									<div className="loading-component">
										<LoadingIcons.Oval
											className="loadin-icon"
											stroke="#00ACCE"
											speed={2}
											width="2rem"
											height="2rem"
											strokeWidth="2"
										/>
									</div>
								)}
							</button>
						</form>
					</div>
				</>
			) : (
				<>
					<div className="content">
						<h2>Su correo ha sido enviado</h2>
						<div>
							Por favor revise su correo para confirmar la cuenta antes de utilizar los servicios a traves
							de Libro Abierto. Este correo puede tomar unos minutos.
						</div>
						<div className="action">
							<button className="back-button" onClick={goLogin}>
								Iniciar Sesión
							</button>
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default ResendFormModal;

import React, { useState } from "react";
import clsx from "clsx";
import LineTitle from "../LineTitle/LineTitle";
import LineArrowIcon from "../../../assets/icons/LineArrowIcon";

interface ExpansionPanelProps {
	text?: string;
	children?: JSX.Element | JSX.Element[];
	startShow?: boolean;
}

const ExpansionPanel: React.FC<ExpansionPanelProps> = ({ text, children, startShow = false }) => {
	const [show, setShow] = useState(startShow);

	const handleCollapsed = () => setShow(!show);

	return (
		<div className={clsx({ "expansion-panel": true, show: show })}>
			<LineTitle text={text} action={handleCollapsed}>
				<LineArrowIcon className="arrow-icon medium-icon" />
			</LineTitle>
			<div className={clsx({ "panel-content": true, show: show })}>{children}</div>
		</div>
	);
};

export default ExpansionPanel;

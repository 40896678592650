import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import { User } from "../models/User/User.model";
import { currentUserStorage } from "../helpers/LocalStorage.utility";

export const useUser = () => {
	const { user, setUser } = useContext(AuthContext);

	const getUser = () => {
		return currentUserStorage.get();
	};

	const addUser = (userData: User) => {
		setUser(userData);
		currentUserStorage.set(userData);
	};

	const removeUser = () => {
        setUser(null);
        currentUserStorage.remove();
	};

	return { user, getUser, addUser, removeUser };
};

import { useState } from "react";
import { useFetchAndLoad } from "./useFetchAndLoad.hook";
import { useNavigate, useParams } from "react-router-dom";
import { useAsync } from "./useAsync.hook";
import { getSubjectByName } from "../services/Subject.service";

export const useSubject = () => {
	const params = useParams();
	const navigate = useNavigate();
	const [subjectId, setSubjectId] = useState<string | null>(null);
	const { loading, callEndpoint } = useFetchAndLoad();

	const getSubjectByNameApiCall = async () => await callEndpoint(getSubjectByName(params.subject!));

	const adaptData = (backData: any) => {
		if (backData?.data) setSubjectId(backData.data);
		else navigate(`not-found`);
	};

	useAsync(getSubjectByNameApiCall, adaptData);

	return { loadingSubject: loading, subjectId };
};

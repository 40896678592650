import React from "react";
import { useFetchAndLoad } from "../../../hooks/useFetchAndLoad.hook";
import LoadingIcons from "react-loading-icons";
import { RestoreModuleProps } from "../models/RestoreModuleProps";
import { RestoreUserDto } from "../../../models";
import { checkRestoreCode, restorePasswordCode } from "../../../services";
import { useNotification } from "../../../hooks/useNotification.hook";

const CodeFormModal: React.FC<RestoreModuleProps> = (props) => {
	const { notifySuccess } = useNotification();
	const { loading, callEndpoint } = useFetchAndLoad();
	const { loading: loadingCode, callEndpoint: callEndpointCode } = useFetchAndLoad();

	const restorePasswordCodeApiCall = async (email: string) => await callEndpointCode(restorePasswordCode(email));

	const checkRestoreCodeApiCall = async (user: RestoreUserDto) => await callEndpoint(checkRestoreCode(user));

	const resendCode = async () => {
		if (!loadingCode) {
			try {
				const result = await restorePasswordCodeApiCall(props.entity.email);
				if (result.data) {
					notifySuccess("Se ha enviado un nuevo código a su correo electrónico");
				}
			} catch (err) {
				console.log(err);
			}
		}
	};

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		if (!loading) {
			e.preventDefault();

			const user = props.entity;
			user.restoreCode = e.currentTarget.code.value;

			try {
				const result = await checkRestoreCodeApiCall(user);
				if (result.data) {
					props.setEntity({ ...props.entity, restoreCode: user.restoreCode });
					props.goNextStep();
				}
			} catch (err) {
				console.log(err);
			}
		}
	};

	return (
		<div className="init-form-modal">
			<div className="information-text">
				Enviamos un código de restauración a tu correo electrónico. Por favor ingrese el código para continuar a
				restaurar su contraseña.
			</div>
			<div className="form-container">
				<form id="logInForm" onSubmit={handleSubmit}>
					<div className="input-container">
						<input id="code" type="text" placeholder="Código" required />
					</div>
					<div className="links-container">
						<div className="reesend-code-link" onClick={resendCode}>
							¿No has recibido tu correo de restauración? Presiona aquí
						</div>
					</div>
					<button className="submit-btn" type="submit">
						{!loading ? (
							<span>Confirmar</span>
						) : (
							<div className="loading-component">
								<LoadingIcons.Oval
									className="loadin-icon"
									stroke="#00ACCE"
									speed={2}
									width="2rem"
									height="2rem"
									strokeWidth="2"
								/>
							</div>
						)}
					</button>
				</form>
			</div>
		</div>
	);
};

export default CodeFormModal;

import React, { useState } from "react";
import VerticalLogo from "../../assets/images/Minerd-Vertical-Logo.png";
import Background from "../../assets/images/Ministerio.jpeg";
import LoginForm from "./components/LoginForm";
import RegisterForm from "./components/RegisterForm";
import SignedInModal from "./components/SignedInModal";
import LibroAbiertoLogoIcon from "../../assets/icons/LibroAbiertoLogoIcon";
import ResendFormModal from "./components/ResendFormModal";
import { Link } from "react-router-dom";

const LoginPage: React.FC = () => {
	const [showLogin, setShowLogin] = useState<boolean>(true);
	const [signedIn, setSignedIn] = useState<boolean>(false);
	const [showResend, setShowResend] = useState<boolean>(false);

	const toggleShowLogin = () => setShowLogin(!showLogin);

	const closeModal = () => {
		setSignedIn(false);
		setShowResend(false);
		setShowLogin(true);
	};

	return (
		<main id="login-page" className="scroll" style={{ backgroundImage: `url(${Background})` }}>
			<div className="overlay"></div>
			<div className="login-container">
				<div className="logo-container">
					<img src={VerticalLogo} alt="Minerd Logo" />
					<LibroAbiertoLogoIcon className="header-logo-icon" />
				</div>
				{!showResend ? (
					!signedIn ? (
						showLogin ? (
							<LoginForm />
						) : (
							<RegisterForm showSignedIn={() => setSignedIn(true)} />
						)
					) : (
						<SignedInModal goLogin={closeModal} />
					)
				) : (
					<ResendFormModal goLogin={closeModal} />
				)}
			</div>
			<div className="links-container">
				<div className="change-link-container">
					{!showResend ? (
						!signedIn &&
						(showLogin ? (
							<div className="change-link" onClick={toggleShowLogin}>
								Registrarse
							</div>
						) : (
							<div className="change-link" onClick={toggleShowLogin}>
								Iniciar Sesión
							</div>
						))
					) : (
						<div className="change-link" onClick={closeModal}>
							Regresar
						</div>
					)}
				</div>
				{!showResend && (
					<div className="resend-link-container" onClick={() => setShowResend(true)}>
						<div className="resend-link">¿No has recibido tu correo de confirmación?</div>
					</div>
				)}
				{!showResend && showLogin && (
					<div className="restore-password-link-container">
						<Link to={"/restaurar-contrasena"} className="resend-link">
							¿Olvidaste tu contraseña?
						</Link>
					</div>
				)}
			</div>
		</main>
	);
};

export default LoginPage;

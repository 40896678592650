export class FieldValidator {
	static validatePassword(password: string) {
		let errors = [];

		if (password.length < 6) errors.push("La contraseña debe tener más de 6 caracteres");

		if (password.search(/[a-z]/) < 0) errors.push("La contraseña debe contener al menos una minúscula");

		if (password.search(/[A-Z]/) < 0) errors.push("La contraseña debe contener al menos una mayúscula");

		if (password.search(/[0-9]/) < 0) errors.push("La contraseña debe contener al menos un dígito");

		if (errors.length > 0) return { valid: false, errors: errors };
		else return { valid: true, errors: [] };
	}

	static validateEmail(mail: string) {
		const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

		if (!mail.match(validRegex)) return { valid: false, errors: ["El correo electrónico no es válido"] };
		else return { valid: true, errors: [] };
	}
}

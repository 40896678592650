import { useEffect, useState } from "react";

export type Dimensions = {
	width: number;
	height: number;
};

export const useRefDimensions = (ref: React.RefObject<HTMLDivElement>) => {
	const [dimensions, setDimensions] = useState<Dimensions>({ width: 1, height: 1 });

	const handleElementResized = () => {
		if (ref.current) {
			const { width, height } = ref.current.getBoundingClientRect();
			setDimensions({ width: width, height: height });
		}
	};

	const resizeObserver = new ResizeObserver(handleElementResized);

	useEffect(() => {
		if (ref.current) resizeObserver.observe(ref.current);
		
		return () => {
			resizeObserver.disconnect();
		};
	}, []);

	return dimensions;
};

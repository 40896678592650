import "./question-input.scss";
import SendImage from "../../../../assets/images/Send.svg";
import React, { forwardRef, useImperativeHandle, useRef, useState } from "react";
import SendIcon from "../../../../assets/icons/SendIcon";

export interface QuestionInputHandle {
	setQuestion: (q: string) => void;
}

export interface QuestionInputProps {
	onSend: (question: string) => void;
	disabled: boolean;
}

const QuestionInput = forwardRef<QuestionInputHandle, QuestionInputProps>(({ onSend, disabled }, ref) => {
	const [question, setQuestion] = useState<string>("");

	const sendQuestion = () => {
		if (disabled || !question.trim()) {
			return;
		}

		onSend(question);
		clearQuestion();
	};

	const clearQuestion = () => {
		setQuestion("");
		const questionInput = document.getElementById("question-input");
		if (questionInput) questionInput.style.height = "50px";
	};

	const onQuestionChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setQuestion(e.currentTarget.value || "");
		e.currentTarget.style.height = "1px";
		e.currentTarget.style.height = 15 + e.currentTarget.scrollHeight + "px";
	};

	const onEnterPress = (e: React.KeyboardEvent) => {
		if (e.key == "Enter" && !e.shiftKey) {
			e.preventDefault();
			sendQuestion();
		}
	};
	const sendQuestionDisabled = disabled || !question.trim();

	useImperativeHandle(ref, () => ({
		setQuestion,
	}));

	return (
		<div className="question-input-container">
			<textarea
				id="question-input"
				placeholder="Hazme una pregunta"
				onKeyDown={onEnterPress}
				value={question}
				onChange={onQuestionChange}></textarea>
			<div className="send-button" onClick={sendQuestion}>
				{sendQuestionDisabled ? (
					<SendIcon className="small-icon" />
				) : (
					<img src={SendImage} className="send-icon" alt="send" />
				)}
			</div>
		</div>
	);
});

QuestionInput.displayName = "QuestionInput";

export default QuestionInput;

import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";
import Home from "./pages/Home/Home";
import "./assets/styles/App.scss";
import BooksList from "./pages/BookList/BookList";
import { ScrollToTop } from "./hooks/scrollToTop.hook";
import BookDetail from "./pages/BookDetail/BookDetail";
import BookReader from "./pages/BookReader/BookReader";
import { useAuth } from "./hooks/useAuth.hook";
import LoginPage from "./pages/Login/LoginPage";
import LibraryPage from "./pages/Library/LibraryPage";
import ContactsPage from "./pages/Contacts/ContactsPage";
import ConfirmMail from "./pages/ConfirmMail/ConfirmMail";
import NotFoundUnauthorized from "./pages/NotFound/NotFoundUnauthorized";
import { injectAuth, injectNotify } from "./api/interceptors";
import { tokenStorage } from "./helpers/LocalStorage.utility";
import { useFetchAndLoad } from "./hooks/useFetchAndLoad.hook";
import { refreshToken } from "./services";
import { useNotification } from "./hooks/useNotification.hook";
import NotFoundAuthorized from "./pages/NotFound/NotFoundAuthorized";
import SubjectDetail from "./pages/SubjectDetail/SubjectDetail";
import { useAsync } from "./hooks/useAsync.hook";
import Background from "./assets/images/Ministerio.jpeg";
import LoadingIcons from "react-loading-icons";
import RestorePasswordPage from "./pages/RestorePassword/RestorePasswordPage";
import MiniChat from "./components/MiniChat/MiniChat";
import PerfilPage from "./pages/Perfil/PerfilPage";

function App() {
	const authHook = useAuth();
	const notifyHook = useNotification();
	const { notifyError } = useNotification();
	const { user, insertToken, logout } = useAuth();
	const { loading, callEndpoint } = useFetchAndLoad();
	injectAuth(authHook);
	injectNotify(notifyHook);

	const refreshTokenApiCall = async () => {
		const token = tokenStorage.get();
		if (token) return await callEndpoint(refreshToken(token));

		authHook.logout();
	};

	const adaptRefreshToken = (backData: any) => {
		if (backData?.data) {
			insertToken(backData.data.token);
		} else {
			const token = tokenStorage.get();
			if (token) {
				notifyError("Su sesión ha finalizado, debe ingresar otra vez.");
				logout();
				localStorage.clear();
			}
		}
	};

	useAsync(refreshTokenApiCall, adaptRefreshToken, []);

	return (
		<div className="App">
			{!loading ? (
				user ? (
					<BrowserRouter>
						<ScrollToTop />
						<Header />
						<Routes>
							<Route path="/" element={<Home />} />
							<Route path="/biblioteca" element={<LibraryPage />} />
							<Route path="/contactos" element={<ContactsPage />} />
							<Route path="/perfil" element={<PerfilPage />} />
							<Route path="/:level/:grade" element={<BooksList />} />
							<Route path="/:level/:grade/:subject" element={<SubjectDetail />} />
							<Route path="/:level/:grade/:subject/:book" element={<BookDetail />} />
							<Route
								path="/:level/:grade/:subject/:book/lector"
								element={<BookReader />}
							/>
							<Route path="*" element={<NotFoundAuthorized />} />
						</Routes>
						<Footer />
						{!window.location.href.endsWith("lector") && <MiniChat />}
					</BrowserRouter>
				) : (
					<BrowserRouter>
						<Routes>
							<Route path="/" element={<LoginPage />} />
							<Route path="/confirmar-cuenta/:clientId" element={<ConfirmMail />} />
							<Route path="/restaurar-contrasena" element={<RestorePasswordPage />} />
							<Route path="*" element={<NotFoundUnauthorized />} />
						</Routes>
					</BrowserRouter>
				)
			) : (
				<main id="login-page" style={{ backgroundImage: `url(${Background})` }}>
					<div className="overlay"></div>
					<div className="loading-container">
						<div className="loading-component">
							<LoadingIcons.Oval
								className="loadin-icon"
								stroke="white"
								speed={2}
								width="8rem"
								height="8rem"
								strokeWidth="3"
							/>
						</div>
					</div>
				</main>
			)}
		</div>
	);
}

export default App;

import React, { createContext, useState } from "react";
import { User } from "../models/User/User.model";

interface AuthContextProps {
	user: User | null;
	setUser: React.Dispatch<React.SetStateAction<User | null>>;
	token: string | null;
	setToken: React.Dispatch<React.SetStateAction<string | null>>;
}

const AuthContext = createContext<AuthContextProps>({
	user: null,
	setUser: () => {},
	token: null,
	setToken: () => {},
});

interface AuthProviderProps {
	children: JSX.Element | JSX.Element[];
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
	const [token, setToken] = useState<string | null>(null);
	const [user, setUser] = useState<User | null>(null);

	return <AuthContext.Provider value={{ user, setUser, token, setToken }}>{children}</AuthContext.Provider>;
};

export default AuthContext;

import React from "react";
import CheckIcon from "../../../assets/icons/CheckIcon";
import { ListSubject } from "../../../models";

interface CheckBoxListProps {
	list: ListSubject[];
	onCheck: () => void;
}

const CheckBoxList: React.FC<CheckBoxListProps> = ({ list, onCheck }) => {
	const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.checked === true) {
			e.target.parentElement!.classList.add("active");
		} else {
			e.target.parentElement!.classList.remove("active");
		}
		onCheck();
	};

	return (
		<div className="checkbox-list">
			{list.map((item, i) => (
				<div className={`checkbox-item ${item.show ? "active" : ""}`} key={i}>
					<input id={item.code} type="checkbox" onChange={handleCheck} defaultChecked={item.show} />
					<label className="label" htmlFor={item.code}>
						<div className="fake-checkbox">
							<CheckIcon className="check" />
						</div>
						<span>{item.name}</span>
					</label>
				</div>
			))}
		</div>
	);
};

export default CheckBoxList;

import { useEffect } from "react";
import { useUser } from "./useUser.hook";
import { User } from "../models/User/User.model";
import { useToken } from "./useToken.hook";
import { addToken } from "../services";

export const useAuth = () => {
	const { user, getUser, addUser, removeUser } = useUser();
	const { token, getToken, saveToken, removeToken } = useToken();

	useEffect(() => {
		const userData = getUser();
		if (userData) addUser(userData);

		const tokenData = getToken();
		if (tokenData) saveToken(tokenData);
	}, []);

	const login = (userData: User) => {
		userData && addUser(userData);
	};

	const insertToken = (tokenData: string) => {
		tokenData && saveToken(tokenData);
		addToken();
	};

	const logout = () => {
		removeUser();
		removeToken();
	};

	return { user, token, insertToken, login, logout };
};

import React from "react";
import MainContainer from "../../components/layout/MainContainer";
import RedBarText from "../../components/templates/RedBarText/RedBarText";
import CalendarIcon from "../../assets/icons/CalendarIcon";
import PhoneIcon from "../../assets/icons/PhoneIcon";
import MailIcon from "../../assets/icons/MailIcon";
import { useFetchAndLoad } from "../../hooks/useFetchAndLoad.hook";
import { ContactMail } from "../../models";
import { useNotification } from "../../hooks/useNotification.hook";
import { sendMail } from "../../services/Mail.service";

const ContactsPage = () => {
	const { notifySuccess, notifyError } = useNotification();
	const { loading, callEndpoint } = useFetchAndLoad();

	const clearForm = (e: React.FormEvent<HTMLFormElement>) => {
		e.currentTarget.personname.value = "";
		e.currentTarget.lastname.value = "";
		e.currentTarget.email.value = "";
		e.currentTarget.feedback.value = "";
	};

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		if (!loading) {
			const mailData: ContactMail = {
				name: e.currentTarget.personname.value,
				lastName: e.currentTarget.lastname.value,
				email: e.currentTarget.email.value,
				feedBack: e.currentTarget.feedback.value,
			};
			
			clearForm(e);

			try {
				const result = await callEndpoint(sendMail(mailData));
				if (result) {
					notifySuccess("Su correo se ha enviado exitosamente");
				}
			} catch (err: any) {
				notifyError(err.data.data);
			}
		}
	};

	return (
		<MainContainer id="ContactsPage">
			<RedBarText title="INFORMACIÓN DE CONTACTO" />
			<section className="contacts-mail-section">
				<article className="contacts-section">
					<div className="contact-container">
						<CalendarIcon className="large-icon" />
						<div className="info">
							<span>Horario: De 8:00am a 4:00pm</span>
							<span>Lunes a Viernes</span>
						</div>
					</div>
					<div className="separation-column"></div>
					<div className="contact-container">
						<PhoneIcon className="large-icon" />
						<div className="info">
							<span>Teléfono:</span>
							<span>{"(809) 688-9700"}</span>
						</div>
					</div>
					<div className="separation-column"></div>
					<div className="contact-container">
						<MailIcon className="large-icon" />
						<div className="info">
							<span>Correo:</span>
							<span>{"libroabierto@minerd.gob.do"}</span>
						</div>
					</div>
				</article>
				<article className="mail-section">
					<div className="title">BUZÓN DIGITAL DE QUEJAS, RECLAMACIONES, SUGERENCIAS Y FELICITACIONES</div>
					<div className="description">
						Queremos continuar mejorando la calidad del servicio brindado y su opinión es importante para
						nosotros. Le invitamos a presentarnos quejas, reclamaciones, sugerencias o felicitaciones
						completando el formulario.
					</div>
					<form onSubmit={handleSubmit} className="contact-form">
						<div className="double-column">
							<input id="personname" placeholder="Nombre" required />
							<input id="lastname" placeholder="Apellido" required />
						</div>
						<input id="email" type="name" placeholder="Correo Electrónico" required />
						<textarea
							id="feedback"
							placeholder="Quejas, Reclamaciones, Sugerencias y Felicitaciones"></textarea>
						<div className="action-container">
							<button type="submit">Enviar</button>
						</div>
					</form>
				</article>
			</section>
		</MainContainer>
	);
};

export default ContactsPage;

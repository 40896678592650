import React, { useEffect, useState } from "react";
import SearchField from "../../../components/templates/SearchField/SearchField";
import CheckBoxList from "../../../components/templates/CheckBoxList/CheckBoxList";
import { ListSubject } from "../../../models";
import SideTitle from "../../../components/templates/SideTitle/SideTitle";
import FilterIcon from "../../../assets/icons/FilterIcon";
import clsx from "clsx";
import CrossIcon from "../../../assets/icons/CrossIcon";

interface BooksFilterSectionProps {
	filter: (subjects?: ListSubject[]) => void;
	subjects: ListSubject[];
}

const BooksFilterSection: React.FC<BooksFilterSectionProps> = ({ filter, subjects }) => {
	const [show, setShow] = useState(false);

	const toggleShow = () => setShow(!show);

	const handleFilter = () => {
		const form = document.getElementById("filter-form") as HTMLFormElement;
		const filteredSubjects = subjects.map((subject) => ({ ...subject, show: form[subject.code].checked }));
		filter(filteredSubjects);
	};

	const handleNameFilter = (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.FormEvent<HTMLFormElement>
	) => {
		if (e) e.preventDefault();
		filter();
	};

	useEffect(() => {
		handleFilter();
	}, []);

	return (
		<>
			<FilterIcon className="filter-icon medium-icon" fill="#003876" onClick={toggleShow} />
			<aside className={clsx({ "filter-section": true, show: show })}>
				<CrossIcon className="cancel-icon medium-plus-icon" onClick={toggleShow} />
				<SideTitle text="Lista de Asignaturas" />
				<form
					id="filter-form"
					onSubmit={handleNameFilter}
					className={`book-list-filters${show ? ` show` : ""}`}>
					<SearchField id="filter-search" handleSearch={handleNameFilter} />
					<div className="subject-filter">
						<div className="subject-list">
							<CheckBoxList list={subjects} onCheck={handleFilter} />
						</div>
					</div>
				</form>
			</aside>
		</>
	);
};

export default BooksFilterSection;

import { Level } from "./Level.Enum";

export const LevelEntities = [
	{
		level: Level.inicial,
		name: "Inicial",
	},
	{
		level: Level.primaria,
		name: "Primaria",
	},
	{
		level: Level.secundaria,
		name: "Secundaria",
	},
];
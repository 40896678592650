import React from "react";
import IconProps from "./IconProps.interface";

const PerfilIcon: React.FC<IconProps> = (props) => {
	return (
		<svg
			className={props.className}
			onClick={props.onClick}
			viewBox="0 0 61 62"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<circle cx="30.5" cy="31" r="30.5" fill={props.fill || "#D9D9D9"} />
			<g clipPath="url(#clip0_747_2999)">
				<path
					d="M44.1422 39.8578C41.9638 37.6795 39.371 36.067 36.5421 35.0908C39.572 33.004 41.5625 29.5116 41.5625 25.5625C41.5625 19.187 36.3755 14 30 14C23.6245 14 18.4375 19.187 18.4375 25.5625C18.4375 29.5116 20.428 33.004 23.458 35.0908C20.6291 36.067 18.0363 37.6795 15.8579 39.8578C12.0804 43.6354 10 48.6578 10 54H13.125C13.125 44.6951 20.6951 37.125 30 37.125C39.3049 37.125 46.875 44.6951 46.875 54H50C50 48.6578 47.9196 43.6354 44.1422 39.8578ZM30 34C25.3476 34 21.5625 30.215 21.5625 25.5625C21.5625 20.91 25.3476 17.125 30 17.125C34.6524 17.125 38.4375 20.91 38.4375 25.5625C38.4375 30.215 34.6524 34 30 34Z"
					fill={props.stroke || "#6A6A6A"}
				/>
			</g>
		</svg>
	);
};

export default PerfilIcon;

import api from "../api";
import { tokenStorage } from "../helpers/LocalStorage.utility";
import { loadAbort } from "../helpers/loadAbort.utility";
import { LoginUserDto, RestoreUserDto, SignInUserDto, UpdateUserDto } from "../models";

export const login = (user: LoginUserDto) => {
	const controller = loadAbort();
	return { call: api.post("api/Auth/Login", user, { signal: controller.signal }), controller };
};

export const refreshToken = (tokenLocalStorage: string) => {
	const controller = loadAbort();
	const token = `Bearer ${tokenLocalStorage}`;
	return {
		call: api.post(
			"api/Auth/Token/Refresh",
			{ token: tokenLocalStorage },
			{ headers: { authentication: token, Authorization: token }, signal: controller.signal }
		),
		controller,
	};
};

export const signIn = (user: SignInUserDto) => {
	const controller = loadAbort();
	return { call: api.post("api/Auth/SignIn", user, { signal: controller.signal }), controller };
};

export const updateUser = (user: UpdateUserDto, token: string) => {
	const controller = loadAbort();
	return {
		call: api.put("api/Auth/Perfil", user, {
			params: { token: token },
			signal: controller.signal,
		}),
		controller,
	};
};

export const restorePassword = (user: RestoreUserDto) => {
	const controller = loadAbort();
	return {
		call: api.patch("api/Auth/RestorePassword", user, { signal: controller.signal }),
		controller,
	};
};

export const restorePasswordCode = (email: string) => {
	const controller = loadAbort();
	return {
		call: api.get("api/Auth/RestorePasswordCode", {
			params: { email: email },
			signal: controller.signal,
		}),
		controller,
	};
};

export const addToken = () => {
	const tokenLocalStorage = tokenStorage.get();
	if (tokenLocalStorage) {
		const token = `Bearer ${tokenLocalStorage}`;
		api.defaults.headers.common["Authorization"] = token;
	}
};

import { createContext } from "react";
import { Toaster } from "react-hot-toast";

const NotifyContext = createContext({});

interface NotifyProviderProps {
	children: JSX.Element | JSX.Element[];
}

export const NotifyProvider: React.FC<NotifyProviderProps> = ({ children }) => {
	return (
		<NotifyContext.Provider value={{}}>
			<Toaster position="top-right" reverseOrder={false} />
			{children}
		</NotifyContext.Provider>
	);
};

export default NotifyContext;

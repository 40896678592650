import React from "react";
import { Link } from "react-router-dom";

const PasswordRestoredModal = () => {
	return (
		<div className="signed-in-modal">
			<div className="content">
				<h2>Su contraseña ha sido restaurada con éxito</h2>
				<div>
					Ya puede ingresar a Libro Abierto con su nueva contraseña para utilizar nuestros servicios.
				</div>
			</div>
			<div className="action">
				<Link className="back-button" to={"/"}>
					Iniciar Sesión
				</Link>
			</div>
		</div>
	);
};

export default PasswordRestoredModal;

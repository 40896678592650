import React, { useState } from "react";
import { CountActivityEventDto, EventEntityType, MediaFile, MediaType } from "../../../models";
import MediaPlayer from "../MediaPlayer/MediaPlayer";
import AudioIcon from "../../../assets/icons/AudioIcon";
import VideoIcon from "../../../assets/icons/VideoIcon";
import { useFetchAndLoad } from "../../../hooks/useFetchAndLoad.hook";
import { countViewActivity } from "../../../services";
import { useUser } from "../../../hooks/useUser.hook";

interface MediaCardProps {
	media: MediaFile;
}

const MediaCard: React.FC<MediaCardProps> = ({ media }) => {
	const { user } = useUser();
	const { callEndpoint } = useFetchAndLoad();
	const [showModal, setShowModal] = useState<boolean>();

	const countViewActivityApiCall = async () => {
		try {
			const activity: CountActivityEventDto = {
				userId: user?.id,
				entityId: media.id,
				entityType: EventEntityType.MediaFile,
			};

			await callEndpoint(countViewActivity(activity));
		} catch (err) {
			console.log(err);
		}
	};

	const handleSelect = () => {
		setShowModal(true);
		countViewActivityApiCall();
	};

	return (
		<>
			<MediaPlayer
				media={media}
				showPlayer={showModal}
				closeShowPlayer={() => setShowModal(false)}
			/>
			<article className="media-file-card" onClick={handleSelect}>
				<div className="media-file-card-content">
					<div className="image-container">
						<img src={media.imageUrl} alt="Imagen de Libro" />
					</div>
					<div className="info-container">
						<div className="info-header">
							<h5 className="title">{media.name}</h5>
							{media.mediaType == MediaType.Audio && (
								<AudioIcon className="media-type-icon medium-plus-icon" />
							)}
							{media.mediaType == MediaType.Video && (
								<VideoIcon className="media-type-icon medium-icon" />
							)}
						</div>
						<p className="autor">{media.autor}</p>
					</div>
				</div>
			</article>
		</>
	);
};

export default MediaCard;

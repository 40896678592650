import "./pdf-reader.scss";
import React, { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { PageDocument, PageDocumentHandle } from "./components/PageDocument";
import { Dimensions, useRefDimensions } from "../../../../hooks";
import ArrowUpIcon from "../../../../assets/icons/ArrowUpIcon";
import ArrowDownIcon from "../../../../assets/icons/ArrowDownIcon";
import ZoomInIcon from "../../../../assets/icons/ZoomInIcon";
import ZoomOutIcon from "../../../../assets/icons/ZoomOutIcon";
import clsx from "clsx";
import { DataPoint } from "../../../../models/ChatBot/Chat.model";
import { BookPDFDto } from "../../../../models/Book/BookPDFDto.model";

export interface PDFReaderHandle {
	handleReference: (d: DataPoint, n: number) => void;
}

export interface PDFReaderProps {
	file: BookPDFDto;
}

const zoomOptions = [50, 75, 100, 125, 150];

export const PDFReader = forwardRef<PDFReaderHandle, PDFReaderProps>(({ file }: PDFReaderProps, ref) => {
	const pdfReaderRef = useRef<HTMLDivElement>(null);
	const dimensions = useRefDimensions(pdfReaderRef);
	const pageDocumentRef = useRef<PageDocumentHandle>(null);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [currentZoom, setCurrentZoom] = useState<number>(100);
	const [pageSize, setPageSize] = useState<Dimensions>({
		width: dimensions.width,
		height: 1000,
	});

	const selectZoom = (n: number) => {
		if (zoomOptions.includes(n)) {
			setCurrentZoom(n);
		}
	};

	const handlePageSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		const inputValue = parseInt(e.currentTarget["actual-page-number"].value);

		if (inputValue > 1 && inputValue < file.pagesNumber) goToPage(inputValue);
		else window.alert("El digito ingresado no es un número de página disponible");
	};

	const goToPage = (n: number) => {
		const allowNumbers = Array.from(Array(file.pagesNumber).keys());
		if (allowNumbers.includes(n - 1)) pageDocumentRef.current?.scrollIntoView(n);
	};

	const handleReference = (d: DataPoint) => {
		pageDocumentRef.current?.scrollIntoView(d.index);
	};

	const handleOnScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
		const container = e.currentTarget;
		const newPage = Math.floor(container.scrollTop / (pageSize.height + 15)) + 1;

		const pageInput = document.getElementById("actual-page-number") as HTMLInputElement;
		if (pageInput) pageInput.value = newPage.toString();

		setCurrentPage(newPage);
	};

	useImperativeHandle(ref, () => ({
		handleReference,
	}));

	return (
		<div ref={pdfReaderRef} className="pdf-reader">
			<div className="reader-header">
				<div className="pagination-control">
					<div
						className={clsx({ "previous-page-button": true, disable: currentPage <= 1 })}
						onClick={() => goToPage(currentPage - 1)}>
						<ArrowUpIcon className="small-more-icon" fill={"#494949"} />
					</div>
					<div className="page-input-container">
						<form onSubmit={handlePageSubmit}>
							<input id="actual-page-number" type="number" defaultValue={1} />
						</form>
						<div className="slash">/</div>
						<div className="page-number">{file.pagesNumber}</div>
					</div>
					<div
						className={clsx({ "next-page-button": true, disable: currentPage >= file.pagesNumber })}
						onClick={() => goToPage(currentPage + 1)}>
						<ArrowDownIcon className="small-more-icon" fill={"#494949"} />
					</div>
				</div>
				<div className="page-size-control">
					<div
						className={clsx({ "zoom-in-button": true, disable: currentZoom >= 150 })}
						onClick={() => selectZoom(currentZoom + 25)}>
						<ZoomInIcon className="small-more-icon" fill={"#494949"} />
					</div>
					<div className="zoom-percent">{currentZoom}%</div>
					<div
						className={clsx({ "zoom-out-button": true, disable: currentZoom <= 50 })}
						onClick={() => selectZoom(currentZoom - 25)}>
						<ZoomOutIcon className="small-more-icon" fill={"#494949"} />
					</div>
				</div>
			</div>
			<div className="reader-body">
				<div className="reader-sidebar"></div>
				<div className="pdf-content" onScroll={handleOnScroll}>
					{Array.from(Array(file.pagesNumber).keys()).map((pageIndex) => (
						<PageDocument
							key={pageIndex}
							ref={pageDocumentRef}
							pageIndex={pageIndex}
							fileURL={`${file.fileName}_${pageIndex + 1}.pdf`}
							pageSize={pageSize}
							setPageSize={setPageSize}
							currentZoom={currentZoom}
						/>
					))}
				</div>
			</div>
		</div>
	);
});

PDFReader.displayName = "PDFReader";

import React from "react";
import RedBarText from "../RedBarText/RedBarText";
import MediaCard from "../MediaCard/MediaCard";
import { Chapter, MediaFile, MediaType, SelectorOption, Subject } from "../../../models";

interface MediaGroupProps {
	type: MediaType;
	entity: Subject | Chapter;
	dataInfo: { label: string; value: string };
	selectedFilter?: SelectorOption;
}

export const MediaGroupsType = {
	video: "Videos",
	audio: "Audios",
};

const MediaGroup: React.FC<MediaGroupProps> = ({ type, entity, dataInfo, selectedFilter }) => {
	const validateShow = (parameter: string) => {
		if (!selectedFilter) return true;

		if (selectedFilter!.value === "all" || selectedFilter!.value === parameter) return true;
		return false;
	};

	return (
		<div className="media-group">
			{validateShow(dataInfo.value) && (
				<section className="list-container">
					<RedBarText title={dataInfo.label} />
					<div className="card-list scroll">
						{entity?.mediaFiles &&
						entity.mediaFiles.filter((x) => x.mediaType == type).length > 0 ? (
							entity?.mediaFiles
								.filter((x) => x.mediaType == type)
								.map((media: MediaFile, i) => {
									return <MediaCard media={media} key={i} />;
								})
						) : (
							<div className="not-found">
								No se encontraron {dataInfo.label.toLocaleLowerCase()} para su
								busqueda
							</div>
						)}
					</div>
				</section>
			)}
		</div>
	);
};

export default MediaGroup;

import React from "react";
import SearchIcon from "../../../assets/icons/SearchIcon";
import clsx from "clsx";

interface SearchFieldProps {
	id?: string;
	placeholder?: string;
	handleSearch: React.MouseEventHandler<HTMLButtonElement>;
	principal?: boolean;
}

const SearchField: React.FC<SearchFieldProps> = ({
	id = "search-field",
	handleSearch,
	placeholder = "¿Qué quieres buscar?",
	principal = false,
}) => {
	return (
		<div className={clsx({ "search-field": true, principal: principal })}>
			<div className="input-container">
				<input id={id} type="text" placeholder={placeholder} />
			</div>
			<button className="search-button" onClick={handleSearch}>
				<SearchIcon className="small-icon" />
			</button>
		</div>
	);
};

export default SearchField;

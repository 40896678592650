import React, { useState } from "react";
import MainContainer from "../../components/layout/MainContainer";
import RedBarText from "../../components/templates/RedBarText/RedBarText";
import FilterSelector from "../../components/templates/FilterSelector/FilterSelector";
import SearchField from "../../components/templates/SearchField/SearchField";
import BookCard from "../../components/templates/BookCard/BookCard";
import LibraryBanner from "../../assets/images/library-banner.png";
import { Book, Library, SelectorOption } from "../../models";
import { useFetchAndLoad } from "../../hooks/useFetchAndLoad.hook";
import { getLibraries } from "../../services";
import { useAsync } from "../../hooks/useAsync.hook";
import NotFoundIcon from "../../assets/icons/NotFoundIcon";

const formatOptions = (libraryData: Library[]) => {
	return [
		{ label: "Todos", value: "all" },
		...libraryData.map((library) => ({ label: library.name!, value: library.id! })),
	];
};

const LibraryPage: React.FC = () => {
	const { loading, callEndpoint } = useFetchAndLoad();
	const [baseLibraries, setBaseLibraries] = useState<string>("[]");
	const [libraries, setLibraries] = useState<Library[] | null>(null);
	const [selectedOption, setSelectedOption] = useState<SelectorOption>({ label: "Todos", value: "all" });

	const getLibrariesApiCall = async () => await callEndpoint(getLibraries());

	const adaptData = (backData: any) => {
		if (backData.data) {
			setBaseLibraries(JSON.stringify(backData.data));
			setLibraries(backData.data);
		}
	};

	const filter = (selectedValue: SelectorOption = selectedOption) => {
		if (!loading) {
			let filterData: Library[] = JSON.parse(baseLibraries);

			if (selectedValue.value == "all") {
				filterData = JSON.parse(baseLibraries);
			} else {
				filterData = JSON.parse(baseLibraries).filter((x: Library) => x.id == selectedValue.value);
			}

			const searchField = document.getElementById("library-filter-search") as HTMLFormElement;
			const searchFieldText = searchField.value;

			if (searchFieldText) {
				filterData = filterData.map((library) => {
					library.books = library.books?.filter((x) =>
						x.title.toLowerCase().includes(searchFieldText.toLowerCase())
					);
					return library;
				});

				filterData = filterData.filter((x) => x.books!.length > 0);
			}

			setLibraries(filterData);
		}
	};

	const handleSearch = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		if (e) e.preventDefault();
		filter();
	};

	const handleSelect = (selectedValue: SelectorOption) => {
		setSelectedOption(selectedValue);
		filter(selectedValue);
	};

	useAsync(getLibrariesApiCall, adaptData);

	return (
		<MainContainer id="LibraryPage">
			<div className="library-banner">
				<img src={LibraryBanner} alt="library-banner" />
				<h2 className="library-title">Biblioteca</h2>
			</div>
			<div className="library-content">
				<div className="filters-container">
					<section className="filters-section">
						<form id="search-form" className="search-container">
							<SearchField id="library-filter-search" handleSearch={handleSearch} />
						</form>
						<div className="divider"></div>
						<div className="selector-container">
							<FilterSelector
								options={formatOptions(JSON.parse(baseLibraries))}
								initialSelected={{ label: "Todos", value: "all" }}
								handleSelect={handleSelect}
							/>
						</div>
					</section>
				</div>
				<>
					{libraries &&
						(libraries.length > 0 ? (
							libraries.map((library: Library) => (
								<section className="library-books list-container" key={library.id}>
									<RedBarText title={`Biblioteca ${library.name}`} />
									<div className="library-url">
										<a target="_blank" rel="noreferrer" href={library.link}>
											{library.link}
										</a>
									</div>
									<div className="books-list scroll">
										{library.books?.length > 0 &&
											library.books
												.filter((x) => x.libraryName == library.name)
												.slice(0, 5)
												.map((book, i) => {
													const booksData = book as Book;
													return <BookCard key={i} book={booksData} noRedirect={true} />;
												})}
									</div>
								</section>
							))
						) : (
							<div className="not-found">
								<NotFoundIcon className="huge-less-icon" />
								<div>No se encuentran bibliotecas con su búsqueda</div>
							</div>
						))}
				</>
			</div>
		</MainContainer>
	);
};

export default LibraryPage;

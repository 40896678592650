import api from "../api";
import { loadAbort } from "../helpers/loadAbort.utility";

export const getMediaFileByChapter = (chapterId: string) => {
	const controller = loadAbort();
	return {
		call: api.get(`api/MediaFile/Chapter/${chapterId}`, { signal: controller.signal }),
		controller,
	};
};

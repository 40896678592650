import React from "react";
import ReadingBooks from "../../../assets/images/people-reading-books.png";

const InfoSection: React.FC = () => {
	return (
		<section id="info-section">
			<article className="info-container">
				<div className="title-container">
					<div className="top-line"></div>
					<h2 className="title">¿Qué es Libro Abierto?</h2>
				</div>
				<div className="description">
					El Proyecto Libro Abierto es una iniciativa del Ministerio de Educación de la República Dominicana
					(MINERD) que busca elaborar los libros de texto para los estudiantes del sector público. Este
					proyecto tiene como objetivo principal proporcionar a los estudiantes acceso gratuito a los libros
					de texto en formato impreso y en línea antes del inicio del próximo año escolar.
				</div>
			</article>
			<div className="image-container">
				<img src={ReadingBooks} alt="Leyendo libros" />
			</div>
		</section>
	);
};

export default InfoSection;

import { User } from "../models";

const tokenStorageName = "token";
const currentUserStorageName = "currentUser";

export const tokenStorage = {
	set: function (token: string) {
		localStorage.setItem(tokenStorageName, token);
	},
	get: function () {
		return localStorage.getItem(tokenStorageName);
	},
	remove: function () {
		localStorage.removeItem(tokenStorageName);
	},
};

export const currentUserStorage = {
	set: function (user: User) {
		localStorage.setItem(currentUserStorageName, JSON.stringify(user));
	},
	get: function () {
		return JSON.parse(`${localStorage.getItem(currentUserStorageName)}`);
	},
	getString: function () {
		return localStorage.getItem(currentUserStorageName);
	},
	remove: function () {
		localStorage.removeItem(currentUserStorageName);
	},
};

import "./chat.scss";
import React, { Fragment, useRef, useState } from "react";
import LibroAbierto from "../../../../assets/images/Libro-Abierto-Logo.svg";
import {
	ConversationRequest,
	ConversationResponse,
	DataPoint,
	HistoryMessage,
	Overrides,
} from "../../../../models/ChatBot/Chat.model";
import ClearButton from "../ClearButton/ClearButton";
import QuestionInput, { QuestionInputHandle } from "../QuestionInput/QuestionInput";
import Answer from "../Answer/Answer";
import LoadingAnswer from "../Answer/LoadingAnswer";
import StopIcon from "../../../../assets/icons/StopIcon";
import { BookPDFDto } from "../../../../models/Book/BookPDFDto.model";
import { chatRequest } from "../../../../services/Chat.service";
import { MiniChatAnswer } from "../../../MiniChat";
import { Answer as MiniAnswer } from "../../../MiniChat/components/Answer";
import { useAuth } from "../../../../hooks/useAuth.hook";

interface ChatProps {
	file: BookPDFDto;
	miniChat?: MiniChatAnswer[];
	handleReference: (r: DataPoint, n: number) => void;
}

const Chat: React.FC<ChatProps> = ({ file, miniChat, handleReference }) => {
	const { user } = useAuth();
	const lastQuestionRef = useRef<string>("");
	const abortFuncs = useRef([] as AbortController[]);
	const questionInputRef = useRef<QuestionInputHandle>(null);
	const chatContentRef = useRef<HTMLDivElement>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [answers, setAnswers] = useState<ConversationResponse[]>([]);
	const [history, setHistory] = useState<HistoryMessage[]>([]);

	const makeApiRequest = async (question: string) => {
		lastQuestionRef.current = question;

		setIsLoading(true);
		const abortController = new AbortController();
		abortFuncs.current.unshift(abortController);

		const userMessage: HistoryMessage = {
			user: question,
			bot: "json",
		};

		const overridesTemplate: Overrides = {
			bookId: file.bookId,
			suggestFollowupQuestions: true,
		};

		const request: ConversationRequest = {
			approach: 0,
			history: [...history, userMessage],
			overrides: overridesTemplate,
		};

		setTimeout(() => scrollDownQuestion(), 50);

		try {
			const result = await chatRequest(request, user!.role, abortController.signal);
			if (result) {

				userMessage.bot = result.answer;

				result.dataPoints?.map((data: DataPoint) => {
					data.content.replace("<p>", "");
					data.content.replace("</p>", "");
					return data;
				});

				setHistory([...history, userMessage]);
				setAnswers([...answers, result]);
			}
		} catch (e) {
			if (!abortController.signal.aborted) {
				console.error(e);
				alert(
					"Ha ocurrido un error. Por favor intenta de nuevo. Si el problema persiste, por favor contactar con el administrador."
				);
			}
			userMessage.bot = "";
			setHistory([...history, userMessage]);
			setAnswers([...answers, {} as ConversationResponse]);
		} finally {
			setIsLoading(false);
			abortFuncs.current = abortFuncs.current.filter((a) => a !== abortController);
			setTimeout(() => scrollDownQuestion(), 50);
		}

		return abortController.abort();
	};

	const scrollDownQuestion = () => {
		if (chatContentRef.current) {
			chatContentRef.current.scrollTo({
				behavior: "smooth",
				top: chatContentRef.current.scrollHeight,
			});
		}
	};

	const selectQuestion = (question: string) => questionInputRef.current?.setQuestion(question);

	const stopGenerating = () => {
		abortFuncs.current.forEach((a) => a.abort());
		setIsLoading(false);
	};

	const clearChat = () => {
		lastQuestionRef.current = "";
		setAnswers([]);
		setHistory([]);
	};

	return (
		<div className="chat-container">
			<div className="chat-body">
				{!(lastQuestionRef.current || miniChat) ? (
					<div className="empty-chat-message">
						<img
							src={LibroAbierto}
							alt="image"
							className="logo-image"
							aria-hidden="true"
						/>
						<h1 className="message-title">Comienza a chatear</h1>
						<h2 className="message-subtitle">
							Este tutor de IA esta configurado para responder tus preguntas
						</h2>
					</div>
				) : (
					<div id="chat-content" className="scroll" ref={chatContentRef}>
						{miniChat &&
							miniChat.map((answer, i) => (
								<MiniAnswer
									key={i}
									answer={answer.answer}
									selectedOption={answer.selectedOption}
								/>
							))}
						{history.map((element, i) => (
							<Fragment key={i}>
								{element.user && element.user != "" && (
									<div className="user-question">{element.user}</div>
								)}
								{element.bot && element.bot != "" && (
									<Answer
										answer={answers[i]}
										onReferenceClicked={(r: DataPoint, id: number) =>
											handleReference(r, id)
										}
										selectQuestion={selectQuestion}
									/>
								)}
							</Fragment>
						))}
						{isLoading && (
							<Fragment>
								<div className="user-question">{lastQuestionRef.current}</div>
								<LoadingAnswer />
							</Fragment>
						)}
					</div>
				)}
			</div>
			<div className="chat-actions">
				{isLoading && (
					<div className="stop-button" onClick={stopGenerating}>
						<StopIcon className="small-icon" />
						<span>Dejar de generar</span>
					</div>
				)}
				<ClearButton disabled={isLoading || answers.length === 0} action={clearChat} />
				<QuestionInput
					ref={questionInputRef}
					disabled={isLoading}
					onSend={(question: string) => makeApiRequest(question)}
				/>
			</div>
		</div>
	);
};

export default Chat;

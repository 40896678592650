import { useFetchAndLoad } from "../../../hooks/useFetchAndLoad.hook";
import LoadingIcons from "react-loading-icons";
import { RestoreModuleProps } from "../models/RestoreModuleProps";
import { restorePasswordCode } from "../../../services";

const EmailFormModal: React.FC<RestoreModuleProps> = (props) => {
	const { loading, callEndpoint } = useFetchAndLoad();

	const restorePasswordCodeApiCall = async (email: string) => await callEndpoint(restorePasswordCode(email));

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		if (!loading) {
			e.preventDefault();

			const email = e.currentTarget.mail.value;

			try {
				const result = await restorePasswordCodeApiCall(email);
				if (result.data) {
					props.setEntity({ ...props.entity, email: result.data.email });
					props.goNextStep();
				}
			} catch (err) {
				console.log(err);
			}
		}
	};

	return (
		<div className="init-form-modal">
			<div className="information-text">
				Si no logras recordar tu contraseña, ingresa tu correo electrónico para enviarte un código de
				restauración.
			</div>
			<div className="form-container">
				<form id="logInForm" onSubmit={handleSubmit}>
					<div className="input-container">
						<input id="mail" type="email" placeholder="Correo electrónico" required />
					</div>
					<button className="submit-btn" type="submit">
						{!loading ? (
							<span>Enviar</span>
						) : (
							<div className="loading-component">
								<LoadingIcons.Oval
									className="loadin-icon"
									stroke="#00ACCE"
									speed={2}
									width="2rem"
									height="2rem"
									strokeWidth="2"
								/>
							</div>
						)}
					</button>
				</form>
			</div>
		</div>
	);
};

export default EmailFormModal;

import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import SearchIcon from "../../assets/icons/SearchIcon";
import UserIcon from "../../assets/icons/UserIcon";
import PrefilCard from "../templates/PerfilCard/PerfilCard";
import SearchField from "../templates/SearchField/SearchField";
import MinerdHorizontalLogo from "../../assets/icons/MinerdHorizontalLogo";
import { useNotification } from "../../hooks/useNotification.hook";
import { useFetchAndLoad } from "../../hooks/useFetchAndLoad.hook";
import clsx from "clsx";
import { BookSearchDto } from "../../models";
import { searchBooksByName } from "../../services";
import LoadingIcons from "react-loading-icons";

const Header: React.FC = () => {
	const location = useLocation();
	const { notifyError } = useNotification();
	const { loading, callEndpoint } = useFetchAndLoad();
	const [books, setBooks] = useState<BookSearchDto[] | null>(null);
	const [showOverlaySearch, setShowOverlaySearch] = useState(false);

	const toggleShowOverlaySearch = () => setShowOverlaySearch(!showOverlaySearch);

	const handleSearch = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		if (e) e.preventDefault();

		if (!loading) {
			const searchFieldText =
				(document.getElementById("search-field") as HTMLInputElement).value ||
				(document.getElementById("search-field-overlay") as HTMLInputElement).value;

			if (searchFieldText && searchFieldText != "/") {
				try {
					const result = await callEndpoint(searchBooksByName(searchFieldText));
					if (result?.data) {
						setShowOverlaySearch(true);
						setBooks(result.data);
					}
				} catch (err) {
					console.log(err);
				}
			} else {
				notifyError("Debe ingresar un libro para buscar.");
				setBooks(null);
			}
		}
	};

	const reset = () => {
		setShowOverlaySearch(false);
		setBooks(null);
	};

	return (
		<header id="app-header">
			<div className="header-content">
				<div className="header-left">
					<Link className="logo-container" to="/">
						<MinerdHorizontalLogo className="header-logo-icon" />
					</Link>
					<nav className="header-navbar">
						<ul className="links">
							<li
								className={clsx({
									link: true,
									active: !(
										location.pathname.includes("biblioteca") ||
										location.pathname.includes("contactos")
									),
								})}>
								<Link to="/">Inicio</Link>
							</li>
							<li className={clsx({ link: true, active: location.pathname.includes("biblioteca") })}>
								<Link to="/biblioteca">Biblioteca</Link>
							</li>
							<li className={clsx({ link: true, active: location.pathname.includes("contactos") })}>
								<Link to="contactos">Contacto</Link>
							</li>
						</ul>
					</nav>
				</div>
				<div className="actions-container">
					{showOverlaySearch && books && books.length > 0 && (
						<div className="close-overlay" onClick={reset}></div>
					)}
					<form id="general-search">
						<SearchField
							handleSearch={handleSearch}
							principal={true}
							placeholder="¿Qué libro quieres buscar?"
						/>
						{!loading ? (
							showOverlaySearch &&
							books &&
							(books.length > 0 ? (
								<div className="options-container scroll">
									{books.map((book) => (
										<Link
											className="book-item"
											key={book.id}
											to={`/${book.levelName}/${book.gradeName}/${book.subjectName}/${book.id}`}
											onClick={reset}>
											{book.title} de {book.levelName}
										</Link>
									))}
								</div>
							) : (
								<div className="options-container scroll">
									<div className="no-item">No se encontraron libros con su busqueda</div>
								</div>
							))
						) : (
							<div className="options-container scroll">
								<div className="loading-container">
									<div className="loading-component">
										<LoadingIcons.Oval
											className="loadin-icon"
											stroke="#00ACCE"
											speed={2}
											width="2rem"
											height="2rem"
											strokeWidth="2"
										/>
									</div>
								</div>
							</div>
						)}
					</form>
					<div
						className={`overlay-search-icon${showOverlaySearch ? " show" : ""}`}
						onClick={toggleShowOverlaySearch}>
						<SearchIcon fill="black" className="small-plus-icon" />
					</div>
					<div className="user-actions">
						<div className="user-icon">
							<UserIcon className="small-plus-icon" />
						</div>
						<PrefilCard />
					</div>
				</div>
			</div>
			<div className={`overlay-search-container${showOverlaySearch ? " show" : ""}`}>
				<form id="general-search">
					<SearchField
						id="search-field-overlay"
						handleSearch={handleSearch}
						placeholder="¿Qué libro quieres buscar?"
					/>
					{!loading ? (
						books &&
						(books.length > 0 ? (
							<div className="options-container scroll">
								{books.map((book) => (
									<Link
										className="book-item"
										key={book.id}
										to={`/${book.levelName}/${book.gradeName}/${book.subjectName}/${book.id}`}
										onClick={reset}>
										{book.title} de {book.levelName}
									</Link>
								))}
							</div>
						) : (
							<div className="options-container scroll">
								<div className="no-item">No se encontraron libros con su busqueda</div>
							</div>
						))
					) : (
						<div className="options-container scroll">
							<div className="loading-container">
								<div className="loading-component">
									<LoadingIcons.Oval
										className="loadin-icon"
										stroke="#00ACCE"
										speed={2}
										width="2rem"
										height="2rem"
										strokeWidth="2"
									/>
								</div>
							</div>
						</div>
					)}
				</form>
			</div>
		</header>
	);
};

export default Header;

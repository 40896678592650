import api from "../api";
import { loadAbort } from "../helpers/loadAbort.utility";
import { RestoreUserDto } from "../models";

export const confirmAccount = (userId: string) => {
	const controller = loadAbort();
	return { call: api.patch(`api/User/Confirmation/Email/${userId}`, { signal: controller.signal }), controller };
};

export const resendConfirmationMail = (mail: string) => {
	const controller = loadAbort();
	return {
		call: api.post(`api/User/Resend/Email`, mail, {
			headers: { "Content-Type": "application/json" },
			signal: controller.signal,
		}),
		controller,
	};
};

export const checkRestoreCode = (user: RestoreUserDto) => {
	const controller = loadAbort();
	return { call: api.post(`api/User/CheckRestoreCode`, user, { signal: controller.signal }), controller };
};

import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import { tokenStorage } from "../helpers/LocalStorage.utility";

export const useToken = () => {
	const { token, setToken } = useContext(AuthContext);

	const getToken = () => {
		return tokenStorage.get();
	};

	const saveToken = (tokenData: string) => {
		setToken(tokenData);
		tokenStorage.set(tokenData);
	};

	const removeToken = () => {
        setToken(null);
        tokenStorage.remove();
	};

	return { token, getToken, saveToken, removeToken };
};

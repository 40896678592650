import React from "react";
import RightArrowIcon from "../../../assets/icons/RightArrowIcon";
import { Link, LinkProps } from "react-router-dom";
import clsx from "clsx";

interface RedBarTextProps {
	title: string;
	subtitle?: string;
	link?: LinkProps;
	redirectText?: string;
}

const RedBarText: React.FC<RedBarTextProps> = ({
	title,
	subtitle,
	link,
	redirectText = "Ver más",
}) => {
	return (
		<div className="red-bar-text-container">
			<div className={clsx({ "red-bar": true, "big-red-bar": subtitle })}></div>
			<div className="red-bar-content">
				<div className="title-container">
					<h5>{title}</h5>
					{subtitle && <h6>{subtitle}</h6>}
				</div>
				{link && (
					<Link to={link.to} state={link.state} className="link-container">
						<span>{redirectText}</span>
						<RightArrowIcon className="small-icon" />
					</Link>
				)}
			</div>
		</div>
	);
};

export default RedBarText;

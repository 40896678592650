import React from "react";

interface LineTitleProps {
	text?: string;
	action?: () => void;
	children?: JSX.Element | JSX.Element[];
}

const LineTitle: React.FC<LineTitleProps> = ({ text, action, children }) => {
	return (
		<div className="line-title" onClick={action}>
			<h3>{text}</h3>
			<div className="row"></div>
			<div className="operations">{children}</div>
		</div>
	);
};

export default LineTitle;

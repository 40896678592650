import React from "react";
import IconProps from "./IconProps.interface";

const ViewIcon: React.FC<IconProps> = (props) => {
	return (
		<svg
			className={props.className}
			onClick={props.onClick}
			viewBox="0 0 28 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M14.0003 0.75C20.7403 0.75 26.3478 5.6 27.5241 12C26.3491 18.4 20.7403 23.25 14.0003 23.25C7.26031 23.25 1.65281 18.4 0.476562 12C1.65156 5.6 7.26031 0.75 14.0003 0.75ZM14.0003 20.75C16.5496 20.7494 19.0233 19.8835 21.0164 18.294C23.0095 16.7044 24.4039 14.4853 24.9716 12C24.4019 9.51663 23.0065 7.30001 21.0136 5.71253C19.0207 4.12506 16.5482 3.26066 14.0003 3.26066C11.4524 3.26066 8.97993 4.12506 6.98703 5.71253C4.99414 7.30001 3.59877 9.51663 3.02906 12C3.59668 14.4853 4.99116 16.7044 6.98424 18.294C8.97733 19.8835 11.451 20.7494 14.0003 20.75ZM14.0003 17.625C12.5085 17.625 11.0777 17.0324 10.0228 15.9775C8.96795 14.9226 8.37531 13.4918 8.37531 12C8.37531 10.5082 8.96795 9.07742 10.0228 8.02252C11.0777 6.96763 12.5085 6.375 14.0003 6.375C15.4922 6.375 16.9229 6.96763 17.9778 8.02252C19.0327 9.07742 19.6253 10.5082 19.6253 12C19.6253 13.4918 19.0327 14.9226 17.9778 15.9775C16.9229 17.0324 15.4922 17.625 14.0003 17.625ZM14.0003 15.125C14.8291 15.125 15.624 14.7958 16.21 14.2097C16.7961 13.6237 17.1253 12.8288 17.1253 12C17.1253 11.1712 16.7961 10.3763 16.21 9.79029C15.624 9.20424 14.8291 8.875 14.0003 8.875C13.1715 8.875 12.3767 9.20424 11.7906 9.79029C11.2046 10.3763 10.8753 11.1712 10.8753 12C10.8753 12.8288 11.2046 13.6237 11.7906 14.2097C12.3767 14.7958 13.1715 15.125 14.0003 15.125Z"
				fill={props.fill || "white"}
			/>
		</svg>
	);
};

export default ViewIcon;

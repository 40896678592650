import { useState } from "react";
import { Grade } from "../models";
import { useFetchAndLoad } from "./useFetchAndLoad.hook";
import { getGradesByName } from "../services";
import { useNavigate, useParams } from "react-router-dom";
import { getLevelByName } from "../helpers/LevelData.utility";
import { useAsync } from "./useAsync.hook";

export const useGrade = () => {
	const params = useParams();
	const navigate = useNavigate();
	const [grade, setGrade] = useState<Grade | null>(null);
	const { loading, callEndpoint } = useFetchAndLoad();

	const getGradeByNameApiCall = async () =>
		callEndpoint(getGradesByName(getLevelByName(params.level!)!, params.grade!));

	const adaptData = (backData: any) => {
		if (backData.data) setGrade(backData.data);
		else navigate(`not-found`);
	};

	useAsync(getGradeByNameApiCall, adaptData);

	return { loadingGrade: loading, grade };
};

import React from "react";
import IconProps from "./IconProps.interface";

const LogoutIcon: React.FC<IconProps> = (props) => {
	return (
		<svg
			className={props.className}
			onClick={props.onClick}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_747_3253)">
				<path
					d="M7.97344 14.6445H1.99334C1.62656 14.6445 1.32891 14.3468 1.32891 13.9801V2.01993C1.32891 1.65314 1.62659 1.35549 1.99334 1.35549H7.97344C8.34087 1.35549 8.63787 1.05849 8.63787 0.69105C8.63787 0.323613 8.34087 0.0265503 7.97344 0.0265503H1.99334C0.894344 0.0265503 0 0.920925 0 2.01993V13.9801C0 15.0791 0.894344 15.9734 1.99334 15.9734H7.97344C8.34087 15.9734 8.63787 15.6764 8.63787 15.309C8.63787 14.9415 8.34087 14.6445 7.97344 14.6445Z"
					fill={props.fill || "#ED232A"}
				/>
				<path
					className="arrow"
					d="M15.802 7.52689L11.7622 3.54017C11.5017 3.28236 11.0805 3.2857 10.8226 3.54682C10.5648 3.80795 10.5675 4.22854 10.8293 4.48636L13.7163 7.33551H5.98011C5.61267 7.33551 5.31567 7.63251 5.31567 7.99995C5.31567 8.36739 5.61267 8.66442 5.98011 8.66442H13.7163L10.8293 11.5136C10.5675 11.7714 10.5655 12.192 10.8226 12.4531C10.9529 12.5847 11.1243 12.6511 11.2957 12.6511C11.4645 12.6511 11.6333 12.5873 11.7622 12.4597L15.802 8.47301C15.9283 8.34811 16 8.17798 16 7.99992C16 7.82192 15.929 7.65248 15.802 7.52689Z"
					fill={props.fill || "#ED232A"}
				/>
			</g>
		</svg>
	);
};

export default LogoutIcon;

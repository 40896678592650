import React, { useEffect, useRef, useState } from "react";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import MainContainer from "../../components/layout/MainContainer";
import Breadcrumbs from "../../components/templates/BreadCrumbs/BreadCrumbs";
import getStudentState from "../../helpers/StudentState.utility";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import RedBarText from "../../components/templates/RedBarText/RedBarText";
import { useFetchAndLoad } from "../../hooks/useFetchAndLoad.hook";
import { CountActivityEventDto, EventEntityType } from "../../models";
import { countViewActivity, getBookPDFById } from "../../services";
import { useAsync } from "../../hooks/useAsync.hook";
import LoadingIcons from "react-loading-icons";
import { useAuth } from "../../hooks/useAuth.hook";
import { BookPDFDto } from "../../models/Book/BookPDFDto.model";
import { ChatBot } from "../../components/ChatBot";
import { PDFReader, PDFReaderHandle } from "./components/PDFReader";
import { DataPoint } from "../../models/ChatBot/Chat.model";
import { ChatBotButton } from "../../components/ChatBot/components/ChatBotButton";
import { MiniChatAnswer } from "../../components/MiniChat";
import clsx from "clsx";

const BookReader: React.FC = () => {
	const { user } = useAuth();
	const params = useParams();
	const navigate = useNavigate();
	const location = useLocation();
	const [book, setBook] = useState<BookPDFDto>();
	const studentState = getStudentState(params);
	const { loading, callEndpoint } = useFetchAndLoad();
	const { callEndpoint: callCountActivityEndpoint } = useFetchAndLoad();
	const [showChatBot, setShowChatBot] = useState<boolean>(false);
	const pdfReaderRef = useRef<PDFReaderHandle>(null);
	const [miniChat, setMiniChat] = useState<MiniChatAnswer[]>();

	const getBookApiCall = async () => await callEndpoint(getBookPDFById(params.book!));

	const adaptData = (backData: any) => {
		if (backData.data) setBook(backData.data);
		else navigate("/not-found");
	};

	const countViewActivityApiCall = async () => {
		try {
			const activity: CountActivityEventDto = {
				userId: user?.id,
				entityId: book?.id,
				entityType: EventEntityType.Book,
			};

			await callCountActivityEndpoint(countViewActivity(activity));
		} catch (err) {
			console.log(err);
		}
	};

	const handleReference = (d: DataPoint, n: number) => {
		pdfReaderRef.current?.handleReference(d, n);
	};

	const toggleShowChatBot = () => setShowChatBot(!showChatBot);

	useEffect(() => {
		if (book) countViewActivityApiCall();
	}, [book]);

	useEffect(() => {
		if (location.state) {
			location.state.minichat && setMiniChat(location.state.minichat);
			setShowChatBot(true);
		}
	}, []);

	useAsync(getBookApiCall, adaptData);

	return (
		<>
			<MainContainer id="BookReader" className={showChatBot ? "hide-all" : ""}>
				{!loading ? (
					book && (
						<>
							<div className="page-top">
								<Breadcrumbs
									history={[
										"Libro Abierto",
										studentState,
										params.subject!,
										book.title,
										"Lector",
									]}
								/>
								<RedBarText title={book!.title} />
							</div>
							<div
								id="book-reader-container"
								className={clsx({ "show-chat-bot": showChatBot })}>
								<div className="book-container">
									<div className="book-options">
										{!showChatBot && (
											<ChatBotButton toggleShowChatBot={toggleShowChatBot} />
										)}
									</div>
									{book && <PDFReader ref={pdfReaderRef} file={book} />}
								</div>
								<div className="chat-bot-container">
									<ChatBot
										file={book}
										miniChat={miniChat}
										toggleShow={toggleShowChatBot}
										handleReference={handleReference}
									/>
								</div>
							</div>
						</>
					)
				) : (
					<div className="loading-container">
						<div className="loading-component">
							<LoadingIcons.Oval
								className="loadin-icon"
								stroke="#00ACCE"
								speed={2}
								width="6rem"
								height="6rem"
								strokeWidth="3"
							/>
						</div>
					</div>
				)}
			</MainContainer>
			{book && showChatBot && (
				<div id="over-chat-bot-container">
					<ChatBot
						file={book}
						miniChat={miniChat}
						toggleShow={toggleShowChatBot}
						handleReference={handleReference}
					/>
				</div>
			)}
		</>
	);
};

export default BookReader;

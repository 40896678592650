import { useEffect } from "react";

export const useAsync = (
	asyncFunc: () => Promise<unknown>,
	successFunc: (p: any) => unknown,
	dependencies: Array<any> = [],
	condition = true
) => {
	useEffect(() => {
		let isActive = true;

		if (condition === true) {
			asyncFunc()
				.then((result: any) => {
					if (isActive) successFunc(result);
				})
				.catch((err) => {
					if (isActive) successFunc({ error: err });
				});
		}
		return () => {
			isActive = false;
		};
	}, dependencies);
};

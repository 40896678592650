import React from "react";
import IconProps from "./IconProps.interface";

const MinerdHorizontalLogo: React.FC<IconProps> = (props) => {
	return (
		<svg
			className={props.className}
			onClick={props.onClick}
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			viewBox="0 0 273 151"
			xmlSpace="preserve">
			<g>
				<g>
					<path
						fill="#ED232A"
						d="M15.6,134c0.97-0.26,1.72-0.41,1.72-1.67v-7.43c0-1.02-0.68-1.29-1.4-1.5c-0.14-0.03-0.03-0.66,0.07-0.65
			c1.23,0.19,3.9,0.1,4.58,0.1c1.55,0,2.62-0.07,4.1-0.1c0.43,0,0.44,0.27,0.36,0.68l-0.39,1.63c-0.05,0.26-0.49,0.26-0.56,0
			c-0.29-0.88-0.75-1.24-2.82-1.24c-1.06,0-1.43,0.31-1.43,1.11v2.69c0,0.44,0.22,0.53,0.56,0.53h0.44c1.17,0,2.45-0.19,3.06-0.68
			c0.19-0.15,0.58-0.12,0.41,0.25l-0.92,2.14c-0.15,0.34-0.44,0.31-0.63,0.03c-0.38-0.56-1.21-0.8-2.04-0.8h-0.32
			c-0.34,0-0.56,0.08-0.56,0.53v2.71c0,0.95,0.34,1.36,1.67,1.36c2.26,0,3.52-1.28,3.9-1.82c0.1-0.14,0.65,0,0.65,0.25
			c0,0.73-0.78,2.64-1.91,2.6c-1.16-0.05-1.85-0.1-3.23-0.1c-1.55,0-3.67-0.05-5.19,0.1C15.53,134.75,15.46,134.04,15.6,134z"
					/>
					<path
						fill="#ED232A"
						d="M27.88,134c0.97-0.26,1.72-0.41,1.72-1.67v-7.43c0-1.02-0.68-1.31-1.4-1.5c-0.14-0.03-0.03-0.66,0.07-0.65
			c0.7,0.08,1.41,0.1,2.35,0.1c1.53,0,2.72-0.1,3.57-0.1c3.96,0,6.35,2.47,6.35,5.72c0,3.16-2.26,6.26-6.36,6.26
			c-0.82,0-1.87-0.1-3.32-0.1c-1.28,0-2.11,0.02-2.88,0.1C27.81,134.75,27.74,134.04,27.88,134z M38.04,129.68
			c0-3.42-1.7-5.92-4.47-5.92c-1.04,0-1.46,0.34-1.46,1.14v7.43c0,0.88,0.82,1.4,2.45,1.4C37.03,133.73,38.04,132.37,38.04,129.68z"
					/>
					<path
						fill="#ED232A"
						d="M44.07,130.21v-5.22c0-1.02-0.46-1.43-1.34-1.53c-0.14-0.02-0.03-0.66,0.1-0.66h5.07
			c0.14,0,0.24,0.65,0.1,0.66c-0.7,0.08-1.38,0.34-1.38,1.53v5.31c0,2.33,1.34,3.32,2.96,3.32c1.7,0,3.11-0.99,3.11-3.32v-5.31
			c0-1.07-0.61-1.41-1.45-1.53c-0.12-0.02-0.03-0.66,0.1-0.66h4.02c0.1,0,0.2,0.65,0.07,0.66c-0.65,0.08-1.33,0.34-1.33,1.53v5.19
			c0,3.16-2.04,4.8-4.81,4.8C46.83,134.97,44.07,133.68,44.07,130.21z"
					/>
					<path
						fill="#ED232A"
						d="M57.71,129c0-2.91,1.74-6.48,6.64-6.48c2.01,0,3.69,0.63,3.49,1.22l-0.25,0.73
			c-0.75,2.16-1.21-0.41-4.42-0.41c-2.36,0-3.28,1.57-3.28,3.78c0,3.86,2.94,5.26,5,5.26c0.34,0,2.13-0.24,2.72-0.65
			c0.14-0.08,0.56,0.39,0.46,0.54c-0.73,1.09-3.1,1.97-3.69,1.97C61.51,134.97,57.71,133.32,57.71,129z"
					/>
					<path
						fill="#ED232A"
						d="M82.29,134.7h-4.92c-0.24,0-0.22-0.61-0.05-0.65c0.6-0.12,1.33-0.31,0.7-1.74l-0.08-0.15
			c-0.17-0.44-0.34-0.49-0.8-0.49H73.8c-0.51,0-0.68,0.05-0.87,0.49l-0.05,0.15c-0.56,1.38,0.08,1.63,0.87,1.74
			c0.24,0.03,0.17,0.65-0.05,0.65h-3.83c-0.19,0-0.24-0.61-0.05-0.65c0.68-0.12,1.09-0.32,1.7-1.74l2.88-6.69
			c0.66-1.48,0.87-2.11,0.87-2.76c0-0.1,1.17-0.37,1.23-0.26l4.15,9.68c0.59,1.38,1,1.65,1.68,1.75
			C82.55,134.09,82.5,134.7,82.29,134.7z M77.15,130.29l-1.7-4.12l-1.72,4.12c-0.1,0.24,0.12,0.29,0.34,0.29h2.76
			C77.03,130.58,77.26,130.53,77.15,130.29z"
					/>
					<path
						fill="#ED232A"
						d="M83.58,129c0-2.91,1.74-6.48,6.64-6.48c2.01,0,3.69,0.63,3.49,1.22l-0.25,0.73
			c-0.75,2.16-1.21-0.41-4.42-0.41c-2.36,0-3.28,1.57-3.28,3.78c0,3.86,2.94,5.26,5,5.26c0.34,0,2.13-0.24,2.72-0.65
			c0.14-0.08,0.56,0.39,0.46,0.54c-0.73,1.09-3.1,1.97-3.69,1.97C87.37,134.97,83.58,133.32,83.58,129z"
					/>
					<path
						fill="#ED232A"
						d="M96.22,134.04c0.92-0.12,1.38-0.51,1.38-1.53v-7.52c0-1.02-0.46-1.41-1.38-1.53
			c-0.14-0.02-0.03-0.66,0.1-0.66h5.07c0.14,0,0.24,0.65,0.1,0.66c-0.71,0.1-1.39,0.34-1.39,1.53v7.52c0,1.19,0.68,1.43,1.39,1.53
			c0.14,0.02,0.03,0.66-0.1,0.66h-5.07C96.18,134.7,96.08,134.05,96.22,134.04z"
					/>
					<path
						fill="#ED232A"
						d="M103.85,128.83c0-3.45,2.5-6.31,6.23-6.31c3.56,0,6.19,2.55,6.19,6.12c0,3.47-2.47,6.33-6.23,6.33
			C106.52,134.97,103.85,132.39,103.85,128.83z M113.81,129.7c0-3.3-2.19-5.84-4.46-5.84c-1.38,0-3.01,0.97-3.01,3.91
			c0,3.33,2.21,5.85,4.44,5.85C112.16,133.63,113.81,132.66,113.81,129.7z M110.1,118.49c0.31-0.44,1.84,1,1.43,1.29l-2.18,1.63
			c-0.22,0.17-0.9-0.48-0.73-0.71L110.1,118.49z"
					/>
					<path
						fill="#ED232A"
						d="M129.27,134.55l-7.98-7.91v5.78c0,1.19,0.7,1.34,1.82,1.62c0.14,0.03,0.07,0.66-0.1,0.66h-4.39
			c-0.12,0-0.24-0.61-0.07-0.66c0.58-0.27,1.36-0.29,1.36-1.62v-6.36c0-1.4-0.29-1.77-0.54-2.31c-0.22-0.32-0.39-0.49-0.39-0.53
			c0-0.17,0.08-0.42,0.41-0.42h1.36c0.15,0,0.27,0.02,0.39,0.15l7.79,7.81v-5.72c0-1.22-0.8-1.33-1.82-1.58
			c-0.14-0.03-0.07-0.66,0.1-0.66h4.39c0.12,0,0.19,0.63,0.07,0.66c-0.73,0.25-1.36,0.36-1.36,1.58v6.4c0,1.22,0.05,2.48,0.2,3.13
			c0.05,0.24-0.17,0.31-0.54,0.31C129.64,134.87,129.47,134.77,129.27,134.55z"
					/>
				</g>
				<g>
					<g>
						<path
							fill="#003876"
							d="M58.74,54.46l1.71-8.87l-4.66-10.96l-5.21-5.44c0,0-0.34-0.26-0.3-0.25c8.36,3.63,14.61,13.49,15.65,25.43
				c0,0.02,0.01,0.07,0.01,0.07L58.74,54.46z M56.08,54.47l-9.18-0.03l0-25.01c0,0,0.08,0.06,0.11,0.1
				c5.39,4.63,9.06,13.89,9.06,24.56C56.08,54.21,56.08,54.47,56.08,54.47z M65.9,54.43l-7.14,0.02c0,0-0.02-0.85-0.04-1.32
				c0.02,0.55,0.04-1.63-0.25-4.39c-0.69-6.64-2.7-12.02-5.64-16.62c-1.19-1.86-2.54-3.16-2.54-3.16l-0.03-0.04
				c0,0,0.7,0.42,1.09,0.67c0.57,0.37,1.14,0.74,1.67,1.12c1.92,1.37,3.55,2.92,4.42,3.81c0.09,0.09,0.39,0.4,0.72,0.77
				c0.33,0.37,0.69,0.79,0.96,1.11c1.09,1.31,2.15,2.79,3.09,4.48c0.52,0.94,0.96,1.89,1.34,2.84c1.59,3.91,2.15,7.81,2.33,10.38
				C65.88,54.1,65.9,54.43,65.9,54.43z M65.88,54.1C65.88,54.1,65.88,54.11,65.88,54.1C65.88,54.11,65.88,54.11,65.88,54.1z
				 M69.45,53.19l-3.53-14.3l-7.41-7.92c0,0-2.19-1.56-3.45-2.41c-0.48-0.33-0.88-0.53-0.88-0.53s0.03,0.01,0.04,0.02
				c10.53,4.26,18.06,14.4,18.49,26.36c0,0.01,0,0.03,0,0.03L69.45,53.19z M55.19,28.63 M68.61,54.44c0,0-0.26-2.9-0.78-5.48
				c-0.44-2.21-1.14-4.14-1.22-4.37c-0.44-1.25-0.94-2.65-1.7-4.16c-1.39-2.72-2.8-5.27-5.34-7.96c-0.78-0.82-1.64-1.69-2.66-2.53
				c-0.68-0.56-1.28-1-1.71-1.3c0.26,0.15,0.58,0.35,0.99,0.58c0.75,0.43,1.65,0.97,2.61,1.61c0.43,0.29,0.83,0.6,1.28,0.94
				c0.1,0.07,1.45,1.12,2.79,2.38c1.11,1.05,2.16,2.26,2.88,3.15c1.3,1.62,2.54,3.5,3.61,5.62c1.25,2.47,2.08,4.98,2.57,7.19
				c0.38,1.66,0.66,4.33,0.66,4.33L68.61,54.44z M65.9,54.43l-7.14,0.02c0,0-0.02-0.85-0.04-1.32c0.02,0.55,0.04-1.63-0.25-4.39
				c-0.69-6.64-2.7-12.02-5.64-16.62c-1.19-1.86-2.54-3.16-2.54-3.16l-0.03-0.04c0,0,0.7,0.42,1.09,0.67
				c0.57,0.37,1.14,0.74,1.67,1.12c1.92,1.37,3.55,2.92,4.42,3.81c0.09,0.09,0.39,0.4,0.72,0.77c0.33,0.37,0.69,0.79,0.96,1.11
				c1.09,1.31,2.15,2.79,3.09,4.48c0.52,0.94,0.96,1.89,1.34,2.84c1.59,3.91,2.15,7.81,2.33,10.38C65.88,54.1,65.9,54.43,65.9,54.43
				z M65.88,54.1C65.88,54.1,65.88,54.11,65.88,54.1C65.88,54.11,65.88,54.11,65.88,54.1z M40.46,29.19l-5.21,5.45L30.58,45.6
				l1.71,8.87l-7.2-0.03c0,0,0-0.05,0.01-0.07c1.04-11.94,7.3-21.8,15.65-25.44C40.79,28.92,40.46,29.19,40.46,29.19z M34.96,54.48
				c0,0,0-0.26,0-0.39c0-10.67,3.67-19.93,9.06-24.57c0.04-0.03,0.11-0.1,0.11-0.1l0,25.02L34.96,54.48z M25.16,54.12L25.16,54.12
				C25.16,54.12,25.16,54.12,25.16,54.12L25.16,54.12C25.16,54.12,25.16,54.12,25.16,54.12z M40.74,28.97c0,0-1.34,1.3-2.54,3.16
				c-2.94,4.6-4.95,9.98-5.64,16.62c-0.29,2.76-0.27,4.94-0.25,4.39c-0.02,0.47-0.04,1.32-0.04,1.32l-7.14-0.02
				c0,0,0.02-0.29,0.02-0.33c0,0,0,0,0,0c0,0,0,0,0,0c0.18-2.58,0.74-6.48,2.33-10.39c0.39-0.95,0.82-1.91,1.34-2.84
				c0.94-1.69,2-3.17,3.09-4.48c0.26-0.32,0.63-0.74,0.96-1.11c0.33-0.37,0.63-0.68,0.72-0.77c0.87-0.89,2.5-2.44,4.42-3.81
				c0.53-0.38,1.1-0.75,1.67-1.12c0.39-0.25,1.09-0.67,1.09-0.67L40.74,28.97z M35.98,28.56c-1.25,0.85-3.45,2.41-3.45,2.41
				l-7.41,7.93l-3.77,15.56l-3.03-0.01c0,0,0-0.02,0-0.03c0.44-11.96,7.96-22.11,18.49-26.37c0.01-0.01,0.04-0.02,0.04-0.02
				S36.46,28.24,35.98,28.56z M31.48,32.47c-2.54,2.69-3.95,5.24-5.34,7.96c-0.77,1.51-1.26,2.91-1.7,4.16
				c-0.08,0.24-0.78,2.17-1.22,4.37c-0.52,2.58-0.78,5.48-0.78,5.48l-3.97,0c0,0,0.28-2.67,0.66-4.34c0.5-2.21,1.32-4.72,2.57-7.19
				c1.07-2.12,2.31-4,3.61-5.63c0.71-0.89,1.77-2.1,2.88-3.15c1.33-1.26,2.69-2.31,2.79-2.38c0.45-0.34,0.85-0.65,1.28-0.94
				c0.96-0.65,1.86-1.18,2.61-1.61c0.4-0.23,0.73-0.43,0.99-0.58c-0.44,0.3-1.03,0.74-1.71,1.3C33.11,30.78,32.25,31.65,31.48,32.47
				z M35.85,28.63 M25.16,54.12L25.16,54.12C25.16,54.12,25.16,54.12,25.16,54.12L25.16,54.12C25.16,54.12,25.16,54.12,25.16,54.12z
				 M40.74,28.97c0,0-1.34,1.3-2.54,3.16c-2.94,4.6-4.95,9.98-5.64,16.62c-0.29,2.76-0.27,4.94-0.25,4.39
				c-0.02,0.47-0.04,1.32-0.04,1.32l-7.14-0.02c0,0,0.02-0.29,0.02-0.33c0,0,0,0,0,0c0,0,0,0,0,0c0.18-2.58,0.74-6.48,2.33-10.39
				c0.39-0.95,0.82-1.91,1.34-2.84c0.94-1.69,2-3.17,3.09-4.48c0.26-0.32,0.63-0.74,0.96-1.11c0.33-0.37,0.63-0.68,0.72-0.77
				c0.87-0.89,2.5-2.44,4.42-3.81c0.53-0.38,1.1-0.75,1.67-1.12c0.39-0.25,1.09-0.67,1.09-0.67L40.74,28.97z M50.85,19.1l-10.71,0
				c-0.1,0-0.18-0.08-0.18-0.18v-0.41c0-0.1,0.08-0.18,0.18-0.18l10.71,0c0.1,0,0.18,0.08,0.18,0.18v0.41
				C51.03,19.02,50.95,19.1,50.85,19.1z M50.85,27.19l-10.71,0c-0.1,0-0.18-0.08-0.18-0.18V26.6c0-0.1,0.08-0.18,0.18-0.18l10.71,0
				c0.1,0,0.18,0.08,0.18,0.18v0.41C51.03,27.11,50.95,27.19,50.85,27.19z M74.13,57.32l-57.22,0c-0.1,0-0.18-0.08-0.18-0.18l0-0.48
				c0-0.1,0.08-0.18,0.18-0.18l57.22,0c0.1,0,0.18,0.08,0.18,0.18v0.48C74.31,57.24,74.23,57.32,74.13,57.32z M44.5,12.7h-0.17
				c-0.02,0-0.04-0.02-0.04-0.04v-0.41c0-0.02,0.02-0.04,0.04-0.04h0.17c0.02,0,0.04,0.02,0.04,0.04v0.41
				C44.53,12.68,44.52,12.7,44.5,12.7z M46.67,12.7H46.5c-0.02,0-0.04-0.02-0.04-0.04v-0.41c0-0.02,0.02-0.04,0.04-0.04h0.17
				c0.02,0,0.04,0.02,0.04,0.04v0.41C46.7,12.68,46.69,12.7,46.67,12.7z M44.29,12.79v-0.13l2.41,0v0.13L44.29,12.79z M45.6,11.05
				h-0.2c-0.03,0-0.05-0.02-0.05-0.05v-0.44c0-0.03,0.02-0.05,0.05-0.05h0.2c0.03,0,0.05,0.02,0.05,0.05v0.44
				C45.65,11.03,45.62,11.05,45.6,11.05z M45.43,13.85v-2.8h0.14v2.8L45.43,13.85z M41.36,17.81c0,0,0-0.01,0-0.02
				c0.01-2.38,1.86-4.31,4.14-4.31c2.28,0,4.13,1.92,4.14,4.29c0,0.01,0,0.04,0,0.04L41.36,17.81z M50.7,19.06l-10.41,0
				c-0.18,0-0.32-0.14-0.32-0.32v-0.09c0-0.18,0.14-0.32,0.32-0.32l10.41,0c0.18,0,0.32,0.14,0.32,0.32v0.09
				C51.02,18.92,50.88,19.06,50.7,19.06z M50.7,27.18l-10.41,0c-0.18,0-0.32-0.14-0.32-0.32v-0.09c0-0.18,0.14-0.32,0.32-0.32
				l10.41,0c0.18,0,0.32,0.14,0.32,0.32v0.09C51.02,27.04,50.88,27.18,50.7,27.18z M46.03,25.77h-1.04c-0.18,0-0.32-0.14-0.32-0.32
				l0-5.57c0-0.18,0.14-0.32,0.32-0.32h1.04c0.18,0,0.32,0.14,0.32,0.32v5.57C46.35,25.62,46.2,25.77,46.03,25.77z M48.74,25.77
				h-1.04c-0.18,0-0.32-0.14-0.32-0.32v-5.57c0-0.18,0.14-0.32,0.32-0.32l1.04,0c0.18,0,0.32,0.14,0.32,0.32l0,5.57
				C49.06,25.62,48.91,25.77,48.74,25.77z M43.3,25.77l-1.04,0c-0.18,0-0.32-0.14-0.32-0.32l0-5.57c0-0.18,0.14-0.32,0.32-0.32h1.04
				c0.18,0,0.32,0.14,0.32,0.32v5.57C43.62,25.62,43.48,25.77,43.3,25.77z M55.86,56.83l0-1.27c0-0.15,0.12-0.27,0.27-0.27l2.53,0
				c0.15,0,0.27,0.12,0.27,0.27v1.18L55.86,56.83z M58.61,61.34h-2.42c-0.18,0-0.32-0.14-0.32-0.32v-2.42
				c0-0.18,0.14-0.32,0.32-0.32l2.42,0c0.18,0,0.32,0.14,0.32,0.32v2.42C58.93,61.19,58.79,61.34,58.61,61.34z M65.66,56.83v-1.27
				c0-0.15,0.12-0.27,0.27-0.27l2.53,0c0.15,0,0.27,0.12,0.27,0.27v1.18L65.66,56.83z M68.41,61.34l-2.42,0
				c-0.18,0-0.32-0.14-0.32-0.32l0-2.42c0-0.18,0.14-0.32,0.32-0.32h2.42c0.18,0,0.32,0.14,0.32,0.32l0,2.42
				C68.73,61.19,68.59,61.34,68.41,61.34z M32.09,56.74v-1.18c0-0.15,0.12-0.27,0.27-0.27h2.53c0.15,0,0.27,0.12,0.27,0.27v1.27
				L32.09,56.74z M34.84,61.34l-2.43,0c-0.18,0-0.32-0.14-0.32-0.32v-2.43c0-0.18,0.14-0.32,0.32-0.32h2.43
				c0.18,0,0.32,0.14,0.32,0.32v2.43C35.16,61.19,35.02,61.34,34.84,61.34z M22.29,56.74v-1.18c0-0.15,0.12-0.27,0.27-0.27l2.53,0
				c0.15,0,0.27,0.12,0.27,0.27l0,1.27L22.29,56.74z M25.04,61.34l-2.43,0c-0.18,0-0.32-0.14-0.32-0.32v-2.43
				c0-0.18,0.14-0.32,0.32-0.32h2.43c0.18,0,0.32,0.14,0.32,0.32l0,2.43C25.36,61.19,25.21,61.34,25.04,61.34z M43.98,56.74v-1.18
				c0-0.15,0.12-0.27,0.27-0.27l2.53,0c0.15,0,0.27,0.12,0.27,0.27v1.27L43.98,56.74z M46.72,61.34H44.3
				c-0.18,0-0.32-0.14-0.32-0.32l0-2.43c0-0.18,0.14-0.32,0.32-0.32h2.43c0.18,0,0.32,0.14,0.32,0.32l0,2.43
				C47.05,61.19,46.9,61.34,46.72,61.34z M73.99,57.31l-56.93,0c-0.18,0-0.32-0.14-0.32-0.32V56.8c0-0.18,0.14-0.32,0.32-0.32
				l56.93,0c0.18,0,0.32,0.14,0.32,0.32v0.19C74.31,57.17,74.16,57.31,73.99,57.31z M73.99,64.05l-56.93,0
				c-0.18,0-0.32-0.14-0.32-0.32v-1.35c0-0.18,0.14-0.32,0.32-0.32l56.93,0c0.18,0,0.32,0.14,0.32,0.32v1.35
				C74.31,63.9,74.16,64.05,73.99,64.05z"
						/>
					</g>
					<g>
						<path
							fill="#003876"
							d="M16.98,76.46v-0.02c0-2.11,1.55-3.97,3.85-3.97c1.29,0,2.1,0.37,2.86,1.02l-0.56,0.66
				c-0.59-0.52-1.25-0.88-2.34-0.88c-1.7,0-2.92,1.44-2.92,3.15v0.02c0,1.83,1.17,3.19,3.04,3.19c0.88,0,1.69-0.34,2.22-0.77v-1.91
				H20.8v-0.78h3.17v3.06c-0.71,0.64-1.79,1.17-3.08,1.17C18.47,80.41,16.98,78.66,16.98,76.46z"
						/>
						<path
							fill="#003876"
							d="M26.83,76.46v-0.02c0-2.12,1.59-3.97,3.93-3.97s3.91,1.83,3.91,3.95c0.01,0.01,0.01,0.01,0,0.02
				c0,2.12-1.59,3.97-3.93,3.97S26.83,78.58,26.83,76.46z M33.76,76.46v-0.02c0-1.74-1.27-3.17-3.03-3.17
				c-1.75,0-3.01,1.4-3.01,3.15v0.02c0,1.74,1.27,3.17,3.03,3.17C32.51,79.61,33.76,78.21,33.76,76.46z"
						/>
						<path
							fill="#003876"
							d="M37.74,72.6h3.27c0.88,0,1.57,0.25,2.01,0.68c0.32,0.33,0.49,0.74,0.49,1.23v0.02c0,1-0.61,1.51-1.22,1.78
				c0.91,0.27,1.65,0.8,1.65,1.85v0.02c0,1.32-1.11,2.1-2.79,2.1h-3.41V72.6z M40.87,76.03c1.04,0,1.77-0.47,1.77-1.37v-0.02
				c0-0.76-0.6-1.25-1.7-1.25H38.6v2.64H40.87z M41.18,79.5c1.16,0,1.89-0.52,1.89-1.37v-0.02c0-0.83-0.7-1.32-2.04-1.32H38.6v2.71
				H41.18z"
						/>
						<path fill="#003876" d="M46.99,72.6h0.87v7.68h-0.87V72.6z" />
						<path
							fill="#003876"
							d="M51.36,72.6h5.55v0.79h-4.68v2.62h4.19v0.79h-4.19v2.69h4.74v0.79h-5.61V72.6z"
						/>
						<path
							fill="#003876"
							d="M59.96,72.6h3.3c0.94,0,1.7,0.29,2.18,0.77c0.37,0.37,0.59,0.91,0.59,1.51v0.02c0,1.27-0.88,2.02-2.08,2.24
				l2.36,3.14h-1.06l-2.23-2.98H63h-2.17v2.98h-0.87V72.6z M63.18,76.52c1.15,0,1.98-0.59,1.98-1.58v-0.02
				c0-0.94-0.72-1.51-1.96-1.51h-2.37v3.12H63.18z"
						/>
						<path
							fill="#003876"
							d="M69.14,72.6h0.81l4.84,6.15V72.6h0.84v7.68h-0.69l-4.96-6.3v6.3h-0.85V72.6z"
						/>
						<path
							fill="#003876"
							d="M78.72,76.46v-0.02c0-2.12,1.59-3.97,3.93-3.97s3.91,1.83,3.91,3.95c0.01,0.01,0.01,0.01,0,0.02
				c0,2.12-1.59,3.97-3.93,3.97S78.72,78.58,78.72,76.46z M85.65,76.46v-0.02c0-1.74-1.27-3.17-3.03-3.17
				c-1.75,0-3.01,1.4-3.01,3.15v0.02c0,1.74,1.27,3.17,3.03,3.17C84.4,79.61,85.65,78.21,85.65,76.46z"
						/>
						<path
							fill="#003876"
							d="M94.17,72.6h2.67c2.41,0,4.08,1.66,4.08,3.82v0.02c0,2.16-1.67,3.84-4.08,3.84h-2.67V72.6z M96.84,79.48
				c1.94,0,3.18-1.32,3.18-3.02v-0.02c0-1.7-1.24-3.04-3.18-3.04h-1.8v6.08H96.84z"
						/>
						<path
							fill="#003876"
							d="M104,72.6h5.55v0.79h-4.68v2.62h4.19v0.79h-4.19v2.69h4.74v0.79H104V72.6z"
						/>
						<path fill="#003876" d="M117.14,72.6h0.87v6.88h4.32v0.8h-5.19V72.6z" />
						<path
							fill="#003876"
							d="M128.03,72.55h0.81l3.5,7.73h-0.93l-0.9-2.03h-4.18l-0.91,2.03h-0.89L128.03,72.55z M130.17,77.47
				l-1.75-3.92l-1.75,3.92H130.17z"
						/>
					</g>
					<g>
						<path
							fill="#003876"
							d="M27.38,99c-1,0.33-2.38,0.29-3.33-0.93l-2.44-3.14c-0.68-0.89-1.49-1.01-2.6-1.03v3.07
				c0,1.11,0.76,1.3,1.77,1.43c0.11,0.02,0.03,0.54-0.1,0.54h-4.8c-0.13,0-0.22-0.52-0.09-0.54c0.81-0.11,1.31-0.43,1.31-1.43v-7.06
				c0-1.04-0.55-1.35-1.31-1.55c-0.13-0.03-0.05-0.55,0.09-0.54c0.62,0.08,1.31,0.1,1.82,0.1c1.17,0,2.27-0.1,3.03-0.1
				c2.39,0,3.99,0.98,3.99,2.93c0,1.84-1.49,2.74-2.82,2.82v0.08c0.63,0,1.09,0.27,1.79,1.14l2.36,2.96
				c0.49,0.62,0.97,0.79,1.31,0.79C27.51,98.54,27.48,98.97,27.38,99z M22.74,91.17c0-1.7-1.08-2.53-2.49-2.53
				c-0.93,0-1.24,0.38-1.24,1.27v3.26h0.46C21.84,93.17,22.74,92.76,22.74,91.17z"
						/>
						<path
							fill="#003876"
							d="M29.94,98.4c0.92-0.24,1.63-0.38,1.63-1.55v-7.03c0-1.01-0.65-1.28-1.33-1.46
				c-0.13-0.03-0.05-0.55,0.08-0.54c1.16,0.13,3.15,0.1,4.12,0.1c1.44,0,2.49-0.06,3.79-0.1c0.38,0,0.38,0.25,0.3,0.62l-0.38,1.5
				c-0.05,0.22-0.44,0.22-0.51,0c-0.24-0.78-0.71-1.22-2.69-1.22c-1.11,0-1.47,0.29-1.47,1.11v2.58c0,0.41,0.21,0.49,0.52,0.49h0.35
				c1.28,0,2.39-0.13,3.04-0.71c0.16-0.14,0.51-0.11,0.36,0.21l-0.9,2c-0.13,0.29-0.36,0.25-0.54,0.02
				c-0.36-0.51-1.17-0.71-2.09-0.71h-0.22c-0.32,0-0.52,0.08-0.52,0.49v2.66c0,0.9,0.38,1.24,1.7,1.24c2.39,0,3.34-1.09,3.72-1.69
				c0.11-0.17,0.54-0.1,0.54,0.17c0,0.81-0.68,2.41-1.68,2.41c-0.89-0.05-1.76-0.09-3.09-0.09c-1.46,0-3.18-0.05-4.62,0.09
				C29.87,98.98,29.81,98.43,29.94,98.4z"
						/>
						<path
							fill="#003876"
							d="M45.75,94.69v2.28c0,1.11,0.76,1.3,1.77,1.43c0.11,0.02,0.03,0.54-0.1,0.54h-4.8
				c-0.13,0-0.22-0.52-0.09-0.54c0.81-0.11,1.31-0.43,1.31-1.43v-7.06c0-1.04-0.55-1.35-1.31-1.55c-0.13-0.03-0.05-0.55,0.09-0.54
				c0.62,0.08,1.31,0.1,1.82,0.1c1.17,0,2.15-0.1,2.96-0.1c2.46,0,4.48,0.9,4.48,3.39C51.89,94.02,49.25,95.21,45.75,94.69z
				 M45.75,89.91v4.01c2.69,0.38,4.1-0.27,4.1-2.34c0-1.88-1.2-2.93-2.83-2.93C46.02,88.64,45.75,88.99,45.75,89.91z"
						/>
						<path
							fill="#003876"
							d="M56.37,94.78v-4.97c0-1-0.51-1.33-1.28-1.43c-0.13-0.02-0.03-0.54,0.09-0.54h4.32
				c0.13,0,0.22,0.52,0.09,0.54c-0.63,0.08-1.27,0.32-1.27,1.43v5.05c0,2.22,1.28,3.25,2.96,3.25c1.73,0,3.1-1.03,3.1-3.25v-5.05
				c0-1.03-0.59-1.31-1.36-1.43c-0.11-0.02-0.03-0.54,0.09-0.54h3.56c0.11,0,0.21,0.52,0.08,0.54c-0.63,0.08-1.27,0.32-1.27,1.43
				v4.96c0,2.92-1.88,4.42-4.4,4.42C58.87,99.19,56.37,98,56.37,94.78z M61.02,83.9c0.29-0.41,1.62,0.81,1.24,1.09l-2.08,1.57
				c-0.21,0.16-0.76-0.35-0.6-0.57L61.02,83.9z"
						/>
						<path
							fill="#003876"
							d="M79.47,95.74c0,1.96-1.79,3.23-4.43,3.23c-0.76,0-1.65-0.09-2.82-0.09c-1,0-1.76,0.02-2.49,0.09
				c-0.14,0.02-0.22-0.54-0.09-0.57c0.92-0.24,1.63-0.38,1.63-1.55v-7.03c0-1.01-0.65-1.28-1.33-1.46
				c-0.13-0.03-0.05-0.55,0.08-0.54c0.65,0.08,1.41,0.1,1.92,0.1c1.27,0,2.2-0.1,2.9-0.1c2.04,0,3.72,0.86,3.74,2.6
				c0.02,1.28-0.89,2.17-2,2.39v0.1C78.04,92.9,79.47,93.96,79.47,95.74z M73.16,89.81v2.82h1.63c1.14,0,1.81-0.57,1.81-1.79
				c0-1.5-1.01-2.2-2.31-2.2C73.45,88.64,73.16,88.92,73.16,89.81z M77.42,96.08c0-1.74-1.16-2.66-2.63-2.66h-1.63v3.42
				c0,0.87,0.44,1.3,1.82,1.3C76.38,98.14,77.42,97.73,77.42,96.08z"
						/>
						<path
							fill="#003876"
							d="M82.5,98.4c0.92-0.24,1.63-0.38,1.63-1.55v-7.03c0-1.11-0.65-1.33-1.33-1.43c-0.13-0.02-0.03-0.54,0.08-0.54
				h4.37c0.11,0,0.19,0.52,0.08,0.54c-0.67,0.08-1.3,0.32-1.3,1.43v7.03c0,0.9,0.38,1.24,1.7,1.24c2.39,0,3.34-1.09,3.72-1.69
				c0.11-0.17,0.54-0.1,0.54,0.17c0,0.81-0.68,2.41-1.68,2.41c-0.89-0.05-1.76-0.09-3.09-0.09c-1.46,0-3.18-0.05-4.62,0.09
				C82.43,98.98,82.37,98.43,82.5,98.4z"
						/>
						<path
							fill="#003876"
							d="M94.78,98.4c0.81-0.11,1.31-0.43,1.31-1.43v-7.16c0-1-0.51-1.31-1.31-1.43c-0.13-0.02-0.03-0.54,0.09-0.54
				h4.34c0.13,0,0.22,0.52,0.09,0.54c-0.68,0.1-1.31,0.32-1.31,1.43v7.16c0,1.11,0.63,1.33,1.31,1.43c0.13,0.02,0.03,0.54-0.09,0.54
				h-4.34C94.75,98.93,94.65,98.41,94.78,98.4z"
						/>
						<path
							fill="#003876"
							d="M102.65,93.6c0-2.74,1.68-6,6.11-6c1.84,0,3.42,0.57,3.23,1.12l-0.21,0.62c-0.57,1.7-1.19-0.47-4.01-0.47
				c-2.27,0-3.37,1.47-3.37,3.75c0,3.56,2.71,5.05,4.88,5.05c0.29,0,1.73-0.22,2.55-0.75c0.13-0.08,0.46,0.29,0.36,0.43
				c-0.68,1-2.8,1.84-3.29,1.84C106.25,99.19,102.65,97.64,102.65,93.6z"
						/>
						<path
							fill="#003876"
							d="M126.67,98.93h-4.18c-0.21,0-0.22-0.51-0.05-0.54c0.59-0.11,1.22-0.32,0.63-1.68l-0.17-0.4
				c-0.16-0.38-0.43-0.41-0.9-0.41h-3.23c-0.48,0-0.74,0.03-0.9,0.41l-0.16,0.4c-0.55,1.36,0.05,1.57,0.81,1.68
				c0.21,0.03,0.16,0.54-0.05,0.54h-3.42c-0.19,0-0.24-0.51-0.05-0.54c0.65-0.11,1.03-0.33,1.62-1.68l2.68-6.14
				c0.62-1.39,0.81-2.04,0.81-2.65c0-0.09,1.11-0.35,1.16-0.24l3.87,9.01c0.57,1.35,0.95,1.6,1.58,1.7
				C126.92,98.43,126.86,98.93,126.67,98.93z M122.25,94.77l-1.85-4.43l-1.87,4.43c-0.1,0.22,0.14,0.25,0.36,0.25h2.99
				C122.11,95.02,122.34,94.99,122.25,94.77z"
						/>
						<path
							fill="#003876"
							d="M134.34,98.4c0.92-0.24,1.63-0.38,1.63-1.55v-7.03c0-1.01-0.65-1.28-1.33-1.46
				c-0.13-0.03-0.05-0.55,0.08-0.54c0.65,0.08,1.31,0.1,1.98,0.1c1.24,0,2.34-0.1,3.15-0.1c3.83,0,5.91,2.28,5.91,5.34
				c0,3.04-2.08,5.81-5.92,5.81c-0.76,0-1.74-0.09-2.92-0.09c-0.98,0-1.76,0.02-2.49,0.09C134.28,98.98,134.22,98.43,134.34,98.4z
				 M143.86,94.06c0-3.03-1.65-5.4-4.5-5.4c-1.12,0-1.49,0.35-1.49,1.16v7.03c0,0.86,0.59,1.28,2.12,1.28
				C142.58,98.13,143.86,96.73,143.86,94.06z"
						/>
						<path
							fill="#003876"
							d="M149.21,93.45c0-3.2,2.28-5.86,5.75-5.86c3.31,0,5.72,2.42,5.72,5.72c0,3.22-2.26,5.88-5.75,5.88
				C151.63,99.19,149.21,96.75,149.21,93.45z M158.79,94.2c0-3.2-2.17-5.53-4.45-5.53c-1.39,0-3.23,0.92-3.23,3.9
				c0,3.23,2.17,5.54,4.44,5.54C156.94,98.11,158.79,97.19,158.79,94.2z"
						/>
						<path
							fill="#003876"
							d="M177.91,98.93h-4.13c-0.11,0-0.19-0.52-0.09-0.54c0.67-0.11,1.16-0.43,1.11-1.43l-0.3-5.32l-2.57,5.23
				c-0.33,0.68-0.59,1.38-0.63,1.63c-0.03,0.19-0.36,0.29-0.62,0.29c-0.24,0-0.4-0.09-0.49-0.29l-3.66-6.94l-0.24,5.4
				c-0.05,1.11,0.6,1.36,1.11,1.43c0.13,0.02,0.03,0.54-0.08,0.54h-3.28c-0.13,0-0.22-0.52-0.09-0.54c0.68-0.08,1.25-0.32,1.31-1.43
				l0.51-9.09c0.02-0.27,0.62-0.29,0.78,0l4.59,8.58l4.26-8.58c0.14-0.29,0.78-0.27,0.79,0l0.51,9.09c0.06,1.11,0.63,1.35,1.3,1.43
				C178.12,98.41,178.02,98.93,177.91,98.93z"
						/>
						<path
							fill="#003876"
							d="M181.17,98.4c0.81-0.11,1.31-0.43,1.31-1.43v-7.16c0-1-0.51-1.31-1.31-1.43c-0.13-0.02-0.03-0.54,0.09-0.54
				h4.34c0.13,0,0.22,0.52,0.09,0.54c-0.68,0.1-1.31,0.32-1.31,1.43v7.16c0,1.11,0.63,1.33,1.31,1.43c0.13,0.02,0.03,0.54-0.09,0.54
				h-4.34C181.14,98.93,181.04,98.41,181.17,98.4z"
						/>
						<path
							fill="#003876"
							d="M198.72,98.79l-7.54-7.89v6.02c0,1.08,0.63,1.25,1.54,1.47c0.13,0.03,0.06,0.54-0.1,0.54h-3.75
				c-0.13,0-0.22-0.49-0.08-0.54c0.67-0.22,1.3-0.35,1.3-1.47v-6.15c0-1.12-0.17-1.66-0.59-2.22c-0.14-0.19-0.29-0.32-0.29-0.36
				c0-0.14,0.1-0.35,0.32-0.35h1.17c0.16,0,0.27,0.02,0.38,0.14l7.38,7.81v-5.96c0-1.12-0.71-1.25-1.54-1.46
				c-0.13-0.03-0.06-0.54,0.09-0.54h3.75c0.13,0,0.21,0.51,0.08,0.54c-0.7,0.21-1.3,0.33-1.3,1.46V96c0,1.04,0.03,2.2,0.19,2.79
				c0.06,0.24-0.19,0.3-0.44,0.3C199.04,99.09,198.89,98.98,198.72,98.79z"
						/>
						<path
							fill="#003876"
							d="M204.03,98.4c0.81-0.11,1.31-0.43,1.31-1.43v-7.16c0-1-0.51-1.31-1.31-1.43c-0.13-0.02-0.03-0.54,0.09-0.54
				h4.34c0.13,0,0.22,0.52,0.09,0.54c-0.68,0.1-1.31,0.32-1.31,1.43v7.16c0,1.11,0.63,1.33,1.31,1.43c0.13,0.02,0.03,0.54-0.09,0.54
				h-4.34C204,98.93,203.91,98.41,204.03,98.4z"
						/>
						<path
							fill="#003876"
							d="M211.91,93.6c0-2.74,1.68-6,6.11-6c1.84,0,3.42,0.57,3.23,1.12l-0.21,0.62c-0.57,1.7-1.19-0.47-4.01-0.47
				c-2.26,0-3.37,1.47-3.37,3.75c0,3.56,2.71,5.05,4.88,5.05c0.29,0,1.73-0.22,2.55-0.75c0.13-0.08,0.46,0.29,0.36,0.43
				c-0.68,1-2.8,1.84-3.29,1.84C215.5,99.19,211.91,97.64,211.91,93.6z"
						/>
						<path
							fill="#003876"
							d="M235.92,98.93h-4.18c-0.21,0-0.22-0.51-0.05-0.54c0.59-0.11,1.22-0.32,0.63-1.68l-0.17-0.4
				c-0.16-0.38-0.43-0.41-0.9-0.41h-3.23c-0.47,0-0.74,0.03-0.9,0.41l-0.16,0.4c-0.55,1.36,0.05,1.57,0.81,1.68
				c0.21,0.03,0.16,0.54-0.05,0.54h-3.42c-0.19,0-0.24-0.51-0.05-0.54c0.65-0.11,1.03-0.33,1.62-1.68l2.68-6.14
				c0.62-1.39,0.81-2.04,0.81-2.65c0-0.09,1.11-0.35,1.16-0.24l3.86,9.01c0.57,1.35,0.95,1.6,1.58,1.7
				C236.18,98.43,236.11,98.93,235.92,98.93z M231.5,94.77l-1.85-4.43l-1.87,4.43c-0.09,0.22,0.14,0.25,0.36,0.25h2.99
				C231.36,95.02,231.6,94.99,231.5,94.77z"
						/>
						<path
							fill="#003876"
							d="M248.82,98.79l-7.54-7.89v6.02c0,1.08,0.63,1.25,1.54,1.47c0.13,0.03,0.06,0.54-0.1,0.54h-3.75
				c-0.13,0-0.22-0.49-0.08-0.54c0.67-0.22,1.3-0.35,1.3-1.47v-6.15c0-1.12-0.17-1.66-0.59-2.22c-0.14-0.19-0.29-0.32-0.29-0.36
				c0-0.14,0.1-0.35,0.32-0.35h1.17c0.16,0,0.27,0.02,0.38,0.14l7.38,7.81v-5.96c0-1.12-0.71-1.25-1.54-1.46
				c-0.13-0.03-0.06-0.54,0.09-0.54h3.75c0.13,0,0.21,0.51,0.08,0.54c-0.7,0.21-1.3,0.33-1.3,1.46V96c0,1.04,0.03,2.2,0.19,2.79
				c0.06,0.24-0.19,0.3-0.44,0.3C249.14,99.09,249,98.98,248.82,98.79z"
						/>
						<path
							fill="#003876"
							d="M265.17,98.93h-4.18c-0.21,0-0.22-0.51-0.05-0.54c0.59-0.11,1.22-0.32,0.63-1.68l-0.17-0.4
				c-0.16-0.38-0.43-0.41-0.9-0.41h-3.23c-0.47,0-0.74,0.03-0.9,0.41l-0.16,0.4c-0.55,1.36,0.05,1.57,0.81,1.68
				c0.21,0.03,0.16,0.54-0.05,0.54h-3.42c-0.19,0-0.24-0.51-0.05-0.54c0.65-0.11,1.03-0.33,1.62-1.68l2.68-6.14
				c0.62-1.39,0.81-2.04,0.81-2.65c0-0.09,1.11-0.35,1.16-0.24l3.86,9.01c0.57,1.35,0.95,1.6,1.58,1.7
				C265.43,98.43,265.37,98.93,265.17,98.93z M260.76,94.77l-1.85-4.43l-1.87,4.43c-0.09,0.22,0.14,0.25,0.36,0.25h2.99
				C260.61,95.02,260.85,94.99,260.76,94.77z"
						/>
					</g>
					<rect x="17.11" y="108.37" fill="#ED232A" width="57.26" height="2.71" />
				</g>
			</g>
		</svg>
	);
};

export default MinerdHorizontalLogo;

import "./answer.scss";
import React, { useMemo } from "react";
import BotIcon from "../../../../assets/icons/BotIcon";
import CopyIcon from "../../../../assets/icons/CopyIcon";
import { ConversationResponse, DataPoint } from "../../../../models/ChatBot/Chat.model";

interface AnswerProps {
	answer: ConversationResponse;
	onReferenceClicked: (reference: DataPoint, id: number) => void;
	selectQuestion: (x: string) => void;
}

const Answer: React.FC<AnswerProps> = ({
	answer: baseAnswer,
	onReferenceClicked,
	selectQuestion,
}) => {
	const answer = useMemo(() => baseAnswer, [baseAnswer]);

	const copyText = () => {
		navigator.clipboard.writeText(answer.answer);
	};

	return (
		<div className="answer-box">
			<div className="answer-header">
				<div className="bot-logo">
					<BotIcon className="medium-less-icon" />
				</div>
				<div className="copy-button" onClick={copyText}>
					<CopyIcon fill="#003876" className="small-icon" />
				</div>
			</div>
			<div className="answer-body">{answer.answer}</div>
			<div className="answer-actions">
				{answer.followUpQuestion && answer.followUpQuestion.length > 0 && (
					<div className="answer-questions">
						<span className="questions-title">
							<b>Preguntas sugeridas:</b>
						</span>
						{answer.followUpQuestion?.map((question, i) => (
							<span
								className="question"
								key={i}
								onClick={() => selectQuestion(question)}>
								{question}
							</span>
						))}
					</div>
				)}
				{answer.dataPoints && answer.dataPoints.length > 0 && (
					<div className="answer-references">
						<span className="references-title">
							<b>Referencias:</b>
						</span>
						{answer.dataPoints?.map((dataPoint, i) => (
							<span
								className="reference"
								key={i}
								onClick={() => onReferenceClicked(dataPoint, i)}>
								{i + 1}.{dataPoint.title}
							</span>
						))}
					</div>
				)}
			</div>
		</div>
	);
};

export default Answer;

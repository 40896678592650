import IconProps from "./IconProps.interface";

const BotIcon: React.FC<IconProps> = (props) => {
	return (
		<svg
			className={props.className}
			onClick={props.onClick}
			viewBox="0 0 30 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M25.7812 17.2992H24.375V15.893C24.375 14.6006 23.3236 13.5492 22.0312 13.5492H19.6875V12.143C19.6875 11.8842 19.4775 11.6742 19.2188 11.6742H15.4688V10.6702C16.275 10.4606 16.875 9.73266 16.875 8.86173C16.875 7.82766 16.0341 6.98672 15 6.98672C13.9659 6.98672 13.125 7.82766 13.125 8.86173C13.125 9.73266 13.725 10.4606 14.5312 10.6702V11.6742H10.7812C10.5225 11.6742 10.3125 11.8842 10.3125 12.143V13.5492H7.96875C6.67641 13.5492 5.625 14.6006 5.625 15.893V17.2992H4.21875C3.44344 17.2992 2.8125 17.9302 2.8125 18.7055V24.3305C2.8125 25.1058 3.44344 25.7367 4.21875 25.7367H5.625V27.143C5.625 28.4353 6.67641 29.4867 7.96875 29.4867H22.0312C23.3236 29.4867 24.375 28.4353 24.375 27.143V25.7367H25.7812C26.5566 25.7367 27.1875 25.1058 27.1875 24.3305V18.7055C27.1875 17.9302 26.5566 17.2992 25.7812 17.2992ZM14.0625 8.86173C14.0625 8.34469 14.483 7.92423 15 7.92423C15.517 7.92423 15.9375 8.34469 15.9375 8.86173C15.9375 9.37876 15.517 9.79923 15 9.79923C14.483 9.79923 14.0625 9.37876 14.0625 8.86173ZM4.21875 24.7992C3.96047 24.7992 3.75 24.5888 3.75 24.3305V18.7055C3.75 18.4472 3.96047 18.2367 4.21875 18.2367H5.625V24.7992H4.21875ZM11.25 12.6117H18.75V13.5492H11.25V12.6117ZM23.4375 27.143C23.4375 27.9183 22.8066 28.5492 22.0312 28.5492H7.96875C7.19344 28.5492 6.5625 27.9183 6.5625 27.143V15.893C6.5625 15.1177 7.19344 14.4867 7.96875 14.4867H22.0312C22.8066 14.4867 23.4375 15.1177 23.4375 15.893V27.143ZM26.25 24.3305C26.25 24.5888 26.0395 24.7992 25.7812 24.7992H24.375V18.2367H25.7812C26.0395 18.2367 26.25 18.4472 26.25 18.7055V24.3305Z"
				fill={props.fill || "#003876"}
			/>
			<path
				d="M19.2188 21.9867H10.7812C10.5225 21.9867 10.3125 22.1967 10.3125 22.4555V22.9242C10.3125 25.5089 12.4153 27.6117 15 27.6117C17.5847 27.6117 19.6875 25.5089 19.6875 22.9242V22.4555C19.6875 22.1967 19.4775 21.9867 19.2188 21.9867ZM15 26.6742C12.9323 26.6742 11.25 24.9919 11.25 22.9242H18.75C18.75 24.9919 17.0677 26.6742 15 26.6742Z"
				fill={props.fill || "#003876"}
			/>
			<path
				d="M12.1875 19.1742C12.1875 18.1402 11.3466 17.2992 10.3125 17.2992C9.27844 17.2992 8.4375 18.1402 8.4375 19.1742C8.4375 20.2083 9.27844 21.0492 10.3125 21.0492C11.3466 21.0492 12.1875 20.2083 12.1875 19.1742ZM9.375 19.1742C9.375 18.6572 9.79547 18.2367 10.3125 18.2367C10.8295 18.2367 11.25 18.6572 11.25 19.1742C11.25 19.6913 10.8295 20.1117 10.3125 20.1117C9.79547 20.1117 9.375 19.6913 9.375 19.1742Z"
				fill={props.fill || "#003876"}
			/>
			<path
				d="M19.6875 17.2992C18.6534 17.2992 17.8125 18.1402 17.8125 19.1742C17.8125 20.2083 18.6534 21.0492 19.6875 21.0492C20.7216 21.0492 21.5625 20.2083 21.5625 19.1742C21.5625 18.1402 20.7216 17.2992 19.6875 17.2992ZM19.6875 20.1117C19.1705 20.1117 18.75 19.6913 18.75 19.1742C18.75 18.6572 19.1705 18.2367 19.6875 18.2367C20.2045 18.2367 20.625 18.6572 20.625 19.1742C20.625 19.6913 20.2045 20.1117 19.6875 20.1117Z"
				fill={props.fill || "#003876"}
			/>
			<path
				d="M7.63734 10.5994C7.72687 10.6889 7.84688 10.7367 7.96875 10.7367C8.02922 10.7367 8.09016 10.725 8.14828 10.7011C8.32312 10.6284 8.4375 10.4578 8.4375 10.268V8.86172H11.25C12.0253 8.86172 12.6562 8.23079 12.6562 7.45547V1.83047C12.6562 1.05516 12.0253 0.424225 11.25 0.424225H1.875C1.09969 0.424225 0.46875 1.05516 0.46875 1.83047V7.45547C0.46875 8.23079 1.09969 8.86172 1.875 8.86172H5.89969L7.63734 10.5994ZM1.40625 7.45547V1.83047C1.40625 1.57219 1.61672 1.36172 1.875 1.36172H11.25C11.5083 1.36172 11.7188 1.57219 11.7188 1.83047V7.45547C11.7188 7.71376 11.5083 7.92422 11.25 7.92422H7.96875C7.71 7.92422 7.5 8.13422 7.5 8.39297V9.13641L6.42516 8.06157C6.33703 7.97344 6.21797 7.92422 6.09375 7.92422H1.875C1.61672 7.92422 1.40625 7.71422 1.40625 7.45547Z"
				fill={props.fill || "#003876"}
			/>
			<path
				d="M28.125 2.29922H18.75C17.9747 2.29922 17.3438 2.93016 17.3438 3.70547V9.33047C17.3438 10.1058 17.9747 10.7367 18.75 10.7367H21.5625V12.143C21.5625 12.3323 21.6769 12.5034 21.8517 12.5761C21.9098 12.6 21.9708 12.6117 22.0312 12.6117C22.1531 12.6117 22.2731 12.5639 22.3627 12.4744L24.1003 10.7367H28.125C28.9003 10.7367 29.5312 10.1058 29.5312 9.33047V3.70547C29.5312 2.93016 28.9003 2.29922 28.125 2.29922ZM28.5938 9.33047C28.5938 9.58876 28.3833 9.79922 28.125 9.79922H23.9062C23.782 9.79922 23.6625 9.84844 23.5748 9.93657L22.5 11.0114V10.268C22.5 10.0092 22.29 9.79922 22.0312 9.79922H18.75C18.4917 9.79922 18.2812 9.58876 18.2812 9.33047V3.70547C18.2812 3.44719 18.4917 3.23672 18.75 3.23672H28.125C28.3833 3.23672 28.5938 3.44719 28.5938 3.70547V9.33047Z"
				fill={props.fill || "#003876"}
			/>
			<path d="M2.34375 2.29922H10.7812V3.23672H2.34375V2.29922Z" fill={props.fill || "#003876"} />
			<path d="M2.34375 6.04922H10.7812V6.98672H2.34375V6.04922Z" fill={props.fill || "#003876"} />
			<path d="M3.28125 4.17422H9.84375V5.11172H3.28125V4.17422Z" fill={props.fill || "#003876"} />
			<path d="M19.2188 4.17422H27.6562V5.11172H19.2188V4.17422Z" fill={props.fill || "#003876"} />
			<path d="M19.2188 7.92422H27.6562V8.86172H19.2188V7.92422Z" fill={props.fill || "#003876"} />
			<path d="M20.1562 6.04922H26.7188V6.98672H20.1562V6.04922Z" fill={props.fill || "#003876"} />
		</svg>
	);
};

export default BotIcon;

import React from "react";
import IconProps from "./IconProps.interface";

const SendIcon: React.FC<IconProps> = (props) => {
	return (
		<svg
			className={props.className}
			onClick={props.onClick}
			viewBox="0 0 30 26"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M28.2134 10.3243L4.00866 0.280814C2.98895 -0.14235 1.837 0.0438029 1.00227 0.766381C0.167544 1.48908 -0.181557 2.60253 0.0912553 3.67234L2.46981 13L0.0912553 22.3277C-0.181557 23.3975 0.167486 24.5109 1.00221 25.2336C1.83852 25.9577 2.99077 26.1415 4.00866 25.7192L28.2134 15.6757C29.3154 15.2184 30 14.1931 30 13C30 11.8068 29.3154 10.7816 28.2134 10.3243ZM27.5396 14.052L3.33495 24.0955C2.9279 24.2644 2.48604 24.1931 2.15282 23.9046C1.81965 23.6161 1.68577 23.189 1.79463 22.7619L4.05981 13.8789H12.7941C13.2795 13.8789 13.6731 13.4854 13.6731 12.9999C13.6731 12.5145 13.2795 12.121 12.7941 12.121H4.05987L1.79463 3.23804C1.68577 2.81101 1.81965 2.38386 2.15282 2.0954C2.48598 1.80695 2.92772 1.73558 3.33489 1.90445L27.5396 11.9479C28.1915 12.2184 28.2422 12.8216 28.2422 13C28.2422 13.1784 28.1915 13.7816 27.5396 14.052Z"
				fill={props.fill || "black"}
			/>
		</svg>
	);
};

export default SendIcon;

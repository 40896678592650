import React, { useState } from "react";
import FilterIcon from "../../../assets/icons/FilterIcon";
import DropIcon from "../../../assets/icons/DropIcon";
import { SelectorOption } from "../../../models";
import clsx from "clsx";

interface FilterSelectorProps {
	options: SelectorOption[];
	handleSelect?: (selectedOption: SelectorOption) => void;
	initialSelected?: SelectorOption;
}

const FilterSelector: React.FC<FilterSelectorProps> = ({ options, handleSelect, initialSelected }) => {
	const [selectedOption, setSelectedOption] = useState<SelectorOption | undefined>(initialSelected);
	const [show, setShow] = useState<boolean>(false);

	const toggleShow = () => setShow(!show);

	const onSelect = (option: SelectorOption) => {
		toggleShow();
		setSelectedOption(option);
		if (handleSelect) handleSelect(option);
	};

	return (
		<>
			<div className="filter-selector-container">
				<div className="selector-header" onClick={toggleShow}>
					<div className="filter-icon">
						<FilterIcon className="small-icon" />
						<span>Filtros: </span>
					</div>
					<span className="selected-option">{selectedOption?.label}</span>
					<div className="drop-icon">
						<DropIcon className="small-less-icon" />
					</div>
				</div>
				<div className={clsx({ "selector-body": true, show: show })}>
					{options.map((option, i) => (
						<div className="selector-option" key={i} onClick={() => onSelect(option)}>
							{option.label}
						</div>
					))}
				</div>
			</div>
			{show && <div className="overlay-selector" onClick={toggleShow}></div>}
		</>
	);
};

export default FilterSelector;

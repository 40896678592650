import api from "../api";
import { loadAbort } from "../helpers/loadAbort.utility";

export const getBookById = (bookId: string) => {
	const controller = loadAbort();
	return { call: api.get(`api/Book/${bookId}`, { signal: controller.signal }), controller };
};

export const getBookPDFById = (bookId: string) => {
	const controller = loadAbort();
	return { call: api.get(`api/Book/PDF/${bookId}`, { signal: controller.signal }), controller };
};

export const getByGradeAndSubject = (gradeId: string, subjectId: string) => {
	const controller = loadAbort();
	return {
		call: api.get(`api/Book/Grade/Subject`, {
			params: { gradeId: gradeId, subjectId: subjectId },
			signal: controller.signal,
		}),
		controller,
	};
};

export const getRelatedBooksByGrade = (gradeId: string, bookId: string) => {
	const controller = loadAbort();
	return {
		call: api.get(`api/Book/Related/Grade/${gradeId}`, {
			params: { bookId: bookId },
			signal: controller.signal,
		}),
		controller,
	};
};

export const getBookByName = (gradeId: string, subjectId: string, bookName: string) => {
	const controller = loadAbort();
	return {
		call: api.get("api/Book/Name", {
			params: {
				gradeId: gradeId,
				subjectId: subjectId,
				bookName: bookName,
			},
			signal: controller.signal,
		}),
		controller,
	};
};

export const searchBooksByName = (bookName: string) => {
	const controller = loadAbort();
	return {
		call: api.get(`api/Book/Search/${bookName}`, { signal: controller.signal }),
		controller,
	};
};

import "./mini-chat.scss";
import React, { useRef, useState } from "react";
import RobotIcon from "../../assets/icons/RobotIcon";
import BotIcon from "../../assets/icons/BotIcon";
import { Answer, AnswerOption } from "./components/Answer";
import LoadingAnswer from "../ChatBot/components/Answer/LoadingAnswer";
import TrashIcon from "../../assets/icons/TrashIcon";
import { useFetchAndLoad } from "../../hooks/useFetchAndLoad.hook";
import { useNavigate } from "react-router-dom";
import AnswersList from "./answers-data.json";
import { getLevelName } from "../../helpers/LevelData.utility";
import { getByGradeAndSubject, getGradesByLevel, getSubjectByGradeId } from "../../services";

const levelOptions = [
	{ id: "1", label: "Inicial" },
	{ id: "2", label: "Primaria" },
	{ id: "3", label: "Secundaria" },
];

export type SearchDataProps = {
	level: number;
	grade?: AnswerOption;
	subject?: AnswerOption;
};

export type MiniChatAnswer = {
	answer: string;
	options?: AnswerOption[];
	selectedOption?: AnswerOption;
};

const MiniChat = () => {
	const navigate = useNavigate();
	const { loading, callEndpoint } = useFetchAndLoad();
	const [show, setShow] = useState<boolean>(false);
	const [searchData, setSearchData] = useState<SearchDataProps>({ level: 0 });
	const [answers, setAnswers] = useState<MiniChatAnswer[]>([
		{ answer: AnswersList[0], options: levelOptions },
	]);

	const scrollDownRef = useRef<HTMLDivElement>(null);

	const addNewAnswer = async (
		step: number,
		prevAnswersList: MiniChatAnswer[],
		newSearchData: SearchDataProps
	) => {
		const options = await callOptions(step, newSearchData);

		if (options.length > 0)
			setAnswers([...prevAnswersList, { answer: AnswersList[step + 1], options: options }]);
		else setAnswers([...prevAnswersList, { answer: AnswersList[5], options: options }]);

		setTimeout(() => scrollDown(), 10);
	};

	const handleSelectOption = async (option: AnswerOption, step: number) => {
		const prevAnswer = answers[step];
		prevAnswer.selectedOption = option;
		const actualAnswers = [...answers.slice(0, step), prevAnswer];

		switch (step) {
			case 0: {
				await addNewAnswer(step, actualAnswers, {
					...searchData,
					level: parseInt(option.id),
				});
				break;
			}
			case 1: {
				await addNewAnswer(step, actualAnswers, { ...searchData, grade: option });
				break;
			}
			case 2: {
				await addNewAnswer(step, actualAnswers, { ...searchData, subject: option });
				break;
			}
			case 3:
				const bookUrl = `/${getLevelName(searchData.level)}/${searchData.grade!.label}/${
					searchData.subject!.label
				}/${option.id}/lector`;
				const miniChatAnswers = [...answers, { answer: AnswersList[4] }];
				navigate(bookUrl, { state: { minichat: miniChatAnswers } });
				break;
			default:
				break;
		}
	};

	const callOptions = async (step: number, newSearchData: SearchDataProps) => {
		const callOptions = [
			async () => await callEndpoint(getGradesByLevel(newSearchData.level)),
			async () => await callEndpoint(getSubjectByGradeId(newSearchData.grade!.id)),
			async () =>
				await callEndpoint(
					getByGradeAndSubject(newSearchData.grade!.id, newSearchData.subject!.id)
				),
		];

		const backData = (await callOptions[step]()).data;
		setSearchData(newSearchData);
		return backData.map((x: any) => ({ id: x.id, label: x.name || x.title }));
	};

	const clearAnswers = () => {
		setAnswers([{ answer: AnswersList[0], options: levelOptions }]);
	};

	const scrollDown = () => {
		if (scrollDownRef.current) {
			scrollDownRef.current.scrollIntoView({ behavior: "smooth" });
		}
	};

	const toggleShow = () => setShow(!show);

	return (
		<div className="mini-chat-space">
			{show ? (
				<div className="mini-chat-container">
					<div className="mini-chat-header">
						<div className="title">
							<BotIcon className="medium-icon" />
							<div className="text">Asistente Digital</div>
						</div>
						<div className="mini-chat-close" onClick={toggleShow}>
							-
						</div>
					</div>
					<div className="mini-chat scroll">
						{answers.map((answer, i) => (
							<Answer
								key={i}
								{...answer}
								selectOption={(option) => handleSelectOption(option, i)}
							/>
						))}
						{loading && (
							<div className="loading-answer-container">
								<LoadingAnswer />
							</div>
						)}
						<div id="scroll-down" ref={scrollDownRef}></div>
					</div>
					<div className="mini-chat-actions">
						{answers.length > 1 && (
							<div className="clear-action" onClick={clearAnswers}>
								<TrashIcon className="medium-less-icon" />
							</div>
						)}
					</div>
				</div>
			) : (
				<div className="mini-chat-button" onClick={toggleShow}>
					<div className="icon">
						<RobotIcon className="medium-plus-icon" />
					</div>
				</div>
			)}
		</div>
	);
};

export default MiniChat;

import "./loading-answer.scss";

const LoadingAnswer = () => {
	return (
		<div className="loading-answer">
			<div className="dot-pulse"></div>
		</div>
	);
};

export default LoadingAnswer;

import React from "react";

interface SignedInModalProps {
	goLogin: () => void;
}

const SignedInModal: React.FC<SignedInModalProps> = ({ goLogin }) => {
	return (
		<div className="signed-in-modal">
			<div className="content">
				<h2>Su cuenta ha sido creada con éxito</h2>
				<div>
					Por favor revise su correo para confirmar la cuenta antes de utilizar los servicios a traves de
					Libro Abierto. Este correo puede tomar unos minutos.
				</div>
			</div>
			<div className="action">
				<button className="back-button" onClick={goLogin}>
					Iniciar Sesión
				</button>
			</div>
		</div>
	);
};

export default SignedInModal;

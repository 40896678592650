import { toast } from "react-hot-toast";

export const useNotification = () => {
	const notifySuccess = (text: string) => {
		toast.success(text);
	};

	const notifyError = (text: string) => {
		toast.error(text);
	};

	return { notifySuccess, notifyError };
};

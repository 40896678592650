import React from "react";
import IconProps from "./IconProps.interface";

const AudioIcon: React.FC<IconProps> = (props) => {
	return (
		<svg
			className={props.className}
			onClick={props.onClick}
			viewBox="0 0 38 38"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M23.4531 11.4441C23.7605 11.4441 24.0098 11.1949 24.0098 10.8875V7.34514C24.0098 7.03771 23.7605 6.7885 23.4531 6.7885C23.1457 6.7885 22.8965 7.03771 22.8965 7.34514V10.8875C22.8965 11.1949 23.1457 11.4441 23.4531 11.4441Z"
				fill={props.fill || "#003876"}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M23.4531 31.2126C23.7605 31.2126 24.0098 30.9634 24.0098 30.6559V13.114C24.0098 12.8065 23.7605 12.5573 23.4531 12.5573C23.1457 12.5573 22.8965 12.8065 22.8965 13.114V30.6559C22.8965 30.9634 23.1457 31.2126 23.4531 31.2126Z"
				fill={props.fill || "#003876"}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M18.9998 23.8097C19.3072 23.8097 19.5564 23.5605 19.5564 23.2531V14.7464C19.5564 14.439 19.3072 14.1898 18.9998 14.1898C18.6923 14.1898 18.4431 14.439 18.4431 14.7464V23.2531C18.4431 23.5605 18.6923 23.8097 18.9998 23.8097Z"
				fill={props.fill || "#003876"}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M25.6797 25.3095C25.9871 25.3095 26.2363 25.0603 26.2363 24.7529V13.2485C26.2363 12.9411 25.9871 12.6919 25.6797 12.6919C25.3723 12.6919 25.123 12.9411 25.123 13.2485V24.7529C25.123 25.0603 25.3723 25.3095 25.6797 25.3095Z"
				fill={props.fill || "#003876"}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M30.1328 24.3546C30.4402 24.3546 30.6895 24.1053 30.6895 23.7979V22.7065C30.6895 22.3991 30.4402 22.1499 30.1328 22.1499C29.8254 22.1499 29.5762 22.3991 29.5762 22.7065V23.7979C29.5762 24.1053 29.8254 24.3546 30.1328 24.3546Z"
				fill={props.fill || "#003876"}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M30.1328 21.037C30.4402 21.037 30.6895 20.7878 30.6895 20.4804V14.2018C30.6895 13.8944 30.4402 13.6452 30.1328 13.6452C29.8254 13.6452 29.5762 13.8944 29.5762 14.2018V20.4804C29.5762 20.7878 29.8254 21.037 30.1328 21.037Z"
				fill={props.fill || "#003876"}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M32.3594 22.3311C32.6668 22.3311 32.916 22.0818 32.916 21.7744V16.2259C32.916 15.9185 32.6668 15.6693 32.3594 15.6693C32.052 15.6693 31.8027 15.9185 31.8027 16.2259V21.7744C31.8027 22.0818 32.052 22.3311 32.3594 22.3311Z"
				fill={props.fill || "#003876"}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M34.5859 22.3311C34.8934 22.3311 35.1426 22.0818 35.1426 21.7744V16.2259C35.1426 15.9185 34.8934 15.6693 34.5859 15.6693C34.2785 15.6693 34.0293 15.9185 34.0293 16.2259V21.7744C34.0293 22.0818 34.2785 22.3311 34.5859 22.3311Z"
				fill={props.fill || "#003876"}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.86694 28.8741C8.17437 28.8741 8.42358 28.6249 8.42358 28.3174V23.5665C8.42358 23.2591 8.17437 23.0098 7.86694 23.0098C7.55952 23.0098 7.3103 23.2591 7.3103 23.5665V28.3174C7.3103 28.6249 7.55952 28.8741 7.86694 28.8741Z"
				fill={props.fill || "#003876"}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.86694 21.8967C8.17437 21.8967 8.42358 21.6475 8.42358 21.34V9.68189C8.42358 9.37447 8.17437 9.12525 7.86694 9.12525C7.55952 9.12525 7.3103 9.37447 7.3103 9.68189V21.34C7.3103 21.6475 7.55952 21.8967 7.86694 21.8967Z"
				fill={props.fill || "#003876"}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M21.2266 29.5073C21.534 29.5073 21.7832 29.258 21.7832 28.9506V9.04901C21.7832 8.74159 21.534 8.49237 21.2266 8.49237C20.9191 8.49237 20.6699 8.74159 20.6699 9.04901V28.9506C20.6699 29.258 20.9191 29.5073 21.2266 29.5073Z"
				fill={props.fill || "#003876"}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M16.7734 27.3655C17.0809 27.3655 17.3301 27.1163 17.3301 26.8088V11.1916C17.3301 10.8842 17.0809 10.6349 16.7734 10.6349C16.466 10.6349 16.2168 10.8842 16.2168 11.1916V26.8088C16.2168 27.1163 16.466 27.3655 16.7734 27.3655Z"
				fill={props.fill || "#003876"}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.3203 26.7108C12.6277 26.7108 12.877 26.4616 12.877 26.1542V11.8462C12.877 11.5388 12.6277 11.2896 12.3203 11.2896C12.0129 11.2896 11.7637 11.5388 11.7637 11.8462V26.1542C11.7637 26.4616 12.0129 26.7108 12.3203 26.7108Z"
				fill={props.fill || "#003876"}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14.5466 23.4175C14.8541 23.4175 15.1033 23.1683 15.1033 22.8609V15.1398C15.1033 14.8324 14.8541 14.5831 14.5466 14.5831C14.2392 14.5831 13.99 14.8324 13.99 15.1398V22.8609C13.99 23.1683 14.2392 23.4175 14.5466 23.4175Z"
				fill={props.fill || "#003876"}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5.64062 23.198C5.94805 23.198 6.19727 22.9488 6.19727 22.6414V15.3591C6.19727 15.0517 5.94805 14.8024 5.64062 14.8024C5.3332 14.8024 5.08398 15.0517 5.08398 15.3591V22.6414C5.08398 22.9488 5.3332 23.198 5.64062 23.198Z"
				fill={props.fill || "#003876"}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M3.41382 22.3725C3.72124 22.3725 3.97046 22.1233 3.97046 21.8159V16.1838C3.97046 15.8763 3.72124 15.6271 3.41382 15.6271C3.10639 15.6271 2.85718 15.8763 2.85718 16.1838V21.8159C2.85718 22.1233 3.10639 22.3725 3.41382 22.3725Z"
				fill={props.fill || "#003876"}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M27.9062 23.5008C28.2137 23.5008 28.4629 23.2516 28.4629 22.9442V15.056C28.4629 14.7486 28.2137 14.4994 27.9062 14.4994C27.5988 14.4994 27.3496 14.7486 27.3496 15.056V22.9442C27.3496 23.2516 27.5988 23.5008 27.9062 23.5008Z"
				fill={props.fill || "#003876"}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10.0935 31.3672C10.4009 31.3672 10.6501 31.118 10.6501 30.8106V7.18878C10.6501 6.88136 10.4009 6.63214 10.0935 6.63214C9.78608 6.63214 9.53687 6.88136 9.53687 7.18878V30.8106C9.53687 31.118 9.78608 31.3672 10.0935 31.3672Z"
				fill={props.fill || "#003876"}
			/>
			<mask id="mask0_90_4865" maskUnits="userSpaceOnUse" x="0" y="0">
				<path d="M0 6.10352e-05H38V38.0001H0V6.10352e-05Z" fill="white" />
			</mask>
			<g mask="url(#mask0_90_4865)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M16.4104 18.9999C16.4104 19.3073 16.6596 19.5565 16.967 19.5565H37.4429C37.7503 19.5565 37.9995 19.3073 37.9995 18.9999C37.9995 18.6925 37.7503 18.4432 37.4429 18.4432H16.967C16.6596 18.4432 16.4104 18.6925 16.4104 18.9999Z"
					fill={props.fill || "#003876"}
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M0 19C0 19.3074 0.249216 19.5566 0.556641 19.5566H14.2956C14.6031 19.5566 14.8523 19.3074 14.8523 19C14.8523 18.6926 14.6031 18.4434 14.2956 18.4434H0.556641C0.249216 18.4434 0 18.6926 0 19Z"
					fill={props.fill || "#003876"}
				/>
			</g>
		</svg>
	);
};

export default AudioIcon;

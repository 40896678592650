import React from "react";
import IconProps from "./IconProps.interface";

const MailIcon: React.FC<IconProps> = (props) => {
	return (
		<svg
			className={props.className}
			onClick={props.onClick}
			viewBox="0 0 82 82"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M41 0C18.3925 0 0 18.3925 0 41C0 63.6075 18.3925 82 41 82C63.6075 82 82 63.6075 82 41C82 18.3925 63.6075 0 41 0ZM41 78.1562C20.512 78.1562 3.84375 61.488 3.84375 41C3.84375 20.512 20.512 3.84375 41 3.84375C61.488 3.84375 78.1562 20.512 78.1562 41C78.1562 61.488 61.488 78.1562 41 78.1562ZM60.2687 24.1035H21.7311C19.1536 24.1035 17.0565 26.2006 17.0565 28.7782V53.222C17.0565 55.7996 19.1534 57.8966 21.7311 57.8966H60.2687C62.8463 57.8966 64.9434 55.7996 64.9434 53.222V28.7782C64.9434 26.2006 62.8463 24.1035 60.2687 24.1035ZM57.3717 27.9473L41 40.9483L24.6282 27.9473H57.3717ZM60.2687 54.0527H21.7311C21.2731 54.0527 20.9002 53.6801 20.9002 53.2218V29.8951L39.8048 44.9073C40.1547 45.1852 40.5773 45.3242 40.9998 45.3242C41.4223 45.3242 41.8451 45.1854 42.1949 44.9073L61.0996 29.8951V53.2218C61.0996 53.6801 60.7268 54.0527 60.2687 54.0527Z"
				fill={props.fill || "#003876"}
			/>
		</svg>
	);
};

export default MailIcon;

import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import LoadingIcons from "react-loading-icons";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { Dimensions, useIsInViewport } from "../../../../../hooks";
import { PageLayout, SpecialZoomLevel, Viewer, Worker } from "@react-pdf-viewer/core";
export interface PageDocumentHandle {
	scrollIntoView: (pageNumber: number) => void;
}

export interface PageDocumentProps {
	pageIndex: number;
	fileURL: string;
	pageSize: Dimensions;
	setPageSize: React.Dispatch<React.SetStateAction<Dimensions>>;
	currentZoom: number;
}

export const PageDocument = forwardRef<PageDocumentHandle, PageDocumentProps>(
	({ pageIndex, fileURL, pageSize, setPageSize, currentZoom }: PageDocumentProps, ref) => {
		const pageDocumentRef = useRef<HTMLDivElement>(null);
		const [allowLoad, setAllowLoad] = useState<boolean>(false);
		const isInViewPort = useIsInViewport(pageDocumentRef);

		const scrollIntoView = (pageNumber: number) => {
			const element = document.getElementById(`page-document-${pageNumber}`);
			if (element) {
				element.scrollIntoView({ block: "start" });
				window.scrollBy(0, -80);
			}
		};

		const pageLayout: PageLayout = {
			transformSize: ({ size }) => {
				if (pageIndex == 0) {
					setPageSize(size);
				}
				return size;
			},
		};

		useEffect(() => {
			if (isInViewPort && !allowLoad) {
				setAllowLoad(true);
			}
		}, [isInViewPort]);

		useEffect(() => {
			setAllowLoad(false);
		}, [fileURL]);

		useImperativeHandle(ref, () => ({
			scrollIntoView,
		}));

		return (
			<div id={`page-document-${pageIndex + 1}`} className="page-document" ref={pageDocumentRef}>
				{allowLoad ? (
					<div className={`page-item z-${currentZoom}`} style={{ minHeight: pageSize.height }}>
						<Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
							<Viewer
								fileUrl={fileURL}
								defaultScale={SpecialZoomLevel.PageWidth}
								pageLayout={pageLayout}
							/>
						</Worker>
					</div>
				) : (
					<div
						className="loading-container"
						style={{
							width: pageSize.width - 10,
							height: pageSize.height,
						}}>
						<div className="loading-component">
							<LoadingIcons.Oval
								className="loadin-icon"
								stroke="#00ACCE"
								speed={2}
								width="4rem"
								height="4rem"
								strokeWidth="3"
							/>
						</div>
					</div>
				)}
			</div>
		);
	}
);

PageDocument.displayName = "PageDocument";

import React, { useState } from "react";
import MainContainer from "../../components/layout/MainContainer";
import { useNavigate } from "react-router";
import Breadcrumbs from "../../components/templates/BreadCrumbs/BreadCrumbs";
import { Book, CountActivityEventDto, EventEntityType } from "../../models";
import ViewIcon from "../../assets/icons/ViewIcon";
import DownloadIcon from "../../assets/icons/DownloadIcon";
import { useNotification } from "../../hooks/useNotification.hook";
import getStudentState from "../../helpers/StudentState.utility";
import QrCodeIcon from "../../assets/icons/QrCodeIcon";
import ChaptersTable from "./components/ChaptersTable";
import RedBarText from "../../components/templates/RedBarText/RedBarText";
import RelatedList from "./components/RelatedList";
import { Link, useParams } from "react-router-dom";
import { useFetchAndLoad } from "../../hooks/useFetchAndLoad.hook";
import { countDownlaodActivity, getBookById } from "../../services";
import { useAsync } from "../../hooks/useAsync.hook";
import LoadingIcons from "react-loading-icons";
import CoverImageContainer from "./components/CoverImageContainer";
import { useAuth } from "../../hooks/useAuth.hook";

const BookDetail = () => {
	const params = useParams();
	const navigate = useNavigate();
	const { user } = useAuth();
	const { notifySuccess } = useNotification();
	const { loading, callEndpoint } = useFetchAndLoad();
	const { callEndpoint: callCountActivityEndpoint } = useFetchAndLoad();
	const [book, setBook] = useState<Book>();
	const studentState = getStudentState(params);

	const getBookApiCall = async () => await callEndpoint(getBookById(params.book!));

	const adaptData = (backData: any) => {
		if (backData.data) setBook(backData.data);
		else navigate("/not-found");
	};

	const countDownloadActivityApiCall = async () => {
		try {
			const activity: CountActivityEventDto = {
				userId: user?.id,
				entityId: book?.id,
				entityType: EventEntityType.Book,
			};

			await callCountActivityEndpoint(countDownlaodActivity(activity));
		} catch (err) {
			console.log(err);
		}
	};

	const handleRead = () => {
		if (book) navigate(``);
	};

	const handleDownload = () => {
		if (book?.pdfCode) {
			const downloadLink = document.createElement("a");
			downloadLink.href = book.pdfCode;
			downloadLink.download = book.pdfCode;
			downloadLink.click();
			downloadLink.remove();
			notifySuccess("El documento se ha descargado con exito.");
			countDownloadActivityApiCall();
		}
	};

	useAsync(getBookApiCall, adaptData, [params]);

	return (
		<MainContainer id="BookDetail">
			{!loading && book ? (
				<>
					<Breadcrumbs
						history={["Libro Abierto", studentState, params.subject!, book.title]}
					/>
					<section className="book-details-card-container">
						<article id="book-details-card">
							<CoverImageContainer book={book} />
							<div className="book-data-container">
								<div className="details-container">
									<h2 className="title">{book.title || ""}</h2>
									<h3 className="autor">{book.publisher || ""}</h3>
									<p className="publisher">{book.autor || ""}</p>
									<p className="description">{book.description}</p>
									<p>{book.pagesNumber || "-"} páginas</p>
									<div className="grade">{`${params.level}, ${params.grade}`}</div>
								</div>
								{book.pdfCode && (
									<div className="book-actions action-buttons">
										<Link
											to={`/${params.level}/${params.grade}/${book.subjectName}/${book.id}/lector`}
											className="lector-btn">
											<span>Leer</span>
											<ViewIcon className="medium-less-icon" fill="black" />
										</Link>
										<button onClick={handleDownload} className="download-btn">
											<span>Descargar</span>
											<DownloadIcon className="small-icon" fill="black" />
										</button>
										<div className="qrcode-button">
											<QrCodeIcon className="medium-plus-icon" />
										</div>
									</div>
								)}
							</div>
						</article>
						{book.chapters && book.chapters.length > 0 && (
							<div className="book-chapters-container">
								<RedBarText title="Unidades" />
								<ChaptersTable
									chapters={book.chapters}
									download={handleDownload}
									view={handleRead}
								/>
							</div>
						)}
						<div className="book-related-container">
							<RedBarText title="Libros relacionados" />
							<RelatedList bookId={book?.id!} />
						</div>
					</section>
				</>
			) : (
				<div className="loading-container">
					<div className="loading-component">
						<LoadingIcons.Oval
							className="loadin-icon"
							stroke="#00ACCE"
							speed={2}
							width="6rem"
							height="6rem"
							strokeWidth="3"
						/>
					</div>
				</div>
			)}
		</MainContainer>
	);
};

export default BookDetail;

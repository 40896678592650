import React from "react";
import IconProps from "./IconProps.interface";

const DownloadIcon: React.FC<IconProps> = (props) => {
	return (
		<svg
			className={props.className}
			onClick={props.onClick}
			viewBox="0 0 15 17"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M0 14.8158H15V16.5H0V14.8158ZM8.33333 9.90766L13.3926 4.79516L14.5711 5.98608L7.5 13.1316L0.428933 5.98608L1.60744 4.79516L6.66667 9.90766V0.5H8.33333V9.90766Z"
				fill={props.fill || "white"}
			/>
		</svg>
	);
};

export default DownloadIcon;

import React from "react";
import IconProps from "./IconProps.interface";

const RedirectIcon: React.FC<IconProps> = (props) => {
	return (
		<svg
			className={props.className}
			onClick={props.onClick}
			viewBox="0 0 32 27"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M24.4441 15.5412H23.3339C23.1717 15.5412 23.0386 15.5931 22.9351 15.6975C22.8307 15.8013 22.7788 15.9343 22.7788 16.0962V21.6468C22.7788 22.41 22.5072 23.0632 21.9638 23.6068C21.4203 24.1503 20.7669 24.4218 20.0036 24.4218H5.57186C4.80867 24.4218 4.15526 24.1503 3.61169 23.6068C3.06824 23.0633 2.79655 22.4101 2.79655 21.6468V7.21522C2.79655 6.45203 3.06818 5.79886 3.61169 5.25536C4.15526 4.71179 4.80867 4.44016 5.57186 4.44016H17.7831C17.9452 4.44016 18.0783 4.38798 18.1822 4.28403C18.2862 4.17995 18.3381 4.04709 18.3381 3.88505V2.77478C18.3381 2.61305 18.2862 2.47994 18.1822 2.37605C18.0783 2.27198 17.9452 2.22003 17.7831 2.22003H5.57186C4.1959 2.22003 3.01922 2.70861 2.04193 3.68572C1.065 4.66277 0.576416 5.83945 0.576416 7.2154V21.647C0.576416 23.0229 1.065 24.1998 2.04199 25.1765C3.01928 26.1537 4.19596 26.6425 5.57192 26.6425H20.0036C21.3795 26.6425 22.5563 26.1537 23.5335 25.1765C24.5107 24.1998 24.9993 23.023 24.9993 21.647V16.0967C24.9993 15.9345 24.9473 15.8015 24.8429 15.6975C24.7389 15.5931 24.6058 15.5412 24.4441 15.5412Z"
				fill={props.fill || "#636363"}
			/>
			<path
				d="M31.33 0.329529C31.1102 0.109782 30.8497 0 30.5493 0H21.6684C21.3678 0 21.1074 0.109782 20.8879 0.329529C20.6681 0.549155 20.5581 0.809364 20.5581 1.11004C20.5581 1.41071 20.6681 1.67098 20.8879 1.89072L23.9406 4.94349L12.6314 16.2529C12.5156 16.3685 12.4579 16.5016 12.4579 16.6516C12.4579 16.8022 12.5158 16.9352 12.6314 17.0508L14.6088 19.028C14.7244 19.1436 14.8575 19.2013 15.0078 19.2013C15.1581 19.2013 15.2912 19.1437 15.4068 19.028L26.716 7.71879L29.769 10.7716C29.9885 10.9913 30.2488 11.1012 30.5495 11.1012C30.8501 11.1012 31.1104 10.9913 31.3302 10.7716C31.55 10.5519 31.6598 10.2917 31.6598 9.99104V1.11004C31.6597 0.809182 31.5495 0.549155 31.33 0.329529Z"
				fill={props.fill || "#636363"}
			/>
		</svg>
	);
};

export default RedirectIcon;

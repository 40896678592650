import React from "react";
import IconProps from "./IconProps.interface";

const RobotIcon: React.FC<IconProps> = (props) => {
	return (
		<svg
			className={props.className}
			onClick={props.onClick}
			viewBox="0 0 44 44"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_730_230)">
				<path
					d="M37.8125 25.3722H35.75V23.3097C35.75 21.4142 34.2079 19.8722 32.3125 19.8722H28.875V17.8097C28.875 17.4302 28.567 17.1222 28.1875 17.1222H22.6875V15.6496C23.87 15.3422 24.75 14.2746 24.75 12.9972C24.75 11.4806 23.5166 10.2472 22 10.2472C20.4834 10.2472 19.25 11.4806 19.25 12.9972C19.25 14.2746 20.13 15.3422 21.3125 15.6496V17.1222H15.8125C15.433 17.1222 15.125 17.4302 15.125 17.8097V19.8722H11.6875C9.79206 19.8722 8.25 21.4142 8.25 23.3097V25.3722H6.1875C5.05037 25.3722 4.125 26.2976 4.125 27.4347V35.6847C4.125 36.8218 5.05037 37.7472 6.1875 37.7472H8.25V39.8097C8.25 41.7051 9.79206 43.2472 11.6875 43.2472H32.3125C34.2079 43.2472 35.75 41.7051 35.75 39.8097V37.7472H37.8125C38.9496 37.7472 39.875 36.8218 39.875 35.6847V27.4347C39.875 26.2976 38.9496 25.3722 37.8125 25.3722ZM20.625 12.9972C20.625 12.2389 21.2417 11.6222 22 11.6222C22.7583 11.6222 23.375 12.2389 23.375 12.9972C23.375 13.7555 22.7583 14.3722 22 14.3722C21.2417 14.3722 20.625 13.7555 20.625 12.9972ZM6.1875 36.3722C5.80869 36.3722 5.5 36.0635 5.5 35.6847V27.4347C5.5 27.0559 5.80869 26.7472 6.1875 26.7472H8.25V36.3722H6.1875ZM16.5 18.4972H27.5V19.8722H16.5V18.4972ZM34.375 39.8097C34.375 40.9468 33.4496 41.8722 32.3125 41.8722H11.6875C10.5504 41.8722 9.625 40.9468 9.625 39.8097V23.3097C9.625 22.1726 10.5504 21.2472 11.6875 21.2472H32.3125C33.4496 21.2472 34.375 22.1726 34.375 23.3097V39.8097ZM38.5 35.6847C38.5 36.0635 38.1913 36.3722 37.8125 36.3722H35.75V26.7472H37.8125C38.1913 26.7472 38.5 27.0559 38.5 27.4347V35.6847Z"
					fill={props.fill || "white"}
				/>
				<path
					d="M28.1875 32.2472H15.8125C15.433 32.2472 15.125 32.5552 15.125 32.9347V33.6222C15.125 37.4131 18.2091 40.4972 22 40.4972C25.7909 40.4972 28.875 37.4131 28.875 33.6222V32.9347C28.875 32.5552 28.567 32.2472 28.1875 32.2472ZM22 39.1222C18.9674 39.1222 16.5 36.6547 16.5 33.6222H27.5C27.5 36.6547 25.0326 39.1222 22 39.1222Z"
					fill={props.fill || "white"}
				/>
				<path
					d="M17.875 28.1222C17.875 26.6056 16.6416 25.3722 15.125 25.3722C13.6084 25.3722 12.375 26.6056 12.375 28.1222C12.375 29.6388 13.6084 30.8722 15.125 30.8722C16.6416 30.8722 17.875 29.6388 17.875 28.1222ZM13.75 28.1222C13.75 27.3639 14.3667 26.7472 15.125 26.7472C15.8833 26.7472 16.5 27.3639 16.5 28.1222C16.5 28.8805 15.8833 29.4972 15.125 29.4972C14.3667 29.4972 13.75 28.8805 13.75 28.1222Z"
					fill={props.fill || "white"}
				/>
				<path
					d="M28.875 25.3722C27.3584 25.3722 26.125 26.6056 26.125 28.1222C26.125 29.6388 27.3584 30.8722 28.875 30.8722C30.3916 30.8722 31.625 29.6388 31.625 28.1222C31.625 26.6056 30.3916 25.3722 28.875 25.3722ZM28.875 29.4972C28.1167 29.4972 27.5 28.8805 27.5 28.1222C27.5 27.3639 28.1167 26.7472 28.875 26.7472C29.6333 26.7472 30.25 27.3639 30.25 28.1222C30.25 28.8805 29.6333 29.4972 28.875 29.4972Z"
					fill={props.fill || "white"}
				/>
				<path
					d="M11.2014 15.5457C11.3327 15.6771 11.5088 15.7472 11.6875 15.7472C11.7762 15.7472 11.8656 15.73 11.9508 15.6949C12.2072 15.5884 12.375 15.3381 12.375 15.0597V12.9972H16.5C17.6371 12.9972 18.5625 12.0718 18.5625 10.9347V2.68468C18.5625 1.54756 17.6371 0.622185 16.5 0.622185H2.75C1.61287 0.622185 0.6875 1.54756 0.6875 2.68468V10.9347C0.6875 12.0718 1.61287 12.9972 2.75 12.9972H8.65288L11.2014 15.5457ZM2.0625 10.9347V2.68468C2.0625 2.30587 2.37119 1.99718 2.75 1.99718H16.5C16.8788 1.99718 17.1875 2.30587 17.1875 2.68468V10.9347C17.1875 11.3135 16.8788 11.6222 16.5 11.6222H11.6875C11.308 11.6222 11 11.9302 11 12.3097V13.4001L9.42356 11.8236C9.29431 11.6944 9.11969 11.6222 8.9375 11.6222H2.75C2.37119 11.6222 2.0625 11.3142 2.0625 10.9347Z"
					fill={props.fill || "white"}
				/>
				<path
					d="M41.25 3.37218H27.5C26.3629 3.37218 25.4375 4.29756 25.4375 5.43468V13.6847C25.4375 14.8218 26.3629 15.7472 27.5 15.7472H31.625V17.8097C31.625 18.0874 31.7928 18.3384 32.0492 18.4449C32.1344 18.48 32.2238 18.4972 32.3125 18.4972C32.4912 18.4972 32.6673 18.4271 32.7986 18.2957L35.3471 15.7472H41.25C42.3871 15.7472 43.3125 14.8218 43.3125 13.6847V5.43468C43.3125 4.29756 42.3871 3.37218 41.25 3.37218ZM41.9375 13.6847C41.9375 14.0635 41.6288 14.3722 41.25 14.3722H35.0625C34.8803 14.3722 34.705 14.4444 34.5764 14.5736L33 16.1501V15.0597C33 14.6802 32.692 14.3722 32.3125 14.3722H27.5C27.1212 14.3722 26.8125 14.0635 26.8125 13.6847V5.43468C26.8125 5.05587 27.1212 4.74718 27.5 4.74718H41.25C41.6288 4.74718 41.9375 5.05587 41.9375 5.43468V13.6847Z"
					fill={props.fill || "white"}
				/>
				<path
					d="M3.4375 3.37218H15.8125V4.74718H3.4375V3.37218Z"
					fill={props.fill || "white"}
				/>
				<path
					d="M3.4375 8.87218H15.8125V10.2472H3.4375V8.87218Z"
					fill={props.fill || "white"}
				/>
				<path
					d="M4.8125 6.12218H14.4375V7.49718H4.8125V6.12218Z"
					fill={props.fill || "white"}
				/>
				<path
					d="M28.1875 6.12218H40.5625V7.49718H28.1875V6.12218Z"
					fill={props.fill || "white"}
				/>
				<path
					d="M28.1875 11.6222H40.5625V12.9972H28.1875V11.6222Z"
					fill={props.fill || "white"}
				/>
				<path
					d="M29.5625 8.87218H39.1875V10.2472H29.5625V8.87218Z"
					fill={props.fill || "white"}
				/>
			</g>
		</svg>
	);
};

export default RobotIcon;

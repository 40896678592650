import React, { useState } from "react";
import VerticalLogo from "../../assets/images/Minerd-Vertical-Logo.png";
import Background from "../../assets/images/Ministerio.jpeg";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFetchAndLoad } from "../../hooks/useFetchAndLoad.hook";
import { confirmAccount } from "../../services";
import { useAsync } from "../../hooks/useAsync.hook";
import LoadingIcons from "react-loading-icons";
import LibroAbiertoLogoIcon from "../../assets/icons/LibroAbiertoLogoIcon";

const isGUID = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

const ConfirmMail: React.FC = () => {
	const params = useParams();
	const navigate = useNavigate();
	const [confirmed, setConfirmed] = useState<boolean>(false);
	const { loading, callEndpoint } = useFetchAndLoad();

	const confirmAccountApiCall = async () => {
		if (params.clientId && isGUID.test(params.clientId as string))
			await callEndpoint(confirmAccount(params.clientId as string));
		else navigate("/Error");
	};

	const adaptData = () => {
		setConfirmed(true);
	};

	useAsync(confirmAccountApiCall, adaptData);

	return (
		<main id="confirm-mail-page" style={{ backgroundImage: `url(${Background})` }}>
			<div className="overlay"></div>
			<div className="login-container">
				<div className="logo-container">
					<img src={VerticalLogo} alt="Minerd Logo" />
					<LibroAbiertoLogoIcon className="header-logo-icon" />
				</div>
				<div className="confirm-message-container">
					{!loading ? (
						confirmed ? (
							<>
								<div className="content">
									<h2>Se ha confirmado su cuenta exitosamente</h2>
									<div>
										Por favor revise su correo para confirmar la cuenta antes de utilizar los
										servicios de Libro Abierto.
									</div>
								</div>
								<div className="action">
									<Link to={"/"} className="back-button">
										Iniciar Sesión
									</Link>
								</div>
							</>
						) : (
							<>
								<div className="content">
									<h2>Ha ocurrido un error al confirmar su cuenta</h2>
									<div>
										Su cuenta no pudo ser confirmada con exito. Por favor escribir a nuestro buzón
										de ayudas: libroabierto@minerd.gob.do.
									</div>
								</div>
								<div className="action">
									<Link to={"/"} className="back-button">
										Iniciar Sesión
									</Link>
								</div>
							</>
						)
					) : (
						<div className="loading-container">
							<div className="loading-component">
								<LoadingIcons.Oval
									className="loadin-icon"
									stroke="#00ACCE"
									speed={2}
									width="6rem"
									height="6rem"
									strokeWidth="3"
								/>
							</div>
						</div>
					)}
				</div>
			</div>
		</main>
	);
};

export default ConfirmMail;

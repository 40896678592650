import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";

export interface axiosCallProps {
	call: Promise<AxiosResponse<any>>;
	controller: AbortController;
}

export const useFetchAndLoad = () => {
	const [loading, setLoading] = useState<boolean>(false);
	let controller: AbortController;

	const callEndpoint = async (axiosCall: axiosCallProps) => {
		if (axiosCall.controller) controller = axiosCall.controller;
		let result: any = {};

		setLoading(true);

		try {
			result = await axiosCall.call;
			setLoading(false);
			return result.data;
		} catch (err: any) {
			if (err !== "canceled" && err?.code !== "ERR_CANCELED") {
				setLoading(false);
			}
			return Promise.reject(err.response);
		}
	};

	const cancelEndpoint = () => {
		setLoading(false);
		controller && controller.abort();
	};

	useEffect(() => {
		return () => {
			cancelEndpoint();
		};
	}, []);

	return { loading, callEndpoint, cancelEndpoint };
};

import React, { useState } from "react";
import { useFetchAndLoad } from "../../../hooks/useFetchAndLoad.hook";
import { Chapter, MediaFile, MediaType } from "../../../models";
import { getMediaFileByChapter } from "../../../services/MediaFile.service";
import MediaGroup from "../../../components/templates/MediaGroup/MediaGroup";
import OpenCollectionIcon from "../../../assets/icons/OpenCollectionIcon";
import CloseCollectionIcon from "../../../assets/icons/CloseCollectionIcon";

const MediaDataOptions = [
	{ label: "Videos", value: "videos" },
	{ label: "Audios", value: "audios" },
];

interface ChapterMediaProps {
	chapter: Chapter;
}

const ChapterMedia: React.FC<ChapterMediaProps> = ({ chapter }) => {
	const { loading, callEndpoint } = useFetchAndLoad();
	const [chapterContent, setChapterContent] = useState<{
		show: boolean;
		mediaFiles: MediaFile[];
	}>({ show: false, mediaFiles: [] });

	const handleOpen = async (chapter: any) => {
		let chapterMedia: MediaFile[] = [];

		if (chapterContent.mediaFiles.length <= 0 && !chapterContent.show)
			chapterMedia = await getMediaFilesApiCall(chapter.id);
		else chapterMedia = chapterContent.mediaFiles;

		setChapterContent({
			show: !chapterContent.show,
			mediaFiles: chapterMedia,
		});
	};

	const getMediaFilesApiCall = async (chapterId: string) => {
		try {
			const result = await callEndpoint(getMediaFileByChapter(chapterId));
			if (result.data) return result.data;
			else return null;
		} catch (err) {
			console.log(err);
			return null;
		}
	};

	return (
		<>
			<div className="media-state-button-container">
				<div
					className="media-state-button"
					onClick={() => {
						handleOpen(chapter);
					}}>
					{!chapterContent.show ? (
						<OpenCollectionIcon className="small-plus-icon" />
					) : (
						<CloseCollectionIcon className="small-plus-icon" />
					)}
				</div>
			</div>
			{chapterContent.show && !loading && (
				<div className="chapter-media">
					{
						<MediaGroup
							entity={{ mediaFiles: chapterContent.mediaFiles } as Chapter}
							type={MediaType.Video}
							dataInfo={MediaDataOptions.find((x) => x.value == "videos")!}
						/>
					}
					<MediaGroup
						entity={{ mediaFiles: chapterContent.mediaFiles } as Chapter}
						type={MediaType.Audio}
						dataInfo={MediaDataOptions.find((x) => x.value == "audios")!}
					/>
				</div>
			)}
		</>
	);
};

export default ChapterMedia;

import api from "../api";
import { loadAbort } from "../helpers/loadAbort.utility";

export const getGrades = () => {
	const controller = loadAbort();
	return { call: api.get("api/Grade", { signal: controller.signal }), controller };
};

export const getGradesById = (id: string) => {
	const controller = loadAbort();
	return { call: api.get(`api/Grade/${id}`, { signal: controller.signal }), controller };
};

export const getGradesByName = (level: number, gradeName: string) => {
	const controller = loadAbort();
	return {
		call: api.get("api/Grade/Name", {
			params: {
				level: level,
				gradeName: gradeName,
			},
			signal: controller.signal,
		}),
		controller,
	};
};

export const getGradesByLevel = (level: number) => {
	const controller = loadAbort();
	return {
		call: api.get("api/Grade/Level", {
			params: {
				level: level,
			},
			signal: controller.signal,
		}),
		controller,
	};
};

import React, { useState } from "react";
import MainContainer from "../../components/layout/MainContainer";
import Slideshow from "../../components/templates/Slideshow/Slideshow";
import ExpansionPanel from "../../components/templates/ExpansionPanel/ExpansionPanel";
import { useNavigate } from "react-router-dom";
import { BannerImage, Grade, LevelEntities } from "../../models";
import StandupBook from "../../assets/images/standup-book.png";
import InfoSection from "./components/InfoSection";
import { useFetchAndLoad } from "../../hooks/useFetchAndLoad.hook";
import { useAsync } from "../../hooks/useAsync.hook";
import { getBannerImages, getGrades } from "../../services";
import LoadingIcons from "react-loading-icons";
import { useAuth } from "../../hooks/useAuth.hook";

const Home: React.FC = () => {
	const navigate = useNavigate();
	const { token } = useAuth();
	const [grades, setGrades] = useState<Grade[]>([]);
	const [imgList, setImageList] = useState<BannerImage[]>([]);
	const { loading: loadingGrades, callEndpoint: callGrades } = useFetchAndLoad();
	const { loading: loadingBanner, callEndpoint: callBanner } = useFetchAndLoad();

	const getGradesApiCall = async () => await callGrades(getGrades());
	const getBannerImagesApiCall = async () => await callBanner(getBannerImages());

	const adaptGrades = (data: any) => data.data && setGrades(data.data);
	const adaptBanner = (data: any) => data.data && setImageList(data.data);

	const selectGrade = (level: string, grade: string) => {
		navigate(`/${level}/${grade}`);
	};

	useAsync(getGradesApiCall, adaptGrades, [token], token != null && grades.length == 0);
	useAsync(getBannerImagesApiCall, adaptBanner, [token], token != null && grades.length == 0);

	return (
		<MainContainer id="Home" palace={false}>
			{!loadingBanner ? (
				<Slideshow id="home-slideshow" imgList={imgList} />
			) : (
				<div className="loading-container">
					<div className="loading-component">
						<LoadingIcons.Oval
							className="loadin-icon"
							stroke="#00ACCE"
							speed={2}
							width="6rem"
							height="6rem"
							strokeWidth="3"
						/>
					</div>
				</div>
			)}
			<InfoSection />
			<div className="home-content">
				<section className="books-list-container">
					<div className="list-title">
						<h2>Bienvenido a Libro Abierto</h2>
					</div>
					<div className="book-image">
						<img src={StandupBook} alt="Libro de lectura" />
					</div>
					<div className="levels-container">
						{!loadingGrades ? (
							LevelEntities.length > 0 ? (
								LevelEntities.map((level) => (
									<ExpansionPanel text={level.name} key={level.level}>
										<div className="grades-container">
											{grades && grades.length > 0 ? (
												grades
													.filter((x) => x.level == level.level)
													.sort((a, b) => a.number! - b.number!)
													.map((grade) => (
														<div
															className="grade-item"
															key={grade.id}
															onClick={() => selectGrade(level.name, grade.name)}>
															{grade.number && <span>{`${grade.number}º -`}</span>}
															<span>{grade.name}</span>
														</div>
													))
											) : (
												<div>No se encontaron grados</div>
											)}
										</div>
									</ExpansionPanel>
								))
							) : (
								<div className="not-found-container">
									<div>No se encontraron niveles</div>
								</div>
							)
						) : (
							<div className="loading-container">
								<div className="loading-component">
									<LoadingIcons.Oval
										className="loadin-icon"
										stroke="#00ACCE"
										speed={2}
										width="6rem"
										height="6rem"
										strokeWidth="3"
									/>
								</div>
							</div>
						)}
					</div>
				</section>
			</div>
		</MainContainer>
	);
};

export default Home;

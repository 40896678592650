import React from "react";
import { User } from "../../../models";

export type Province = {
	id: string;
	name: string;
};

export interface PerfilFormProps {
	user: User;
	provinces: Province[];
	province: string;
	editable?: boolean;
	handleSubmit?: (e: React.FormEvent<HTMLFormElement>) => Promise<void>;
}

const PerfilForm: React.FC<PerfilFormProps> = ({
	user,
	provinces,
	province,
	editable = false,
	handleSubmit,
}) => {
	return (
		<form id="perfil-data-form" onSubmit={handleSubmit}>
			<div className="perfil-data-section">
				<div className="label-container">
					<label htmlFor="personame">Nombre</label>
					<span> y </span>
					<label htmlFor="lastname">Apellido</label>
				</div>
				<div className="input-container">
					<input
						className="form-input"
						id="personname"
						type="text"
						{...(editable ? { defaultValue: user.name } : { value: user.name })}
						disabled={!editable}
					/>
					<input
						className="form-input"
						id="lastname"
						type="text"
						{...(editable ? { defaultValue: user.lastName } : { value: user.lastName })}
						disabled={!editable}
					/>
				</div>
			</div>
			<div className="perfil-data-section">
				<div className="label-container">
					<label>Credenciales</label>
				</div>
				<div className="input-container">
					<input
						className="form-input"
						id="email"
						type="email"
						required
						value={user.email}
						disabled
					/>
					<input
						className="form-input"
						id="password"
						type="password"
						required
						defaultValue={"password"}
						disabled
					/>
					{/* <div className="password-link">
						<ExternalLinkIcon className="small-icon" />
					</div> */}
				</div>
			</div>
			<div className="perfil-data-section">
				<div className="label-container">
					<label htmlFor="province">Provincia</label>
				</div>
				<div className="input-container">
					<select
						className="form-input"
						id="province"
						required
						{...(editable ? { defaultValue: province } : { value: province })}
						disabled={!editable}>
						{provinces.map((province) => (
							<option value={province.id} key={province.id}>
								{province.name}
							</option>
						))}
					</select>
				</div>
			</div>
			<div className="perfil-data-section">
				<div className="label-container">
					<label htmlFor="phone">Número de teléfono</label>
				</div>
				<div className="input-container">
					<input
						className="form-input"
						id="phone"
						type="text"
						pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
						required
						{...(editable
							? { defaultValue: user.cellphone }
							: { value: user.cellphone })}
						disabled={!editable}
					/>
				</div>
			</div>
			<div className="perfil-data-section">
				<div className="label-container">
					<label htmlFor="userrole">Rol</label>
				</div>
				<div className="input-container">
					<select
						className="form-input"
						id="userrole"
						required
						{...(editable ? { defaultValue: user.role } : { value: user.role })}
						disabled={!editable}>
						<option value={1}>Estudiante</option>
						<option value={2}>Profesor</option>
					</select>
				</div>
			</div>
		</form>
	);
};

export default PerfilForm;

import { createRef, useRef, useState } from "react";
import MainContainer from "../../components/layout/MainContainer";
import RedBarText from "../../components/templates/RedBarText/RedBarText";
import { useFetchAndLoad } from "../../hooks/useFetchAndLoad.hook";
import { getProvinces, updateUser } from "../../services";
import { useAuth } from "../../hooks/useAuth.hook";
import PerfilForm, { Province } from "./components/PerfilForm";
import { useAsync } from "../../hooks/useAsync.hook";
import { UpdateUserDto } from "../../models";
import LoadingIcons from "react-loading-icons";
import { useNotification } from "../../hooks/useNotification.hook";

const PerfilPage = () => {
	const { notifySuccess } = useNotification();
	const { user, token, login, insertToken } = useAuth();
	const [allowEdit, setAllowEdit] = useState<boolean>(false);
	const [provinces, setProvinces] = useState<Province[]>([]);
	const { loading: provinceLoading, callEndpoint: callProvinceEndpoint } = useFetchAndLoad();
	const { loading: loadingUser, callEndpoint: callUserEndpoint } = useFetchAndLoad();
	const [provinceId, setProvinceId] = useState<string>();

	const getProvincesApiCall = async () => callProvinceEndpoint(getProvinces());
	const adaptProvinces = (backData: any) => {
		if (backData.data) {
			setProvinces(backData.data);
			setProvinceId(backData.data.find((x: Province) => x.name == user?.province).id);
		}
	};

	const handleAllowEdit = () => {
		setAllowEdit(true);
		getProvincesApiCall();
	};

	const handleCancel = () => {
		setAllowEdit(false);
	};

	const handleSave = () => {
		const form = document.getElementById("perfil-data-form") as HTMLFormElement;
		form.requestSubmit();
	};

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		if (!loadingUser) {
			const userData: UpdateUserDto = {
				name: e.currentTarget.personname.value,
				lastName: e.currentTarget.lastname.value,
				provinceId: e.currentTarget.province.value,
				cellphone: e.currentTarget.phone.value,
				role: parseInt(e.currentTarget.userrole.value),
			};

			try {
				const result = await callUserEndpoint(updateUser(userData, token!));
				if (result) {
					login(result.data);
					insertToken(result.data.token);
					setProvinceId(userData.provinceId);
					notifySuccess("Perfil actualizado correctamente");
					setAllowEdit(false);
				}
			} catch (err: any) {
				console.log(err);
			}
		}
	};

	useAsync(getProvincesApiCall, adaptProvinces);

	return (
		<MainContainer id="PerfilPage">
			<div className="perfil-page-header">
				<RedBarText title="INFORMACIÓN PERSONAL" subtitle="Verifica o edita tus datos" />
				<div className="perfil-actions">
					{!allowEdit ? (
						<button className="edit-button" onClick={handleAllowEdit}>
							Editar
						</button>
					) : !loadingUser ? (
						<>
							<button className="cancel-button" onClick={handleCancel}>
								Cancelar
							</button>
							<button className="save-button" onClick={handleSave}>
								Guardar
							</button>
						</>
					) : (
						<div className="loading-component">
							<LoadingIcons.Oval
								className="loadin-icon"
								stroke="#00ACCE"
								speed={2}
								width="2rem"
								height="2rem"
								strokeWidth="2"
							/>
						</div>
					)}
				</div>
			</div>
			{!provinceLoading ? (
				!allowEdit ? (
					<PerfilForm user={user!} province={provinceId!} provinces={provinces} />
				) : (
					<PerfilForm
						user={user!}
						provinces={provinces}
						province={provinceId!}
						handleSubmit={handleSubmit}
						editable
					/>
				)
			) : (
				<div className="loading-container">
					<div className="loading-component">
						<LoadingIcons.Oval
							className="loadin-icon"
							stroke="#00ACCE"
							speed={2}
							width="6rem"
							height="6rem"
							strokeWidth="3"
						/>
					</div>
				</div>
			)}
		</MainContainer>
	);
};

export default PerfilPage;

import React from "react";
import { useAuth } from "../../../hooks/useAuth.hook";
import { useNotification } from "../../../hooks/useNotification.hook";
import { useFetchAndLoad } from "../../../hooks/useFetchAndLoad.hook";
import { login } from "../../../services";
import { LoginUserDto } from "../../../models";
import LoadingIcons from "react-loading-icons";

const LoginForm: React.FC = () => {
	const { login: loginUser, insertToken } = useAuth();
	const { notifyError } = useNotification();
	const { loading, callEndpoint } = useFetchAndLoad();

	const loginApiCall = async (user: LoginUserDto) => await callEndpoint(login(user));

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		const userData: LoginUserDto = {
			email: e.currentTarget.mail.value,
			password: e.currentTarget.password.value,
		};

		try {
			const result = await loginApiCall(userData);
			if (result.statusCode == 200) {
				loginUser(result.data);
				insertToken(result.data.token);
			}
		} catch (err: any) {
			if (err.data) {
				notifyError(err.data.data);
			} else {
				console.log(err);
			}
		}
	};

	return (
		<div className="form-container">
			<form id="logInForm" onSubmit={handleSubmit}>
				<div className="input-container">
					<input id="mail" type="email" placeholder="Correo electrónico" required />
					<input id="password" type="password" placeholder="Contraseña" required />
				</div>
				<button className="submit-btn" type="submit">
					{!loading ? (
						<span>Ingresar</span>
					) : (
						<div className="loading-component">
							<LoadingIcons.Oval
								className="loadin-icon"
								stroke="#00ACCE"
								speed={2}
								width="2rem"
								height="2rem"
								strokeWidth="2"
							/>
						</div>
					)}
				</button>
			</form>
		</div>
	);
};

export default LoginForm;

import React from "react";
import IconProps from "./IconProps.interface";

const LeftArrowIcon: React.FC<IconProps> = (props) => {
	return (
		<svg
			className={props.className}
			onClick={props.onClick}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M3.828 9.222L9.192 14.586L7.778 16L0 8.222L7.778 0.444L9.192 1.858L3.828 7.222H16V9.222H3.828Z"
				fill={props.fill || "#212529"}
			/>
		</svg>
	);
};

export default LeftArrowIcon;

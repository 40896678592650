import React from "react";
import IconProps from "./IconProps.interface";

const QrCodeIcon: React.FC<IconProps> = (props) => {
	return (
		<svg
			className={props.className}
			onClick={props.onClick}
			viewBox="0 0 34 34"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M0 0V11.2891H11.2891V0H0ZM8.63281 8.63281H2.65625V2.65625H8.63281V8.63281Z"
				fill={props.fill || "#636363"}
			/>
			<path d="M6.97266 4.31641H4.31641V6.97266H6.97266V4.31641Z" fill={props.fill || "#636363"} />
			<path
				d="M22.7109 0V11.2891H34V0H22.7109ZM31.3438 8.63281H25.3672V2.65625H31.3438V8.63281Z"
				fill={props.fill || "#636363"}
			/>
			<path d="M29.6836 4.31641H27.0273V6.97266H29.6836V4.31641Z" fill={props.fill || "#636363"} />
			<path
				d="M0 22.7109V34H11.2891V22.7109H0ZM8.63281 31.3438H2.65625V25.3672H8.63281V31.3438Z"
				fill={props.fill || "#636363"}
			/>
			<path d="M6.97266 27.0273H4.31641V29.6836H6.97266V27.0273Z" fill={props.fill || "#636363"} />
			<path d="M5.3125 13.082H2.65625V15.7383H5.3125V13.082Z" fill={props.fill || "#636363"} />
			<path
				d="M7.96875 18.3945V15.7383H5.3125V18.3945H7.90234V21.0508H10.5586V18.3945H7.96875Z"
				fill={props.fill || "#636363"}
			/>
			<path d="M21.25 5.11328H18.5938V7.76953H21.25V5.11328Z" fill={props.fill || "#636363"} />
			<path d="M15.9375 2.65625H13.2812V7.76953H15.9375V2.65625Z" fill={props.fill || "#636363"} />
			<path d="M18.5938 0H15.9375V2.65625H18.5938V0Z" fill={props.fill || "#636363"} />
			<path d="M15.9375 7.76953V10.4258H13.2812V13.082H18.5938V7.76953H15.9375Z" fill={props.fill || "#636363"} />
			<path
				d="M18.5938 23.5742V20.9844H15.9375V15.7383H13.2812V21.0508H15.9375V23.6406H18.5938V26.2305H23.9062V23.5742H18.5938Z"
				fill={props.fill || "#636363"}
			/>
			<path d="M21.25 13.082H18.5938V18.3945H21.25V13.082Z" fill={props.fill || "#636363"} />
			<path
				d="M31.3438 15.6719V13.082H26.4961V15.7383H23.9062V18.3945H26.5625V20.9844H34V15.6719H31.3438ZM31.3438 18.3281H26.5625V15.7383H31.3438V18.3281Z"
				fill={props.fill || "#636363"}
			/>
			<path d="M34 23.5742H31.3438V28.8867H34V23.5742Z" fill={props.fill || "#636363"} />
			<path d="M23.9062 18.3945H21.25V21.0508H23.9062V18.3945Z" fill={props.fill || "#636363"} />
			<path d="M26.5625 26.2305H23.9062V28.8867H26.5625V26.2305Z" fill={props.fill || "#636363"} />
			<path d="M29.2188 23.5742H26.5625V26.2305H29.2188V23.5742Z" fill={props.fill || "#636363"} />
			<path d="M26.5625 28.8867V34H29.2188V31.543H31.3438V28.8867H26.5625Z" fill={props.fill || "#636363"} />
			<path d="M15.9375 23.6406H13.2812V28.6875H15.9375V23.6406Z" fill={props.fill || "#636363"} />
			<path
				d="M21.25 31.3438V28.6875H15.9375V34H18.5938V31.3438H21.1836V34H23.8398V31.3438H21.25Z"
				fill={props.fill || "#636363"}
			/>
			<path d="M13.2812 13.082H7.96875V15.7383H13.2812V13.082Z" fill={props.fill || "#636363"} />
			<path d="M2.65625 15.7383H0V21.0508H2.65625V15.7383Z" fill={props.fill || "#636363"} />
		</svg>
	);
};

export default QrCodeIcon;

import React from "react";
import IconProps from "./IconProps.interface";

const RightArrowIcon: React.FC<IconProps> = (props) => {
	return (
		<svg
			className={props.className}
			onClick={props.onClick}
			viewBox="0 0 16 17"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M12.1716 7.50005L6.8076 2.13604L8.2218 0.721832L16 8.50005L8.2218 16.2782L6.8076 14.864L12.1716 9.50005H0V7.50005H12.1716Z"
				fill={props.fill || "#212529"}
			/>
		</svg>
	);
};

export default RightArrowIcon;

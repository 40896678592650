import React, { useState } from "react";
import LoadingIcons from "react-loading-icons";
import { useFetchAndLoad } from "../../../hooks/useFetchAndLoad.hook";
import { RestoreModuleProps } from "../models/RestoreModuleProps";
import { RestoreUserDto } from "../../../models";
import { restorePassword } from "../../../services";
import { FieldValidator } from "../../../helpers/FieldValidator.utility";

const PasswordFormModal: React.FC<RestoreModuleProps> = (props) => {
	const { loading, callEndpoint } = useFetchAndLoad();
	const [passwordErrors, setPasswordErrors] = useState<string[]>([]);
	const [confirmErrors, setConfirmErrors] = useState<string[]>([]);

	const restorePasswordApiCall = async (user: RestoreUserDto) => await callEndpoint(restorePassword(user));

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		if (!loading) {
			setPasswordErrors([]);
			setConfirmErrors([]);

			const password = e.currentTarget.password.value;
			const confirmPassword = e.currentTarget.confirm.value;

			const passwordValidation = FieldValidator.validatePassword(password);

			if (passwordValidation.valid) {
				if (password == confirmPassword) {
					const user = props.entity;
					user.password = password;

					try {
						const result = await restorePasswordApiCall(user);
						if (result.data) {
							props.goNextStep();
						}
					} catch (err) {
						console.log(err);
					}
				} else {
					setConfirmErrors(["Las contraseñas no coinciden"]);
				}
			} else {
				setPasswordErrors(passwordValidation.errors);
			}
		}
	};

	return (
		<div className="init-form-modal">
			<div className="information-text">
				Por favor ingresa una nueva contraseña que no utilices en ningún otro sitio web.
			</div>
			<div className="form-container">
				<form id="logInForm" onSubmit={handleSubmit}>
					<div className="input-container">
						<div className="input-field">
							<input id="password" type="password" placeholder="Nueva Contraseña" required />
							<div className="errors-container">
								{passwordErrors?.map((error, i) => (
									<div className="error" key={i}>
										{error}
									</div>
								))}
							</div>
						</div>
						<div className="input-field">
							<input id="confirm" type="password" placeholder="Confirmar Contraseña" required />
							<div className="errors-container">
								{confirmErrors?.map((error, i) => (
									<div className="error" key={i}>
										{error}
									</div>
								))}
							</div>
						</div>
					</div>
					<button className="submit-btn" type="submit">
						{!loading ? (
							<span>Guardar</span>
						) : (
							<div className="loading-component">
								<LoadingIcons.Oval
									className="loadin-icon"
									stroke="#00ACCE"
									speed={2}
									width="2rem"
									height="2rem"
									strokeWidth="2"
								/>
							</div>
						)}
					</button>
				</form>
			</div>
		</div>
	);
};

export default PasswordFormModal;

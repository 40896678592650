import React from "react";
import CrossIcon from "../../../assets/icons/CrossIcon";
import clsx from "clsx";
import { MediaFile, MediaType } from "../../../models";
import { getContentType } from "../../../helpers/ContentType.utility";

interface MediaPlayerProps {
	media: MediaFile;
	showPlayer: boolean | undefined;
	closeShowPlayer: () => void;
}

const MediaPlayer: React.FC<MediaPlayerProps> = ({ media, showPlayer, closeShowPlayer }) => {
	const contentType = getContentType(media.fileUrl?.split('.')[1]);

	if (showPlayer)
		return (
			<div className="media-player-modal-container">
				<div className="modal-overlay" onClick={closeShowPlayer}></div>
				<div className="media-player-modal">
					<CrossIcon className="cross-icon medium-icon" onClick={closeShowPlayer} />
					<div
						className={clsx({
							"media-container": true,
							audio: media.mediaType == MediaType.Audio,
							video: media.mediaType == MediaType.Video,
						})}>
						{media.mediaType == MediaType.Audio && (
							<audio controls>
								<source src={media.fileUrl} type={contentType} />
							</audio>
						)}
						{media.mediaType == MediaType.Video && (
							<video controls>
								<source src={media.fileUrl} type={contentType} />
							</video>
						)}
					</div>
					<div className="info-container">
						<div className="title">{media.name}</div>
						<div className="autor">{media.autor}</div>
					</div>
				</div>
			</div>
		);

	return null;
};

export default MediaPlayer;

const ContentTypeList: { [key: string]: string } = {
	".svg": "image/svg+xml",
	".pdf": "application/pdf",
	".jpeg": "image/jpeg",
	".jpg": "image/jpg",
	".png": "image/png",
	".mp4": "video/mp4",
	".mp3": "audio/mpeg",
	".aac": "audio/aac",
};

export const getContentType = (extension?: string) => {
	if (extension) return undefined;
	
	return ContentTypeList[extension!];
};

import React from "react";
import LogoutIcon from "../../../assets/icons/LogoutIcon";
import { useAuth } from "../../../hooks/useAuth.hook";
import { Link, useNavigate } from "react-router-dom";
import PerfilIcon from "../../../assets/icons/PerfilIcon";

const PrefilCard: React.FC = () => {
	const navigate = useNavigate();
	const { user, logout } = useAuth();

	const handleLogout = () => {
		logout();
		navigate("/");
	}

	return (
		<section id="perfil-card">
			<div className="perfil-data-container">
				<PerfilIcon className="big-plus-icon perfil-icon" />
				<div className="perfil-data">
					<div className="perfil-complete-name">
						<span>
							{user?.name} {user?.lastName}
						</span>
					</div>
					<div className="perfil-mail">
						<span>{user?.email}</span>
					</div>
				</div>
			</div>
			<div className="perfil-operations">
				<Link className="operation" to={"perfil"}>
					Mi Perfil
				</Link>
			</div>
			<div className="perfil-actions">
				<div className="logout-button" onClick={handleLogout}>
					<span>Cerrar sesión</span>
					<LogoutIcon className="small-plus-icon" />
				</div>
			</div>
		</section>
	);
};

export default PrefilCard;

import clsx from "clsx";
import React, { useState } from "react";
import { Book } from "../../../models";

interface CoverImageContainerProps {
	book: Book;
}

const CoverImageContainer: React.FC<CoverImageContainerProps> = ({ book }) => {
	const [activeImage, setActiveImage] = useState(true);

	if (book.backCoverImageUrl)
		return (
			<div className="image-container">
				{activeImage && <img src={book.coverImageUrl} alt="Portada de Libro" />}
				{!activeImage && <img src={book.backCoverImageUrl} alt="Contraportada de Libro" />}
				<div className="dual-image-container">
					<div
						className={clsx({ cover: true, active: activeImage })}
						onClick={() => setActiveImage(true)}></div>
					<div
						className={clsx({ "back-cover": true, active: !activeImage })}
						onClick={() => setActiveImage(false)}></div>
				</div>
			</div>
		);
	else
		return (
			<div className="image-container">
				<img src={book.coverImageUrl} alt="Portada de Libro" />
			</div>
		);
};

export default CoverImageContainer;

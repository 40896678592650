import React from "react";
import IconProps from "./IconProps.interface";

const LibroAbiertoLogoIcon: React.FC<IconProps> = (props) => {
	return (
		<svg
			className={props.className}
			onClick={props.onClick}
			data-name="Layer 1"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 1080 553.02">
			<path
				className="cls-1"
				fill="#e5292f"
				d="M852,327.9A190.66,190.66,0,0,0,876.25,329c37.89-.05,75.79.14,113.69.18,13.69,0,27.38-.18,41.07-.21,5.78,0,6,.6,6.5,6.21.83,10.19-.18,20.36,0,30.54.07,4.81-2.92,5.24-6.64,5.24q-55.18,0-110.36.05-69,0-138.07,0c-6.7,0-6.95,0-6.95-6.9,0-34.86.2-69.72.19-104.58,0-38.85-.18-77.7-.22-116.55,0-9.71.39-19.43.26-29.13-.06-4.56,2-5.82,6.1-5.76,9.39.13,18.79.26,28.18.07,3.43-.07,4.49,1.12,4.48,4.38-.06,16.4,0,32.8.06,49.2q.09,63.52.19,127.07c0,3.2-1.25,7.69,2,9.1,3.65,1.59,6-2.85,8.33-5.07,10.41-10,20.42-20.43,30.67-30.62,18.11-18,36.35-35.87,54.41-53.94,18.26-18.27,36.34-36.73,54.55-55.06,3.12-3.13,6.66-5.86,9.69-9.07,2.13-2.25,3.89-1.83,5.68,0q12.18,12.12,24.24,24.35c1.81,1.82,1.63,3.61-.17,5.39-10,9.82-19.93,19.6-29.81,29.5-6.17,6.17-12.2,12.49-18.23,18.79-7.55,7.89-15.57,15.28-23.23,23.06-9.9,10.06-19.83,20.1-30,29.92-13,12.56-25.53,25.59-38.33,38.35C860.24,317.75,855.42,321.81,852,327.9Z"
			/>
			<path
				className="cls-2"
				fill="#2a4065"
				d="M707,273.4c0-52.87.06-105.74-.1-158.61,0-4.73,1.14-6.44,6.1-6.24,9.22.39,18.48.31,27.7,0,4.71-.15,6.34,1.25,6.33,6.19-.12,47.45.05,94.91.05,142.36q0,66.64-.17,133.29c0,6.21.14,6.32,6.5,6.32q138.55-.06,277.12-.2c4.77,0,6.52,1.34,6.4,6.16-.27,10.66-.21,21.33-.13,32,0,3.93-1.56,5.08-5.48,5.08q-158.86-.15-317.74-.08c-6.46,0-6.67-.2-6.67-6.71V273.4Z"
			/>
			<path
				className="cls-2"
				fill="#2a4065"
				d="M149.34,372.37c0-22.75.4-45.52-1.55-68.23-.52-6-3-8.45-9-8.91-2.36-.18-4.17-.74-4.46-3.25s1.46-3.75,3.69-4.16c10.46-1.91,21-3.32,31.39-5.78,3.55-.84,3.7,2.1,3.7,4.58,0,7.16-.06,14.32-.07,21.48q0,24.36,0,48.71c0,1.66-.89,3.85,1.11,4.76s2.33-1.67,3.38-2.59c10.28-9.05,22.15-10.69,34.7-6.8,36.67,11.35,39.93,61,16,81.63-13.65,11.75-34.27,14.71-50.29,5.83-2.57-1.43-4.66-3.78-6.86-5.82s-4.13-1.87-6.23.06c-2.77,2.54-5.66,4.95-8.39,7.52-1.54,1.45-3.29,2.08-5.16,1.29s-2-2.51-2-4.42C149.37,416.3,149.34,394.34,149.34,372.37Zm67.86,25.28a66.32,66.32,0,0,0-1.13-17.41c-1.57-7.15-4.57-14.11-11.48-17.17-13.52-6-25.66,4.3-29,15.18-3.21,10.39-2.19,21.07-2,31.68.13,7.95,3.85,14.53,9.57,19.49,8.27,7.17,22,8.58,29.07-4.42C216.86,416.45,217.22,407.49,217.2,397.65Z"
			/>
			<path
				className="cls-2"
				fill="#2a4065"
				d="M404,216c7.54-11,18.89-13.92,31.61-12.43,15,1.77,25.89,9.75,32.23,23.57,8.25,18,7.64,35.9-3.87,51.94-9.88,13.75-27.57,21.49-46,16.18-6.54-1.89-12.43-4.55-17.25-9.34-2.13-2.11-3.77-1.47-5.71.27-2.95,2.65-6.12,5.05-9.1,7.66a4.74,4.74,0,0,1-4.92.71c-1.76-.59-1.76-2.48-1.68-4.09.8-15.74.28-31.49.35-47.24.08-18.47-.11-36.93,0-55.39a71.5,71.5,0,0,0-1.36-12.78c-.81-4.6-3.47-7.73-8.61-8.05-2.35-.15-4.55-.91-4.61-3.79s2.11-3.37,4.5-3.76c9.39-1.52,18.77-3.15,28.14-4.83,4.86-.88,5.36-.39,5.33,4.7-.1,17.19-.08,34.38-.14,51.57A10.14,10.14,0,0,0,404,216Zm43.14,32.18c-.5-6-.2-12.1-1.72-18-2.44-9.45-10.8-18.75-21.62-16.39a23.3,23.3,0,0,0-17.83,15.52c-3.9,10.77-2.76,22-2.49,33,.14,5.9,2.61,11,6.17,15.61,5.32,6.95,12.37,10,20.87,8.5,6.85-1.23,10.39-6.64,13.16-12.59C447.47,265.63,447.06,256.88,447.11,248.17Z"
			/>
			<path
				className="cls-2"
				fill="#2a4065"
				d="M117.73,396.87c.84,8.73-.54,17.53,1,26.2,1.45,8.07,1.61,8,9.81,8.49,3.65.2,5,2.85,2.77,5.88-5.34,7.17-13.16,7.74-21.1,7.14-6.58-.5-10-4.92-11.58-11-.41-1.51-.76-3.4-2.48-3.81s-2.11,1.59-2.94,2.63c-8.37,10.4-24.95,15.3-37.35,10.19-8.6-3.53-13.29-9.53-13.71-18.72-.41-9,4.74-14.86,11.65-19.46,11.66-7.76,24.9-12.3,37.59-18,1.94-.87,3.49-1.79,3.59-3.9.27-5.72.45-11.45-2.42-16.74A12.11,12.11,0,0,0,81,359c-4.76.1-8.09,2.51-10.16,6.7-1.13,2.28-2.2,4.59-3.19,6.92-1.74,4.08-5,6-9.2,6.64-7.52,1.17-12.42-3.71-12.11-11.36.34-8.17,5.9-12.08,12.24-14.11,15-4.78,30.35-5.23,45.41-.21,8.58,2.85,12.77,10.05,13.6,18.52C118.36,380.28,117.73,388.61,117.73,396.87Zm-22.62,9.67c0-3.13,0-5.35,0-7.57,0-3.07-1.42-4.15-4.22-2.61-7,3.87-14.4,7.25-20.36,12.85a12.33,12.33,0,0,0-3.62,12.13,8.38,8.38,0,0,0,6.78,6.59c5,1.1,9.54-.16,13.87-3.06C94.64,420.12,95.57,413.2,95.11,406.54Z"
			/>
			<path
				className="cls-2"
				fill="#2a4065"
				d="M239.26,294.82q-27,0-54,0c-2.14,0-4.45.22-4.38-2.87.05-2.47,1.08-3.63,4-4.14,10.79-1.88,13.59-2.32,13.77-14.69.43-29.45.21-58.9.09-88.36,0-8.54-3.05-11.74-12-13.38-2.77-.51-3.93-2.13-3.83-4.55.11-2.64,2.07-3.21,4.44-3.09,16.54.88,33.1.07,49.65.37,2.15,0,4.78-.51,4.8,3,0,3.17-2.11,3.84-4.71,4.17-10.59,1.36-12,2.58-12.75,13.35-.49,6.81.37,13.75-.48,20.49-1,8.14-.43,16.34-.25,24.33.31,13.93-.37,27.93,1,41.83.72,7.36,7.72,13,16.74,13.57a157.55,157.55,0,0,0,26.69-.1c14.41-1.49,20.63-9.07,24.46-22.16.31-1.07.44-2.18.72-3.26.68-2.63,2.45-3.51,5-3.15,3,.41,2.73,2.54,2.56,4.67-.79,9.82-1.79,19.63-2.23,29.46-.17,3.71-1.53,4.47-4.87,4.45-18.15-.12-36.3-.06-54.45-.06Z"
			/>
			<path
				className="cls-2"
				fill="#2a4065"
				d="M618.51,444.53c-21.86,1.26-43.78-16.51-47.73-35.14-4.5-21.25,1.35-38.31,19.52-50.59,19.4-13.1,47.78-10.63,65.69,5.94,14.09,13,16.27,31.21,10.93,48.23C661.31,430.83,638.69,446.25,618.51,444.53ZM596.42,398c0,8.86,1,17.44,5.67,25.25,7.94,13.4,24.31,15.44,33.51,1.52,6.39-9.67,7.82-20.38,7.24-31.45-.53-10.12-2.94-19.84-10.42-27.39-7.8-7.88-18.92-7.44-26.55.57C597.33,375.47,596.71,386.67,596.42,398Z"
			/>
			<path
				className="cls-2"
				fill="#2a4065"
				d="M618.78,203c15.21.06,28.47,5,39.27,16,10.63,10.92,14.28,31.39,9,45.73-9.11,24.8-37.12,36-59.6,30.43-28-7-43.58-32.4-35.45-60.45,4.16-14.33,15.07-23,28.55-28.45C606.37,203.93,612.38,202.41,618.78,203ZM643,250c.07-13.69-1.83-20.8-8-29.12-7.27-9.82-20.58-11.18-29-1.7-5.15,5.79-7.68,12.64-8.61,20.19-1.66,13.48-1.25,26.59,6.72,38.42,7.75,11.49,23.74,11.26,31.64-.2C641.5,269.13,643,259.8,643,250Z"
			/>
			<path
				className="cls-2"
				fill="#2a4065"
				d="M374.6,391.32c-8.59,0-17.18.08-25.77-.05-3.24,0-4.43,1-4.41,4.4.07,10,1.32,19.56,7.44,27.93,8.24,11.26,21.4,11.43,32.75,7.24a38.43,38.43,0,0,0,10.9-6.74c2-1.69,4.53-3.31,6.67-.76,2,2.38,1.74,4.71-1,7.34-17.69,17.11-44.41,18.41-63.85,6a38.25,38.25,0,0,1-18.2-30.21c-.84-10.84-.9-21.67,4.85-31.5,6.57-11.25,15.74-19,28.66-22.54,8.86-2.4,17.61-2.25,26.41-.92,9.57,1.44,22.14,9.85,24.79,21.71A74.81,74.81,0,0,1,406,386.33c.14,3.21-1,5.09-4.64,5C392.43,391.24,383.51,391.32,374.6,391.32ZM363,382.11c5.06-.79,10.14.48,15.2-.55,1.85-.37,3.84-.59,4.06-3.18.79-9.41-6.63-18.64-15.83-17.94-7.84.59-18.27,10.26-19.49,18.37-.39,2.55.74,3.41,3.22,3.34C354.42,382,358.7,382.11,363,382.11Z"
			/>
			<path
				className="cls-2"
				fill="#2a4065"
				d="M445.9,442.86c-8,0-15.92,0-23.89,0-2.09,0-4.36.27-4.62-2.74-.23-2.69,1.12-3.92,3.76-4.52,10-2.31,11.34-3.78,11.39-14.1.08-15.76.77-31.54-1.12-47.24-.92-7.71-3-9.8-10.71-10.91-2.48-.37-3.82-1.66-3.9-4-.08-2.52,1.55-3.65,4-3.76,8.6-.41,16.84-2.84,25.21-4.54,3.4-.69,5.52-.06,5.7,4a59,59,0,0,0,1.42,9.4c.2,1,.46,2.35,1.77,2.59,1.47.28,1.68-1.13,2.28-2,6.82-10.11,16.09-15.4,28.52-14.4,5.6.44,11.27,5.43,11.54,10.15.41,6.9-3.25,12.44-9.17,13.86-4.5,1.07-8.11-1.07-11.47-3.5-6.2-4.48-8.79-3.85-12.88,2.54-4.92,7.68-6.11,16.36-6.89,25-.74,8.22-.32,16.55-.22,24.83.11,7.9,2.95,10.71,10.78,11.73,3.06.4,7.78-.33,7.36,4.62-.38,4.55-4.6,2.69-7.34,2.73-7.17.12-14.33,0-21.5,0Z"
			/>
			<path
				className="cls-2"
				fill="#2a4065"
				d="M512.91,294.91c-7.79,0-15.59,0-23.39,0-2.3,0-4.53.19-4.81-3.1s1.53-4,4.22-4.44c8.91-1.61,10.69-3.53,10.83-12.64.21-13.52.13-27.05.09-40.57a39.44,39.44,0,0,0-1.67-10.82,10.21,10.21,0,0,0-9.51-7.83c-2.13-.14-4.3.09-4.27-3,0-2.72,1.26-3.72,4-4.15,8.15-1.25,16.26-2.84,24.36-4.45,4.47-.88,5.73-.11,6,4.58.22,3.32,1.74,6.44,1.43,9.84a1.92,1.92,0,0,0,1,2c1.39.66,1.42-.77,2-1.41,3.67-4.36,7.4-8.53,12.34-11.69,6.83-4.37,13.78-5.41,21.25-2.6,6.78,2.54,9.23,9.21,6.19,16.15-2.51,5.72-9.89,8.08-15.85,4.95-2-1-3.76-2.34-5.61-3.55-3.56-2.33-6.75-1.83-9.11,1.61a40.24,40.24,0,0,0-6.59,15.62,143.71,143.71,0,0,0-2.32,31.79,32.55,32.55,0,0,0,.59,5.67c1.81,8.54,3.67,10.1,12.41,10.68,2.65.17,5.47.48,5.45,3.91,0,3.78-3.13,3.32-5.64,3.33-7.8,0-15.59,0-23.39,0Z"
			/>
			<path
				className="cls-2"
				fill="#2a4065"
				d="M514.82,394.66c0-9.55,0-19.1,0-28.64,0-2.89-.91-4.62-4.1-4.46-1.59.08-3.19-.06-4.78.05-2.24.15-3.79-.55-3.92-3s-.71-4.69,2.59-5.77c10.45-3.45,19-9.34,24-19.54,1.35-2.71,4.46-1.94,6.89-1.75s2.82,2.14,2.77,4.23c-.09,4.14-.05,8.28-.2,12.42-.09,2.81,1.06,4.11,3.93,4.06,3.82-.08,7.65.11,11.47,0,3.29-.1,4.66,1.44,4.62,4.59s-1.47,4.66-4.69,4.59c-3.66-.08-7.39.46-11-.08-3.78-.57-4.38,1.25-4.39,4.13,0,10.21,0,20.43-.06,30.64,0,8.4-.4,16.8-.08,25.18.16,4.36,1.28,9,5.6,11.28a9.41,9.41,0,0,0,11.82-1.7c1.45-1.43,3.29-2.4,5.05-.75s1.11,3.89-.12,5.66a20.05,20.05,0,0,1-13.54,8.2c-9.83,1.71-19.2.78-27.13-6.31-3.08-2.75-4.27-6.24-4.66-10.08C513.89,416.64,515.09,405.64,514.82,394.66Z"
			/>
			<path
				className="cls-2"
				fill="#2a4065"
				d="M347.09,240.66c0,9.55.08,19.1,0,28.65a50.18,50.18,0,0,0,1.55,11.8c1.15,5.06,5.63,6.21,9.68,7.18,2.56.61,3.36,1.38,3.4,3.95.06,3.38-2.26,2.49-3.92,2.5q-22.22.11-44.42,0c-2,0-4.47.43-4.73-2.61-.24-2.8,1.06-4.29,4-4.84,7.7-1.45,10.77-4.29,10.94-12,.38-16.53,1-33.1-.51-49.61-.69-7.4-3.37-9.69-10.59-10.2-2.16-.15-4.49-.31-4.72-2.95-.25-2.91,2.14-3.54,4.45-3.9,10.22-1.61,20.46-3.1,30.64-4.88,3.6-.63,4.48.73,4.45,4-.09,11,0,22,0,32.95Z"
			/>
			<path
				className="cls-2"
				fill="#2a4065"
				d="M294.19,389.68c0,10.83-.27,21.66.07,32.48.34,10.43,2.49,11.78,10.61,13.58,2.61.58,4.66,1.32,4.2,4.53-.43,3-2.8,2.5-4.81,2.5-14.81,0-29.62,0-44.43-.06-1.82,0-4.55,1-4.75-2.35-.17-2.78,1.25-4.67,4.1-4.88,7.59-.56,10.23-2.74,10.87-12.84a395.13,395.13,0,0,0-.25-49.15c-.48-7.95-2.8-10.07-10.64-10.46-2.59-.12-4.85-.61-4.82-3.22s2.35-3.72,5.09-3.92c9.83-.7,19.46-2.77,29.12-4.61,4.28-.81,5.46,0,5.47,4.49q0,17,0,33.91Z"
			/>
			<path
				className="cls-2"
				fill="#2a4065"
				d="M283,314.21c7.72-.63,12.84,7,12.76,13.58-.09,7.65-6.24,13-14.67,12.81-6.61-.16-12.05-6.37-11.85-13.52C269.44,318.78,274.54,314,283,314.21Z"
			/>
			<path
				className="cls-2"
				fill="#2a4065"
				d="M335.28,153.89c9.54-.46,13.36,7.16,13.17,13.41-.23,7.73-5.6,12.86-13.14,12.52-7.93-.36-13.32-5.83-13.14-13.34S327.82,153.58,335.28,153.89Z"
			/>
		</svg>
	);
};

export default LibroAbiertoLogoIcon;

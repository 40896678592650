import clsx from "clsx";
import "./clear-button.scss";
import EraseIcon from "../../../../assets/icons/EraseIcon";

interface ClearButtonProps {
	disabled: boolean;
	action: () => void;
}

const ClearButton: React.FC<ClearButtonProps> = ({ disabled, action }) => {
	return (
		<div className={clsx({ "clear-button": true, disabled: disabled })} onClick={action}>
			<EraseIcon fill="white" className="small-icon" />
		</div>
	);
};

export default ClearButton;

import ReactDOM from "react-dom/client";
import "./assets/styles/index.scss";
import App from "./App";
import { NotifyProvider } from "./context/NotifyContext";
import { AuthProvider } from "./context/AuthContext";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
	<NotifyProvider>
		<AuthProvider>
				<App />
		</AuthProvider>
	</NotifyProvider>
);

import BotIcon from "../../../../assets/icons/BotIcon";
import RobotIcon from "../../../../assets/icons/RobotIcon";
import "./chat-bot-button.scss";

interface ChatBotButtonProps {
	hide?: boolean;
	toggleShowChatBot: () => void;
}

export const ChatBotButton = ({ hide = false, toggleShowChatBot }: ChatBotButtonProps) => {
	if (!hide)
		return (
			<div id="floating-chat-bot-button" onClick={toggleShowChatBot}>
				<div className="chat-bot-button">
					<div className="icon">
						<RobotIcon className="medium-plus-icon" />
					</div>
				</div>
			</div>
		);
	else
		return (
			<div id="chat-bot-button" onClick={toggleShowChatBot}>
				<BotIcon className="medium-less-icon" />
				<div className="text">Cerrar asistente</div>
			</div>
		);
};

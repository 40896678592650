import React from "react";
import IconProps from "./IconProps.interface";

const ArrowUpIcon: React.FC<IconProps> = (props) => {
	return (
		<svg
			className={props.className}
			onClick={props.onClick}
			xmlns="http://www.w3.org/2000/svg"
			fill={props.fill || "black"}
			viewBox="0 0 16 16">
			<path
				fillRule="evenodd"
				d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"
			/>
		</svg>
	);
};

export default ArrowUpIcon;

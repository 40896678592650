import React from "react";
import IconProps from "./IconProps.interface";

const VideoIcon: React.FC<IconProps> = (props) => {
	return (
		<svg
			className={props.className}
			onClick={props.onClick}
			viewBox="0 0 85 85"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<circle cx="42.5" cy="42.5" r="42.5" fill={props.fill || "#EE2A24"} />
			<path d="M21 42.5L53.25 23.8805L53.25 61.1195L21 42.5Z" fill="white" />
		</svg>
	);
};

export default VideoIcon;

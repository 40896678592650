import BotIcon from "../../../../assets/icons/BotIcon";
import { MiniChatAnswer } from "../../MiniChat";
import "./answer.scss";
import React from "react";

export type AnswerOption = {
	id: string;
	label: string;
};

export type AnswerProps = MiniChatAnswer & {
	selectOption?: (option: AnswerOption) => void;
};

export const Answer: React.FC<AnswerProps> = ({
	answer,
	options,
	selectedOption,
	selectOption,
}) => {
	return (
		<div className="answer-container">
			<div className="answer-box">
				<div className="answer-header">
					<div className="bot-logo">
						<BotIcon className="medium-less-icon" />
					</div>
				</div>
				<div className="answer-body">{answer}</div>
				<div className="answer-actions">
					{options && (
						<div className="answer-options">
							{options?.map((option, i) => (
								<span
									className="option"
									key={i}
									onClick={() => selectOption!(option)}>
									{option.label}
								</span>
							))}
						</div>
					)}
				</div>
			</div>
			{selectedOption && <div className="answer-response">{selectedOption?.label}</div>}
		</div>
	);
};

import { ConversationRequest, ConversationResponse } from "../models/ChatBot/Chat.model";
import { ChatRoleEnum } from "../models/ChatBot/Role.enum";

export async function chatRequest(
	conversation: ConversationRequest,
	role: ChatRoleEnum,
	abortSignal: AbortSignal
): Promise<ConversationResponse> {
	const url = `https://prodopenaiapigateway.azurewebsites.net/OpenAI/${
		role == ChatRoleEnum.Student ? "Student" : "Teacher"
	}`;
	const response = await fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Accept: "*/*",
		},
		body: JSON.stringify(conversation),
		signal: abortSignal,
	}).then((data) => data.json());

	return response;
}

import api from ".";
export const ERR_NETWORK = "ERR_NETWORK";
export const ECONNABORTED = "ECONNABORTED";

let _notifyHook: any;
let _authHook: any;
let errorMessage = true;

export const injectNotify = (notifyHook: any) => {
	_notifyHook = notifyHook;
};

export const injectAuth = (authHook: any) => {
	_authHook = authHook;
};

api.interceptors.response?.use(
	(response) => {
		errorMessage = false;
		return response;
	},
	(error) => {
		if (error?.code === ERR_NETWORK) {
			_notifyHook?.notifyError("Ha ocurrido un problema con el servidor.");
		} else if (error?.code === ECONNABORTED) {
			_notifyHook?.notifyError("Ha superado el limite de tiempo de carga.");
		} else if (error?.response?.status === 401) {
			if (!error?.response?.data?.data) {
				_authHook.logout();
				if (!errorMessage) {
					_notifyHook?.notifyError("Necesita iniciar sesión para ingresar a la página.");
					errorMessage = true;
				}
			} else {
				return Promise.reject(error);
			}
		} else {
			return Promise.reject(error);
		}
	}
);

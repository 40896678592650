import React from "react";
import MinisterioIcon from "../../assets/icons/MinisterioIcon";
import clsx from "clsx";
interface MainContainerProps {
	id: string;
	className?: string;
	children?: JSX.Element | JSX.Element[];
	palace?: boolean;
	left?: boolean;
}

const MainContainer: React.FC<MainContainerProps> = ({ id, className = "", children, palace = true, left = false }) => {
	return (
		<main className={clsx({ left: left, [className]: className != "" })}>
			<div id={id}>{children}</div>
			{palace && <MinisterioIcon className="ministerio-icon huge-icon" />}
		</main>
	);
};

export default MainContainer;

import "./chat-bot.scss";
import Chat from "./components/Chat/Chat";
import { BookPDFDto } from "../../models/Book/BookPDFDto.model";
import { ChatBotButton } from "./components/ChatBotButton";
import { DataPoint } from "../../models/ChatBot/Chat.model";
import { MiniChatAnswer } from "../MiniChat";

interface ChatBotProps {
	file: BookPDFDto;
	miniChat?: MiniChatAnswer[];
	toggleShow: () => void;
	handleReference: (d: DataPoint, n: number) => void;
}

export const ChatBot = ({ file, miniChat, toggleShow, handleReference }: ChatBotProps) => {
	return (
		<div id="chat-bot">
			<div className="chat-bot-header">
				<ChatBotButton toggleShowChatBot={toggleShow} hide />
			</div>
			<div className="chat-bot-body">
				<Chat
					file={file}
					miniChat={miniChat}
					handleReference={handleReference}
				/>
			</div>
		</div>
	);
};

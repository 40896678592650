import api from "../api";
import { loadAbort } from "../helpers/loadAbort.utility";
import { CountActivityEventDto } from "./../models";

export const countViewActivity = (activity: CountActivityEventDto) => {
	const controller = loadAbort();
	return { call: api.patch("api/ActivityEvent/Count/View", activity, { signal: controller.signal }), controller };
};

export const countDownlaodActivity = (activity: CountActivityEventDto) => {
	const controller = loadAbort();
	return { call: api.patch("api/ActivityEvent/Count/Download", activity, { signal: controller.signal }), controller };
};

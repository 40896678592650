import React, { useState } from "react";
import MainContainer from "../../components/layout/MainContainer";
import RedBarText from "../../components/templates/RedBarText/RedBarText";
import Breadcrumbs from "../../components/templates/BreadCrumbs/BreadCrumbs";
import { useNavigate, useParams } from "react-router-dom";
import { Book, MediaFile, MediaType, SelectorOption, Subject } from "../../models";
import BookCard from "../../components/templates/BookCard/BookCard";
import SearchField from "../../components/templates/SearchField/SearchField";
import FilterSelector from "../../components/templates/FilterSelector/FilterSelector";
import getStudentState from "../../helpers/StudentState.utility";
import { useGrade } from "../../hooks/useGrade.hook";
import { useFetchAndLoad } from "../../hooks/useFetchAndLoad.hook";
import { getSubjectWithBookAndMediaByGrade } from "../../services/Subject.service";
import { useAsync } from "../../hooks/useAsync.hook";
import LoadingIcons from "react-loading-icons";
import { useSubject } from "../../hooks/useSubject.hook";
import MediaGroup from "../../components/templates/MediaGroup/MediaGroup";

const SubjectDataOptions = [
	{ label: "Todos", value: "all" },
	{ label: "Libros", value: "books" },
	{ label: "Videos", value: "videos" },
	{ label: "Audios", value: "audios" },
];

const SubjectDetail: React.FC = () => {
	const params = useParams();
	const { loadingGrade, grade } = useGrade();
	const { loadingSubject, subjectId } = useSubject();
	const [subject, setSubject] = useState<Subject>();
	const [baseSubject, setBaseSubject] = useState<string>("[]");
	const { loading, callEndpoint } = useFetchAndLoad();
	const studentState = getStudentState(params);

	const getSubjectApiCall = async () =>
		await callEndpoint(getSubjectWithBookAndMediaByGrade(subjectId!, grade!.id!));

	const adaptSubject = (backData: any) => {
		if (backData?.data) {
			setSubject(backData.data);
			setBaseSubject(JSON.stringify(backData.data));
		}
	};

	const [selectedFilter, setSelectedFilter] = useState<SelectorOption>(
		SubjectDataOptions.find((x) => x.value === "all")!
	);

	const validateShow = (parameter: string) => {
		if (selectedFilter.value === "all" || selectedFilter.value === parameter) return true;
		return false;
	};

	const handleSelect = (newOption: SelectorOption) => setSelectedFilter(newOption);

	const handleSearch = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.preventDefault();

		const searchField = document.getElementById("subject-search-filter") as HTMLInputElement;
		const text = searchField.value;

		const subjectData: Subject = JSON.parse(baseSubject);

		const filterBooks = subjectData.books?.filter((book) =>
			book.title.toLowerCase().includes(text.toLowerCase())
		) as Book[];

		const mediaFiles = subjectData.mediaFiles?.filter((mediaFile) =>
			mediaFile.name.toLowerCase().includes(text.toLowerCase())
		) as MediaFile[];

		setSubject({ ...subject!, books: filterBooks, mediaFiles: mediaFiles });
	};

	useAsync(getSubjectApiCall, adaptSubject, [subjectId, grade], !!subjectId && !!grade);

	return (
		<MainContainer id="SubjectDetail">
			<Breadcrumbs history={["Libro Abierto", studentState, params.subject!]} />
			{!loading && !loadingGrade && !loadingSubject ? (
				<>
					<section className="subject-details-card-container">
						<article id="subject-details-card">
							<div className="image-container">
								<img src={subject?.imageUrl} alt="Libro Español" />
							</div>
							<div className="details-container">
								<h4 className="title">{`${subject?.name} ${params.grade} de ${params.level}`}</h4>
								<div className="publisher">Libro Abierto</div>
								<div className="description">{subject?.description}</div>
								<div className="grade">{`${params.level}, ${params.grade}`}</div>
							</div>
						</article>
					</section>
					<div className="filters-container">
						<section className="filters-section">
							<form className="search-container">
								<SearchField
									id="subject-search-filter"
									handleSearch={handleSearch}
								/>
							</form>
							<div className="divider"></div>
							<div className="selector-container">
								<FilterSelector
									options={SubjectDataOptions}
									initialSelected={SubjectDataOptions.find(
										(x) => x.value === "all"
									)}
									handleSelect={handleSelect}
								/>
							</div>
						</section>
					</div>
					<>
						{validateShow("books") && (
							<section className="subject-books list-container">
								<RedBarText title="Libros" />
								<div className="card-list scroll">
									{subject?.books && subject.books.length > 0 ? (
										subject?.books.map((book, i) => {
											const booksData = book as Book;
											return (
												<BookCard grade={grade!} book={booksData} key={i} />
											);
										})
									) : (
										<div>No se encontraron libros para su busqueda</div>
									)}
								</div>
							</section>
						)}
					</>
					<MediaGroup
						type={MediaType.Video}
						entity={subject!}
						dataInfo={SubjectDataOptions.find((x) => x.value == "videos")!}
						selectedFilter={selectedFilter}
					/>
					<MediaGroup
						type={MediaType.Audio}
						entity={subject!}
						dataInfo={SubjectDataOptions.find((x) => x.value == "audios")!}
						selectedFilter={selectedFilter}
					/>
				</>
			) : (
				<div className="loading-container">
					<div className="loading-component">
						<LoadingIcons.Oval
							className="loadin-icon"
							stroke="#00ACCE"
							speed={2}
							width="6rem"
							height="6rem"
							strokeWidth="3"
						/>
					</div>
				</div>
			)}
		</MainContainer>
	);
};

export default SubjectDetail;

import React from "react";
import IconProps from "./IconProps.interface";

const DropIcon: React.FC<IconProps> = (props) => {
	return (
		<svg
			className={props.className}
			onClick={props.onClick}
			viewBox="0 0 9 7"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M4.24268 6.32824L0 2.08564L1.41422 0.671387L4.24268 3.49984L7.07108 0.671387L8.48528 2.08564L4.24268 6.32824Z"
				fill={props.fill || "#ABABAB"}
			/>
		</svg>
	);
};

export default DropIcon;

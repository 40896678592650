import React from "react";
import MainContainer from "../../components/layout/MainContainer";
import NotFoundIcon from "../../assets/icons/NotFoundIcon";
import { Link } from "react-router-dom";

const NotFoundAuthorized: React.FC = () => {
	return (
		<MainContainer id="NotFoundAuthorized">
			<div className="not-found">
				<NotFoundIcon className="huge-less-icon" />
				<div className="not-found">La página que busca no existe.</div>
				<Link className="home-link" to="/">
					Libro Abierto
				</Link>
			</div>
		</MainContainer>
	);
};

export default NotFoundAuthorized;

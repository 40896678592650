import React, { useState } from "react";
import { useNotification } from "../../../hooks/useNotification.hook";
import { useFetchAndLoad } from "../../../hooks/useFetchAndLoad.hook";
import { getProvinces, signIn } from "../../../services";
import { useAsync } from "../../../hooks/useAsync.hook";
import { SignInUserDto } from "../../../models";
import LoadingIcons from "react-loading-icons";
import { FieldValidator } from "../../../helpers/FieldValidator.utility";

type Provinces = {
	id: string;
	name: string;
};

interface RegisterFormProps {
	showSignedIn: () => void;
}

const RegisterForm: React.FC<RegisterFormProps> = ({ showSignedIn }) => {
	const { notifyError } = useNotification();
	const { loading, callEndpoint } = useFetchAndLoad();
	const { callEndpoint: callProvinceEndpoint } = useFetchAndLoad();
	const [provinces, setProvinces] = useState<Provinces[]>([]);
	const [errors, setErrors] = useState<{ [key: string]: string[] }>({});

	const getProvincesApiCall = async () => callProvinceEndpoint(getProvinces());
	const adaptProvinces = (backData: any) => {
		if (backData.data) setProvinces(backData.data);
	};

	const signInApiCall = async (user: SignInUserDto) => await callEndpoint(signIn(user));

	const validateFields = (entity: SignInUserDto) => {
		let fieldsErrors: { [key: string]: string[] } = {};

		const passwordValidation = FieldValidator.validatePassword(entity.password);
		if (passwordValidation.valid === false)
			fieldsErrors = { ...errors, password: passwordValidation.errors };

		const mailValidation = FieldValidator.validateEmail(entity.email);
		if (mailValidation.valid === false)
			fieldsErrors = { ...errors, password: mailValidation.errors };

		return fieldsErrors;
	};

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		if (!loading) {
			setErrors({});

			const userData: SignInUserDto = {
				name: e.currentTarget.personname.value,
				lastName: e.currentTarget.lastname.value,
				provinceId: e.currentTarget.province.value,
				cellphone: e.currentTarget.phone.value,
				role: parseInt(e.currentTarget.userrole.value),
				email: e.currentTarget.mail.value,
				password: e.currentTarget.password.value,
				confirmPassword: e.currentTarget.confirmPassword.value,
			};

			const validatedFields = validateFields(userData);

			if (Object.keys(validatedFields).length == 0) {
				if (userData.password == userData.confirmPassword) {
					try {
						const result = await signInApiCall(userData);
						if (result) {
							showSignedIn();
						}
					} catch (err: any) {
						if (err.data.statusCode == 400) {
							notifyError(err.data.data);
						} else {
							console.log(err);
						}
					}
				} else {
					setErrors({
						...validatedFields,
						confirmPassword: ["Las contraseñas no coinciden"],
					});
				}
			} else {
				setErrors({ ...validatedFields });
			}
		}
	};

	useAsync(getProvincesApiCall, adaptProvinces);

	return (
		<div className="form-container">
			<form id="registerForm" onSubmit={handleSubmit}>
				<div className="input-container">
					<input id="personname" type="text" placeholder="Nombre(s)" required />
					<input id="lastname" type="text" placeholder="Apellido(s)" required />
					<select id="province" required defaultValue={"init"}>
						<option value="init" disabled hidden>
							Provincia
						</option>
						{provinces.map((province) => (
							<option value={province.id} key={province.id}>
								{province.name}
							</option>
						))}
					</select>
					<input
						id="phone"
						type="text"
						placeholder="Teléfono de Contacto (xxx-xxx-xxxx)"
						pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
						required
					/>
					<select id="userrole" required defaultValue={"init"}>
						<option value="init" disabled hidden>
							Rol
						</option>
						<option value={1}>Estudiante</option>
						<option value={2}>Profesor</option>
					</select>
					<div className="input-field">
						<input id="mail" type="email" placeholder="Correo Electrónico" required />
						<div className="errors-container">
							{errors["mail"]?.map((error, i) => (
								<div className="error" key={i}>
									{error}
								</div>
							))}
						</div>
					</div>
					<div className="input-field">
						<input id="password" type="password" placeholder="Contraseña" required />
						<div className="errors-container">
							{errors["password"]?.map((error, i) => (
								<div className="error" key={i}>
									{error}
								</div>
							))}
						</div>
					</div>
					<div className="input-field">
						<input
							id="confirmPassword"
							type="password"
							placeholder="Confirmar Contraseña"
							required
						/>
						<div className="errors-container">
							{errors["confirmPassword"]?.map((error, i) => (
								<div className="error" key={i}>
									{error}
								</div>
							))}
						</div>
					</div>
				</div>
				<button className="submit-btn" type="submit">
					{!loading ? (
						<span>Registrarse</span>
					) : (
						<div className="loading-component">
							<LoadingIcons.Oval
								className="loadin-icon"
								stroke="#00ACCE"
								speed={2}
								width="2rem"
								height="2rem"
								strokeWidth="2"
							/>
						</div>
					)}
				</button>
			</form>
		</div>
	);
};

export default RegisterForm;

import api from "../api";
import { loadAbort } from "../helpers/loadAbort.utility";

export const getSubjects = () => {
	const controller = loadAbort();
	return { call: api.get("api/Subject", { signal: controller.signal }), controller };
};

export const getSubjectsByGrade = (gradeId: string) => {
	const controller = loadAbort();
	return {
		call: api.get(`api/Subject/Books/Grade/${gradeId}`, { signal: controller.signal }),
		controller,
	};
};

export const getSubjectWithBookAndMediaByGrade = (subjectId: string, gradeId: string) => {
	const controller = loadAbort();
	return {
		call: api.get(`api/GradeSubject/${gradeId}/${subjectId}/Books/MediaFiles/`, { signal: controller.signal }),
		controller,
	};
};

export const getSubjectByGradeId = (gradeId: string) => {
	const controller = loadAbort();
	return {
		call: api.get(`api/GradeSubject/Grade/${gradeId}`, { signal: controller.signal }),
		controller,
	};
};

export const getSubjectByName = (subjectName: string) => {
	const controller = loadAbort();
	return {
		call: api.get("api/Subject/Name", {
			params: {
				subjectName: subjectName,
			},
			signal: controller.signal,
		}),
		controller,
	};
};

import React from "react";

interface SideTitleProps {
	text: string;
}

const SideTitle: React.FC<SideTitleProps> = ({ text }) => {
	return (
		<div className="side-title">
			<h3>{text}</h3>
			<div className="row"></div>
		</div>
	);
};

export default SideTitle;
